import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms'
import { LikedUserComponent } from './liked-user.component'
import { TranslateModule } from '@ngx-translate/core'
import { PickerModule } from '@ctrl/ngx-emoji-mart'

@NgModule({
  imports: [CommonModule, FormsModule, TranslateModule, PickerModule],
  declarations: [LikedUserComponent],
  exports: [LikedUserComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
})
export class LikedUserModule {}
