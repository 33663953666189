import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core'
import { FormsModule } from '@angular/forms'

import { DatepickerModule } from './components/datepicker/datepicker.module'
import { ContentEditableModule } from './directives/content-editable/content-editable.module'
import { DndModule } from '@ng-dnd/core'
import { ColorPickerModule } from 'ngx-color-picker'

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    TranslateModule,
    DndModule,
    ContentEditableModule,
    DatepickerModule,
    ColorPickerModule,
  ],
  exports: [ContentEditableModule, DndModule],
})
export class SharedModules {}
