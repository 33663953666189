import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router'

import { of } from 'rxjs'
import { environment } from 'src/environements/environment'

@Injectable({
  providedIn: 'root',
})
export class InvitationRedirectionGuard implements CanActivate {
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // Params
    let externalUrl = new URL(environment.authentication_uri)
    externalUrl.pathname = '/invitation'

    if (environment.authentication_dynamic_uri_code) {
      const [account] = window.location.hostname.split('.')
      const externalUri = window.location.origin.replace(
        `${account}`,
        `${environment.authentication_dynamic_uri_code}`,
      )

      externalUrl = new URL(externalUri + '/invitation')
    }

    if (
      window.origin !== externalUrl.origin &&
      !window.origin.includes('mate')
    ) {
      const validationCode = new URLSearchParams(window.location.search).get(
        'invitation_code',
      )

      if (validationCode) {
        externalUrl.searchParams.append('invitation_code', validationCode)
      }

      window.location.href = externalUrl.href
    } else {
      if (new URLSearchParams(window.location.search).get('code')) {
        localStorage.setItem(
          'kizeoCode',
          new URLSearchParams(window.location.search).get('code'),
        )
      }
    }

    return of(true)
  }
}
