import { Dictionary } from 'lodash'
import { DisplayModel } from '../models/json/display.model'
import { Field } from '../models/json/field.model'

export class InitList {
  static readonly type = '[List] Init'

  constructor(
    public displayName: string,
    public variationName: string,
    public currentDisplay: DisplayModel,
    public buttonsFilter?,
    public reset?: boolean,
  ) {}
}
export class RefreshList {
  static readonly type = '[List] Refresh'

  constructor(public offset: number) {}
}
export class SaveListTableSettings {
  static readonly type = '[List] SaveListTableSettings'

  constructor() {}
}
export class DeleteListTableSettings {
  static readonly type = '[List] DeleteListTableSettings'

  constructor() {}
}
export class SearchList {
  static readonly type = '[List] Search'

  constructor(public search: string) {}
}

export class NextListPage {
  static readonly type = '[List] Next Page'

  constructor() {}
}

export class FiltersChange {
  static readonly type = '[List] Filters change'

  constructor(
    public tablesFilter: Dictionary<any>,
    public selectedValues: Dictionary<any>,
  ) {}
}

export class SetButtonFilterForList {
  static readonly type = '[List] Set Button Filter For List'

  constructor(public buttonsFilter) {}
}

export class SetButtonFilter {
  static readonly type = '[List] Set Button Filter'

  constructor(public buttonsFilter) {}
}

export class FieldReorder {
  static readonly type = '[List] FieldReorder'

  constructor(public fields: Field[]) {}
}

export class ToggleVisible {
  static readonly type = '[List] Toggle field visibility'

  constructor(public field: Field) {}
}

export class ToggleOrder {
  static readonly type = '[List] Field Order'

  constructor(public source: string) {}
}

export class ChangeDate {
  static readonly type = '[Calendar] Change view'

  constructor(public viewType: string, public start: Date, public end: Date) {}
}
