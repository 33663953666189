import { Dictionary } from 'lodash'
import { FormModel } from './form.model'
import { Field } from './field.model'
import { ActionButton } from './action-button.model'
import { TableFilter } from './table-filter.model'

export class DisplayModel {
  canAdd: boolean

  canEdit: boolean

  canEditInline: boolean

  canDelete: boolean

  fieldOrder: string

  fieldOrderDesc: boolean

  fieldAffectation: string

  fieldGanttParent: string

  fieldStartDate: string

  fieldEndDate: string

  hideItemsPerPageOnList: boolean

  /*new*/
  hidePaginateOnList: boolean

  hideButtonsHeaderOnList: boolean

  hideColSearchOnList: boolean

  listDisplayClass: string

  hideSearchTextBox: boolean

  hideExportButtonOnList: boolean

  message: any

  buttonsFilterMode: 'button' | 'select'

  buttonsFilter: any

  colorInBackground: boolean

  displayVariations: any

  form: FormModel

  editInPlace: boolean

  addInPlace: boolean

  hideExportExcel: boolean


  table: {
    fields: Dictionary<Field>
    tableColSearch: Dictionary<TableFilter<any>>
    selectValues: Dictionary<any>
    defaultTheme: string
    lines: number
  }

  alias_displays: any

  actionButtons: Dictionary<ActionButton>

  multipleSelection: boolean

  [key: string]: string | any // any obligé pour la compilation de la classe apparement :'(
}
