import { Injectable } from '@angular/core'

import { Observable } from 'rxjs'
import { catchError, filter, map, tap } from 'rxjs/operators'
import {
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpResponse,
} from '@angular/common/http'
import { TranslateService } from '@ngx-translate/core'
import { MessageService } from 'primeng/api'
import { MvHttpClient } from '../lib/MvHttpClient'
import { Utilities } from '../utilities/utilities'
import { GlobalVars } from '../vars/globalvars'
import { DataModel } from '../models/data.model'

export const FORBIDDEN_ERROR_CODE = 403
export const NOT_FOUND_ERROR_CODE = 404
export const SUCCESS_CODE = 200
export const OK_CODE = 'ok'

@Injectable({ providedIn: 'root' })
export class RequestRessource {
  constructor(
    private mvHttpClient: MvHttpClient,
    private utilities: Utilities,
    private _g: GlobalVars,
    private translate: TranslateService,
    private messageService: MessageService,
  ) {}

  async getAllQueries(requests: string[]) {
    const url = `${this._g.restRoot}app/data/get_all_queries`

    const result = await this.mvHttpClient
      .post<any>(url, { requests })
      .toPromise()
    return result?.data
  }

  async getMap(
    request: string,
    latitudeMin: number,
    latitudeMax: number,
    longitudeMin: number,
    longitudeMax: number,
    requestFilter?: { [key: string]: { operator: string; value: string } },
  ) {
    const url = `${this._g.restRoot}app/data/map/${request}`
    const payload = {
      coordinates: {
        latitude_min: latitudeMin,
        latitude_max: latitudeMax,
        longitude_min: longitudeMin,
        longitude_max: longitudeMax,
      },
      filters: requestFilter ? requestFilter : {},
      search: null,
      displayAG: 'check_points',
      variationAG: null,
    }

    const result = await this.mvHttpClient.post<any>(url, payload).toPromise()
    return result?.data
  }

  create$(
    displayName: string,
    variationName: string,
    request: string,
    datas: Partial<DataModel>,
  ): Observable<HttpEvent<{ data: number }>> {
    return this.mvHttpClient
      .post<{
        data: number
      }>(
        `${this._g.restRoot}app/data/${request}/create`,
        { displayName, data: datas },
        { observe: 'events', reportProgress: true },
      )
      .pipe(
        tap((httpEvent: HttpEvent<{ data: any; created: any }>) => {
          // Full Response
          if (
            httpEvent.type === HttpEventType.Response &&
            httpEvent.status === SUCCESS_CODE
          ) {
            this.handleHttpEventFlashMessage(
              this.translate.instant('DETAIL.CREATE_COMPLETED'),
            )
          }
        }),
        catchError((errorResponse: HttpErrorResponse) => {
          this.handleHttpErrorResponse(errorResponse)

          throw new Error(
            this.utilities.getHttpErrorResponseMessage(errorResponse),
          )
        }),
      )
  }

  create(
    displayName: string,
    variationName: string,
    request: string,
    datas: Partial<DataModel>,
  ): Promise<{ data: number }> {
    return this.create$(displayName, variationName, request, datas)
      .pipe(
        filter(
          (httpEvent: HttpEvent<{ data: number }>) =>
            httpEvent.type === HttpEventType.Response,
        ),
        map((httpEvent: HttpResponse<{ data: number }>) => httpEvent.body),
      )
      .toPromise()
  }

  update$(
    displayName: string,
    variationName: string,
    request: string,
    datas: Partial<DataModel>,
    create?: Partial<DataModel>,
  ): Observable<HttpEvent<{ data: any; created: any }>> {
    return this.mvHttpClient
      .post<{ data: any; created: any }>(
        `${this._g.restRoot}app/data/${request}/update`,
        { displayName, data: datas, create },
        {
          observe: 'events',
          reportProgress: true,
          responseType: 'text',
        } as any,
      )
      .pipe(
        tap((httpEvent: HttpEvent<{ data: any; created: any }>) => {
          // Full Response
          if (
            httpEvent.type === HttpEventType.Response &&
            httpEvent.status === SUCCESS_CODE
          ) {
            this.handleHttpEventFlashMessage(
              this.translate.instant('DETAIL.UPDATE_COMPLETED'),
            )
          }
        }),
        catchError((errorResponse: HttpErrorResponse) => {
          this.handleHttpErrorResponse(errorResponse)
          throw new Error(
            this.utilities.getHttpErrorResponseMessage(errorResponse),
          )
        }),
      )
  }

  update(
    displayName: string,
    variationName: string,
    request: string,
    datas: Partial<DataModel>,
    create?: Partial<DataModel>,
  ) {
    return this.update$(displayName, variationName, request, datas, create)
      .pipe(
        filter(
          (httpEvent: HttpEvent<{ data: any; created: any }>) =>
            httpEvent.type === HttpEventType.Response,
        ),
        map(
          (httpEvent: HttpResponse<{ data: any; created: any }>) =>
            httpEvent.body,
        ),
      )
      .toPromise()
  }

  duplicate(displayName: string, request: string, data: Partial<DataModel>) {
    return this.mvHttpClient
      .post(`${this._g.restRoot}app/data/${request}/duplicate`, {
        displayName,
        data,
      })
      .pipe(
        tap((httpEvent: HttpEvent<{ data: any; created: any }>) => {
          // Full Response
          if (
            httpEvent.type === HttpEventType.Response &&
            httpEvent.status === SUCCESS_CODE
          ) {
            this.handleHttpEventFlashMessage(
              this.translate.instant('DETAIL.DUPLICATE_COMPLETED'),
            )
          }
        }),
        catchError((errorResponse: HttpErrorResponse) => {
          this.handleHttpErrorResponse(errorResponse)
          throw new Error(
            this.utilities.getHttpErrorResponseMessage(errorResponse),
          )
        }),
      )
      .toPromise()
  }

  delete(
    displayName: string,
    variationName: string,
    request: string,
    itemId: number,
  ) {
    return this.mvHttpClient
      .delete(
        `${this._g.restRoot}app/data/${request}/delete/${itemId}/${displayName}`,
      )
      .pipe(
        tap((httpEvent: any) => {
          // Full Response
          if (
            httpEvent.type === HttpEventType.Response &&
            httpEvent.status === SUCCESS_CODE
          ) {
            this.handleHttpEventFlashMessage(
              this.translate.instant('DETAIL.DELETE_COMPLETED'),
            )
          } else if (httpEvent.status === OK_CODE) {
            this.handleHttpEventFlashMessage(
              this.translate.instant('DETAIL.DELETE_COMPLETED'),
            )
          }
        }),
        catchError((errorResponse: HttpErrorResponse) => {
          this.handleHttpErrorResponse(errorResponse)
          throw new Error(
            this.utilities.getHttpErrorResponseMessage(errorResponse),
          )
        }),
      )
      .toPromise()
  }

  getData<T extends DataModel>(
    requestname: string,
    params: Partial<{
      dataId: string
      dataFilter?: string
      fieldOrder: string
      fieldOrderDesc: boolean
      minLines: number
      maxLines: number
      searchString: string
    }> = {},
  ): Promise<{ data: T[]; hasMoreLines: boolean }> {
    return this.utilities.getDataPromise(requestname, params)
  }

  private handleHttpErrorResponse(errorResponse: HttpErrorResponse): void {
    if (errorResponse.status === NOT_FOUND_ERROR_CODE) {
      this.messageService.add({
        severity: 'error',
        life: 10000,
        summary: this.translate.instant('DETAIL.NOT_FOUND'),
        detail:
          errorResponse?.error?.error ||
          errorResponse['data']?.error ||
          this.translate.instant('ERROR.ERROR'),
      })
    } else if (errorResponse.status === FORBIDDEN_ERROR_CODE) {
      this.messageService.add({
        severity: 'error',
        life: 10000,
        summary: this.translate.instant('DETAIL.CAN_NOT'),
        detail:
          errorResponse?.error?.error ||
          errorResponse['data']?.error ||
          this.translate.instant('ERROR.ERROR'),
      })
    }
  }

  public generateInvitationLink(domain: string): Observable<any> {
    const URL = `${this._g.restRoot}app/account/invite`

    return this.mvHttpClient.post(URL, {
      domain,
    })
  }

  public inviteUsers(emails: string): Observable<any> {
    const URL = `${this._g.restRoot}app/users/invite`

    return this.mvHttpClient.post(URL, {
      emails,
    })
  }

  private handleHttpEventFlashMessage(message: string): void {
    if (this._g.isAutoSaving) {
      return
    }

    const summary = this.translate.instant('DETAIL.SAVE')
    this.messageService.add({
      severity: 'success',
      summary,
      detail: message,
    })
  }
}
