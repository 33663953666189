import { Dictionary, mapValues } from 'lodash'
import { plainToClass } from 'class-transformer'

export declare type Constructor<T> = new (...args: any[]) => T

export function dictionaryType<T>(
  clazz: Constructor<T>,
  dictionary: Dictionary<T> = {},
) {
  return mapValues(dictionary, (v) => plainToClass(clazz, v))
}
