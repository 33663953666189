import { HttpUrlEncodingCodec } from '@angular/common/http'

/**
 * CustomHttpUrlEncodingCodec
 * Fix plus sign (+) not encoding, so sent as blank space
 * See: https://github.com/angular/angular/issues/11058#issuecomment-247367318
 */
export class CustomHttpUrlEncodingCodec extends HttpUrlEncodingCodec {
  static readonly ENCODED_PLUS_SIGN = '%2B'

  static encodePlusSign(k: string): string {
    return k.replace(/\+/gi, CustomHttpUrlEncodingCodec.ENCODED_PLUS_SIGN)
  }

  override encodeKey(k: string): string {
    k = super.encodeKey(k)
    return CustomHttpUrlEncodingCodec.encodePlusSign(k)
  }

  override encodeValue(v: string): string {
    v = super.encodeValue(v)
    return CustomHttpUrlEncodingCodec.encodePlusSign(v)
  }
}
