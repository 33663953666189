import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { MessageService } from 'primeng/api'
import { SlackResource } from '../resources/slack.resource'

@Injectable({
  providedIn: 'root',
})
export class SlackService {
  constructor(
    private slackResource: SlackResource,
    private messageService: MessageService,
    private translate: TranslateService,
  ) {}

  public addToSlack(code: string): void {
    this.slackResource.access(code).subscribe((status) => {
      if (status === 'ok') {
        this.messageService.add({
          severity: 'success',
          summary: '',
          detail: this.translate.instant('SLACK.SUCCESS'),
        })
      } else {
        this.messageService.add({
          severity: 'error',
          summary: '',
          detail: this.translate.instant('SLACK.ERROR'),
          life: 10000,
        })
      }
    })
  }
}
