<div style="margin: 8px">
  <div innerHTML="{{ config.data.body | translate }}"></div>
  <textarea
    *ngIf="config.data.showTextArea === true"
    [(ngModel)]="config.data.inputValue"
    class="form-control"
    rows="6"
  ></textarea>
  <input
    *ngIf="config.data.showInput === true"
    [(ngModel)]="config.data.inputValue"
    class="form-control"
    type="text"
  />
</div>
<div style="display: flex; flex-direction: row-reverse">
  <button class="btn btn-primary" type="button" (click)="close()">
    {{ 'ERROR.DIALOG_OK' | translate }}
  </button>
  <button
    *ngIf="config.data.showCancel === true"
    class="btn btn-secondary"
    type="button"
    translate
  >
    CORE.CANCEL
  </button>
</div>
