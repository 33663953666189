import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core'
import { DialogModule } from 'primeng/dialog'
import { TableModule } from 'primeng/table'

import { ApiOfflineComponent } from './api-offline.component'

// Module perso de content Editable

@NgModule({
  imports: [CommonModule, TranslateModule, DialogModule, TableModule],
  declarations: [ApiOfflineComponent],
  exports: [ApiOfflineComponent],
})
export class ApiOfflineModule {}
