import { Injectable } from '@angular/core'
import { Base64 } from 'js-base64'

@Injectable({ providedIn: 'root' })
export class Base64Service {
  encodeBinaryString(binaryString: string): string {
    return Base64.btoa(binaryString)
  }

  blobToBase64(blob: Blob): Promise<string> {
    const reader = new FileReader()
    reader.readAsDataURL(blob)

    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result as string)
      }
    })
  }
}
