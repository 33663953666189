import { Directive, ElementRef, Renderer2 } from '@angular/core'
import { DEBUG_CODE } from 'src/app/pages/gateway/header-navigation/dropdown-language/dropdown-language.component'
import { GlobalVars } from 'src/app/shared/vars/globalvars'

const JSON_TAG = 'JSON'
const DATA_TAG = 'DATA'
const FRONT_TAG = 'FRONT'

@Directive({
  standalone: true,
  selector: '[appTranslationTagsHost]',
})
export class TranslationTagsHostDirective {
  constructor(
    private _g: GlobalVars,
    private el: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngAfterViewInit() {
    if (this._g.language !== DEBUG_CODE) {
      return
    }

    const element = this.el.nativeElement

    if (!element) {
      return
    }

    this.observeChanges(element)
  }

  observeChanges(element: HTMLElement) {
    if (this._g.language !== DEBUG_CODE) {
      return
    }

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList') {
          this.transformContent(element)
        }
      })
    })

    observer.observe(element, {
      childList: true,
      subtree: true,
    })
  }

  private transformContent(parent: HTMLElement): void {
    if (this._g.language !== DEBUG_CODE) {
      return
    }

    if (!parent) {
      return
    }

    this.findElementsContainingText(parent)
  }

  findElementsContainingText(parent: HTMLElement) {
    if (this._g.language !== DEBUG_CODE) {
      return parent
    }

    const jsonTag = '<' + JSON_TAG + '>'
    const dataTag = '<' + DATA_TAG + '>'
    const frontTag = '<' + FRONT_TAG + '>'

    if (
      !parent.textContent.includes(jsonTag) &&
      !parent.textContent.includes(dataTag) &&
      !parent.textContent.includes(frontTag)
    ) {
      return parent
    }

    const children = Array.from(parent.childNodes)
    children.forEach((child) => {
      if (child.nodeType === Node.TEXT_NODE) {
        if (child.textContent.includes(jsonTag)) {
          this.processTags(child, parent, JSON_TAG)
        } else if (child.textContent.includes(dataTag)) {
          this.processTags(child, parent, DATA_TAG)
        } else if (child.textContent.includes(frontTag)) {
          this.processTags(child, parent, FRONT_TAG)
        }
      } else if (child.nodeType === Node.ELEMENT_NODE) {
        this.findElementsContainingText(child as HTMLElement)
      }
    })
  }

  processTags(child: Node, parent: HTMLElement, tag: string) {
    const tagPattern = '<' + tag + '>'
    if (!child.textContent.includes(tagPattern)) {
      return
    }

    let text = child.textContent.replace(new RegExp(`<\/?${tag}>`, 'g'), '')
    const { code, translation } = this.extractBracketedPrefix(text)

    this.renderer.setProperty(child, 'textContent', translation)

    this.renderer.setStyle(parent, 'text-shadow', '2px 2px 2px #000')
    this.renderer.setStyle(parent, 'font-weight', 900)

    let tooltip = parent.getAttribute('ng-reflect-text')
    let tooltipCode = null
    let tooltipTranslation = null

    if (tooltip) {
      tooltip = tooltip.replace(new RegExp(`<\/?${tag}>`, 'g'), '')

      const tooltipData = this.extractBracketedPrefix(tooltip)

      tooltipCode = tooltipData?.code
      tooltipTranslation = tooltipData?.translation

      this.renderer.setProperty(parent, 'ng-reflect-text', tooltipTranslation)
      this.renderer.setProperty(parent, 'title', tooltipTranslation)
    }

    this.renderer.listen(parent, 'click', (event: MouseEvent) => {
      if (event.ctrlKey || event.metaKey || event.altKey) {
        event.stopPropagation()
        event.stopImmediatePropagation()
        event.preventDefault()
        this.handleClick(translation, tag, code)
      }

      if (event.shiftKey) {
        event.stopPropagation()
        event.stopImmediatePropagation()
        event.preventDefault()

        if (!tooltipCode) {
          tooltipTranslation = tooltipTranslation.replace('[#', '')
        }

        this.handleClick(tooltipTranslation, tag, tooltipCode)
      }
    })
  }

  private extractBracketedPrefix(str: string): {
    code: string
    translation: string
  } {
    const match = str.match(/.*\[#(.*?)\#](.*)/)

    if (match) {
      return {
        code: match[1],
        translation: match[2].trim(),
      }
    }

    return { code: null, translation: str }
  }

  private handleClick(content: string, type = null, code = null): void {
    if (this._g.language !== DEBUG_CODE) {
      return
    }

    if (!content) {
      return
    }

    content = content.trim()

    this._g.translationSearch$.next(content)

    if (type) {
      type = type.toLowerCase()
      this._g.translationSearchType$.next(type)
    }

    if (code) {
      this._g.translationSearchCode$.next(code)
    }

    this._g.isTranslationsModal$.next(true)
  }
}
