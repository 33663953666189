import { Routes } from '@angular/router'
import { AccountRedirectionGuard } from './guards/account-redirection.guard'
import { IsSignedInGuard } from './guards/is-signed-in.guard'
import { LoginRedirectionGuard } from './guards/login-redirection.guard'
import { GatewayResolver } from './resolvers/gateway.resolver'
import { FirstConnectionRedirectionGuard } from './guards/first-connection.redirection.guard'
import { InvitationRedirectionGuard } from './guards/invitation.redirection.guard'

export const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/gateway/gateway.module').then((m) => m.GatewayModule),
    resolve: {
      dataLoaded: GatewayResolver,
    },
    canActivate: [IsSignedInGuard, AccountRedirectionGuard],
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginModule),
    canActivate: [LoginRedirectionGuard],
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import('./pages/reset-password/reset-password.module').then(
        (m) => m.ResetPasswordModule,
      ),
    canActivate: [IsSignedInGuard],
  },
  {
    path: 'first-connection',
    loadChildren: () =>
      import('./pages/first-connection/first-connection.module').then(
        (m) => m.FirstConnectionModule,
      ),
    canActivate: [FirstConnectionRedirectionGuard],
  },
  {
    path: 'invitation',
    loadChildren: () =>
      import('./pages/invitation/invitation.module').then(
        (m) => m.InvitationModule,
      ),
    canActivate: [InvitationRedirectionGuard],
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./pages/forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordModule,
      ),
    canActivate: [LoginRedirectionGuard],
  },
]
