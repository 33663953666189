import { Injectable } from '@angular/core'

import { Dictionary } from 'lodash'
import { Subject } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { MvHttpClient } from 'src/app/shared/lib/MvHttpClient'
import { Base64Service } from 'src/app/shared/services/base64.service'
import { Utilities } from 'src/app/shared/utilities/utilities'
import { GlobalVars } from 'src/app/shared/vars/globalvars'

@Injectable({ providedIn: 'root' })
export class FileDownloadService {
  fileToDownload$ = new Subject<FileToDownload>()

  constructor(
    private mvHttpClient: MvHttpClient,
    private _g: GlobalVars,
    private base64Service: Base64Service,
    private utilities: Utilities,
  ) {}

  downloadFile(fileToDownload: FileToDownload): Promise<Blob> {
    if (fileToDownload.method === 'POST') {
      return this.mvHttpClient
        .post<Blob>(fileToDownload.url, fileToDownload.body, {
          responseType: 'blob' as 'json',
          observe: 'body',
        })
        .toPromise()
    } else if (fileToDownload.method === 'GET') {
      return this.mvHttpClient
        .get<any>(fileToDownload.url, {
          responseType: 'json',
          observe: 'body',
        })
        .pipe(
          map(
            (response: any) =>
              new Blob([JSON.stringify(response)], {
                type: 'application/json',
              }),
          ),
          catchError((errorResponse: any) => {
            this.utilities.handleHttpErrorResponse(errorResponse)
            throw new Error(
              this.utilities.getHttpErrorResponseMessage(errorResponse),
            )
          }),
        )
        .toPromise()
    }
  }

  async downloadImgForDomain(str: string) {
    if (str.indexOf('__TOKEN__') !== -1) {
      return str.replace(/__TOKEN__/gi, this._g.token)
    } else if (isNaN(parseInt(str))) {
      return str
    } else {
      const blob = await this.mvHttpClient
        .get<Blob>(this.getUrlForDomain(str), {
          responseType: 'blob' as 'json',
          observe: 'body',
        })
        .toPromise()
      return await this.base64Service.blobToBase64(blob)
    }
  }

  getUrlForDomain(str: string) {
    if (str.indexOf('__TOKEN__') !== -1) {
      return str.replace(/__TOKEN__/gi, this._g.token)
    } else if (isNaN(parseInt(str))) {
      return str
    } else {
      return `${this._g.restRoot}app/file/${str}/s3-prev?Authorization=${this._g.token}`
    }
  }
}

export class FileToDownload {
  name: string

  url: string

  method: 'POST' | 'GET'

  body?: Dictionary<any>

  constructor(obj: Partial<FileToDownload>) {
    Object.assign(this, obj)
  }
}
