import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { TranslateModule } from '@ngx-translate/core'
import { ButtonModule } from 'primeng/button'
import { ConfirmModalComponent } from './confirm-modal.component'
import { FormsModule } from '@angular/forms'

@NgModule({
  imports: [CommonModule, TranslateModule, ButtonModule, FormsModule],
  declarations: [ConfirmModalComponent],
  exports: [ConfirmModalComponent],
})
export class ConfirmModalModule {}
