import { Injectable } from '@angular/core'

import { catchError, map } from 'rxjs/operators'
import { GlobalVars } from '../vars/globalvars'
import { MvHttpClient } from '../lib/MvHttpClient'
import { Utilities } from '../utilities/utilities'

@Injectable({ providedIn: 'root' })
export class SlackResource {
  private baseUrl = `${this._g.restRoot}slack`

  constructor(
    private _g: GlobalVars,
    private mvHttpClient: MvHttpClient,
    private utilities: Utilities,
  ) {}

  public access(code: string) {
    return this.mvHttpClient.get(`${this.baseUrl}/${code}`).pipe(
      map((response: any) => response.status),
      catchError((errorResponse: any) => {
        this.utilities.handleHttpErrorResponse(errorResponse)
        throw new Error(
          this.utilities.getHttpErrorResponseMessage(errorResponse),
        )
      }),
    )
  }
}
