import { TranslateLoader } from '@ngx-translate/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { GlobalVars } from 'src/app/shared/vars/globalvars'


export class CustomTranslateLoader implements TranslateLoader {
  constructor(private httpClient: HttpClient, private _g: GlobalVars) {}

  getTranslation(lang: string): Observable<any> {
    if (lang.includes('_local')) {
      const replacedLang = lang.replace('_local', '')
      // On force le chargement du fichier local pour palier aux soucis de latence pour les fichiers servis par le backend
      const local = this.httpClient.get(`/assets/i18n/${replacedLang}.json`)

      return local
    } else {
      const url = `${this._g.restRoot}get_translations/${lang}`
      // Si le fichier n'est pas disponible sur le serveur, on utilise le fichier local en secours
      const external = this.httpClient
        .get(url)
        .pipe(
          catchError((_) => this.httpClient.get(`/assets/i18n/${lang}.json`)),
        )

      return external
    }
  }
}
