import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { AddCredentialsInterceptor } from './AddCredentialsInterceptor'
import { LanguageInterceptor } from './Language.interceptor'
import { LoaderInterceptor } from './Loader.interceptor'
import { RequestOriginInterceptor } from './RequestOrigin.interceptor'
import { TokenInterceptor } from './token.interceptor'

export const httpInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: LoaderInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AddCredentialsInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: RequestOriginInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: LanguageInterceptor,
    multi: true,
  },
]
