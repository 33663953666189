import { NgModule } from '@angular/core'
import {
  AUTH_SERVICE,
  AuthModule as NgxAuthModule,
  PUBLIC_FALLBACK_PAGE_URI,
} from 'ngx-auth'
import { AuthenticationService } from './authentication.service'

@NgModule({
  imports: [NgxAuthModule],
  providers: [
    { provide: AUTH_SERVICE, useClass: AuthenticationService },
    { provide: PUBLIC_FALLBACK_PAGE_URI, useValue: '/login' },
  ],
})
export class AuthModule {}
