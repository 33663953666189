import { Inject, Injectable, InjectionToken } from '@angular/core'

export const PLATFORM_SPECIFIC = new InjectionToken<IPlatformSpecific>(
  'Platform specific',
)

@Injectable({ providedIn: 'root' })
export class PlatformSpecificService implements IPlatformSpecific {
  constructor(
    @Inject(PLATFORM_SPECIFIC) private platformSpecific: IPlatformSpecific,
  ) {}

  fileExists(path, file): any {
    return this.platformSpecific.fileExists(path, file)
  }

  getDeviceDataDirectory(): any {
    return this.platformSpecific.getDeviceDataDirectory()
  }

  getRequestOrigin(): string {
    return this.platformSpecific.getRequestOrigin()
  }

  isOnline(): boolean {
    return false
  }

  platformAlert(params): void {
    return this.platformSpecific.platformAlert(params)
  }

  readAsDataURL(params): void {
    return this.platformSpecific.readAsDataURL(params)
  }

  readAsText(params): void {
    return this.platformSpecific.readAsText(params)
  }

  writeFile(params): void {
    return this.platformSpecific.writeFile(params)
  }
}

export interface IPlatformSpecific {
  /***********  Test Online */
  isOnline(): boolean

  getDeviceDataDirectory(): any

  writeFile(params): void

  fileExists(path, file): any

  readAsText(params): void

  readAsDataURL(params): void

  platformAlert(params): void

  getRequestOrigin(): string
}
