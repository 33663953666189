import { FileModel } from './file.model'
import { Type } from 'class-transformer'

export class FileWrapperModel {
  @Type(() => FileModel)
  list: Omit<FileModel, 'content'>[] = []

  @Type(() => FileModel)
  added: FileModel[] = []

  @Type(() => FileModel)
  updated: FileModel[] = []

  @Type(() => FileModel)
  removed: Omit<FileModel, 'content'>[] = []

  constructor(obj: FileWrapperModel) {
    Object.assign(this, obj)
  }
}
