import { Injectable } from '@angular/core'
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http'

import { Observable } from 'rxjs'
import { PlatformSpecificService } from '../services/platform-specific.service'

const INTERCEPTOR_EXCEPTION = 'disableRequestOrigin'

/** Inject With Credentials into the request */
@Injectable()
export class RequestOriginInterceptor implements HttpInterceptor {
  constructor(private platspec: PlatformSpecificService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (req.headers.has(INTERCEPTOR_EXCEPTION)) {
      const headers = req.headers.delete(INTERCEPTOR_EXCEPTION)
      return next.handle(req.clone({ headers }))
    }

    req = req.clone({
      headers: req.headers.set(
        'RequestOrigin',
        this.platspec.getRequestOrigin(),
      ),
    })

    return next.handle(req)
  }
}
