import { Dictionary } from 'lodash'
import { DisplayModel } from './display.model'
import { AppSettings } from './app-settings.model'
import { DataJson } from './data.model'
import { Menu } from './menu.model'
import { EnrichedDisplayVariations } from './enriched-display.model'
import { Transform, Type } from 'class-transformer'
import { dictionaryType } from '../../utilities/type.utils'


export class CompleteJson {
  @Type(() => Menu)
  enrichedDisplays: Dictionary<EnrichedDisplayVariations>

  @Type(() => Menu)
  menusTree: Menu[]

  @Type(() => Menu)
  figures: Menu[]

  @Type(() => Menu)
  @Transform(({ value }) => dictionaryType(Menu, value))
  menu: Dictionary<Menu>

  @Type(() => AppSettings)
  appSettings: AppSettings

  @Type(() => DataJson)
  @Transform(({ value }) => dictionaryType(DataJson, value))
  data: Dictionary<DataJson>

  @Type(() => DisplayModel)
  @Transform(({ value }) => dictionaryType(DisplayModel, value))
  displays: Dictionary<DisplayModel>
  dashboards: any
}
