<div class="d-inline-block" ngbDropdown>
  <a
    class="text-muted waves-effect waves-dark"
    id="dropdownBasic1"
    ngbDropdownToggle
  >
    <i
      *ngIf="userHasDebugRights && debugCode === _g.language"
      class="fas fa-bug"
    ></i>
    <i
      *ngIf="debugCode !== _g.language"
      class="flag-icon"
      [ngClass]="'flag-icon-' + getCurrentLanguageIcon()"
    ></i>

    {{ getCurrentLanguageLabel() }}
  </a>
  <div aria-labelledby="dropdownBasic1" ngbDropdownMenu>
    <ng-container *ngFor="let language of _g.available_languages">
      <a
        *ngIf="language !== _g.language"
        class="dropdown-item"
        ngbDropdownItem
        (click)="changeLanguage(language)"
      >
        <i
          class="flag-icon"
          [ngClass]="'flag-icon-' + getLanguageIcon(language)"
        ></i>
        {{ getLanguageLabel(language) }}
      </a>
    </ng-container>
    <a
      *ngIf="userHasDebugRights && debugCode !== _g.language"
      class="dropdown-item"
      ngbDropdownItem
      (click)="translationsLiveMode()"
    >
      <i class="fas fa-bug"></i>
      {{ debugCode }}
    </a>
  </div>
</div>
