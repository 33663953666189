import { Injectable } from '@angular/core'
import { Dictionary, filter, forEach, groupBy, mapValues } from 'lodash'

import { plainToClass } from 'class-transformer'
import * as _ from 'lodash'
import { TranslateService } from '@ngx-translate/core'
import { MvHttpClient } from '../lib/MvHttpClient'
import { GlobalVars } from '../vars/globalvars'
import { PlatformSpecificService } from '../services/platform-specific.service'
import { Utilities } from '../utilities/utilities'
import { CompleteJson } from '../models/json/complete-json.model'
import { Menu } from '../models/json/menu.model'
import { DisplayModel } from '../models/json/display.model'
import { EnrichedDisplayVariations } from '../models/json/enriched-display.model'

@Injectable({ providedIn: 'root' })
export class DefinitionResource {
  constructor(
    private mvHttpClient: MvHttpClient,
    private _g: GlobalVars,
    private platspec: PlatformSpecificService,
    private utilities: Utilities,
    private translate: TranslateService,
  ) {}

  /*****************************
   *
   * Lecture de la définition des displays, menu, dataDef à partir du Web
   *
   */
  async readCompleteDefinitionFromWeb(language = ''): Promise<CompleteJson> {
    // Recherche de la structure via le REST
    const url = this._g.restRoot + 'app/complete'
    try {
      let completeJson = this._g?.completeJson

      if (!completeJson) {
        const data = await this.mvHttpClient
          .get<{ data: CompleteJson }>(url, {
            headers: this.utilities.getHTTPHeaders(),
          })
          .toPromise()

        completeJson = plainToClass(CompleteJson, data?.data)
      }

      if (language !== '') {
        completeJson = _.merge(
          completeJson,
          completeJson.displays?.the_languages?.es,
        )
      }

      // values de select : array !!
      // On a aussi les valeur dans les tables elles meme select request
      // Et puis les je sais pas quoi des gens par exemple
      // donc : cas : en dur appli + dans les json (avec pb de array) + chaque enregistrement peut être filtrée ? cas des communications ou traduite

      this._g.displaysDef = completeJson.displays
      this._g.dashboardsDef = completeJson.dashboards

      // Le filtrage des éléments par le rôle est désormais réalisés par l'API
      this._g.dashboardsLists = completeJson.dashboards.dashboardsLists

      // Tri suivant Index
      for (const dashName in this._g.dashboardsLists) {
        this._g.dashboardsLists[dashName] = this._g.dashboardsLists[
          dashName
        ].sort((d1, d2) => d1._index - d2._index)
      }

      this._g.menuDef = completeJson.menu
      this._g.menuList = this.utilities.generateArray(completeJson.menu)

      this._g.appSettings = completeJson.appSettings

      if (completeJson?.appSettings?.env?.disable_change_account) {
        this._g.disable_change_account =
          completeJson?.appSettings?.env?.disable_change_account
      }

      if (completeJson?.appSettings?.env?.disable_chat) {
        this._g.disable_chat = completeJson?.appSettings?.env?.disable_chat
      }

      if (completeJson?.appSettings?.env?.disable_notifications) {
        this._g.disable_notifications =
          completeJson?.appSettings?.env?.disable_notifications
      }

      if (completeJson?.appSettings?.env?.disable_breathing) {
        this._g.disable_breathing =
          completeJson?.appSettings?.env?.disable_breathing
      }

      if (completeJson?.appSettings?.env?.disable_notes) {
        this._g.disable_notes = completeJson?.appSettings?.env?.disable_notes
      }

      if (completeJson?.appSettings?.env?.disable_recaptcha) {
        this._g.disable_recaptcha =
          completeJson?.appSettings?.env?.disable_recaptcha
      }

      if (completeJson?.appSettings?.env?.disable_gratifications) {
        this._g.disable_gratifications =
          completeJson?.appSettings?.env?.disable_gratifications
      }

      const filteredMenus: Menu[] = filter(
        completeJson.menu,
        (menu) =>
          (menu.type === 'divider' || menu.type === 'menu') &&
          this.utilities.determineVisibility(menu.visible, {}),
      )

      completeJson.enrichedDisplays = this.calcEnrichedDisplays(
        completeJson.displays,
      )

      completeJson.menusTree = this.calcMenusTree(filteredMenus)

      // On passe le menu dans les Globals pour éviter le store
      this._g.menuTree = filter(
        completeJson.menusTree,
        (menu) =>
          !menu.parent &&
          menu.type !== 'figure' &&
          menu.visible !== false &&
          menu.web !== false,
      )

      completeJson.figures = filter(
        completeJson.menu,
        (menu) => menu.type === 'figure',
      )

      return completeJson
    } catch (error) {
      this.platspec.platformAlert({
        message: this.translate.instant('ERROR.LOAD_COMPLETE'),
        logData: error,
        user: 'toast',
        log: 'error',
        function: 'readCompleteDefinitionFromWeb-utilities.ts',
      })
      throw error
    }
  }

  orderMenus(menusList: Menu[]) {
    const result = menusList.filter((menu) => !menu.after)
    const menusListWithoutResult = menusList.filter((menu) => menu.after)

    for (const i in menusList) {
      const index = menusListWithoutResult.findIndex((menu) =>
        result.some((res) => res.name === menu.after),
      )
      if (index >= 0) {
        const lastResult = menusListWithoutResult.splice(index, 1)
        result.push(...lastResult)
      }
    }
    return [...result, ...menusListWithoutResult]
  }

  calcMenusTree(menus: Menu[]) {
    const menusByParent = groupBy(menus, 'parent')

    forEach(menusByParent, (subMenus, key) => {
      menusByParent[key] = this.orderMenus(subMenus)
    })

    forEach(menus, (menu) => {
      if (menusByParent[menu.name]) {
        menu.subMenu = menusByParent[menu.name]
      }
    })
    return this.orderMenus(menus)
  }

  calcEnrichedDisplays(
    displays: Dictionary<DisplayModel>,
  ): Dictionary<EnrichedDisplayVariations> {
    return mapValues(displays, (baseDisplay) => {
      const enrichedDisplay = this.utilities.enrichDisplay(baseDisplay)
      const variations = mapValues(
        baseDisplay.displayVariations,
        (currentVariation) =>
          this.utilities.enrichDisplay(
            this.utilities.mergeDeep(baseDisplay, currentVariation),
          ),
      )
      return new EnrichedDisplayVariations(enrichedDisplay, variations)
    })
  }
}
