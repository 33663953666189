import { Action, Selector, State, StateContext, Store } from '@ngxs/store'
import { Injectable } from '@angular/core'
import { ListState } from './list.state'
import {
  ChangeDate,
  DeleteListTableSettings,
  FieldReorder,
  FiltersChange,
  InitList,
  NextListPage,
  RefreshList,
  SaveListTableSettings,
  SearchList,
  SetButtonFilter,
  SetButtonFilterForList,
  ToggleOrder,
  ToggleVisible,
} from './list.actions'
import { cloneDeep } from 'lodash'
import { ListService } from '../services/list.service'
import { DisplayModel } from '../models/json/display.model'
import { TableSettings } from '../modules/components/table/table.settings'

@State<ListState>({
  name: 'list',
})
@Injectable()
export class ListStore {
  @Selector()
  static state(state: ListState) {
    return state
  }

  @Selector()
  static tableSettings(state: ListState) {
    return state?.tableSettings
  }

  @Selector()
  static buttonsFilter(state: ListState) {
    return state?.tableSettings?.buttonsFilter
  }

  @Selector()
  static buttonsFilterSql(state: ListState) {
    return state?.tableSettings?.buttonsFilterSql || ''
  }

  @Selector()
  static calendar(state: ListState) {
    return state?.calendar
  }

  constructor(private listService: ListService, private store: Store) {}

  isCurrentDisplay(display: DisplayModel, variationName: string): boolean {
    const state = this.store.selectSnapshot(ListStore.state)

    return (
      state.currentDisplay === display && state.variationName === variationName
    )
  }

  @Action(InitList)
  async initList(
    context: StateContext<ListState>,
    {
      displayName,
      variationName,
      currentDisplay,
      buttonsFilter,
      reset = false,
    }: InitList,
  ) {
    const currentState = context.getState()
    if (
      currentState?.displayName === displayName &&
      currentState?.variationName === variationName &&
      !reset
    ) {
      context.setState({
        currentDisplay,
        displayName,
        tableSettings: new TableSettings(currentState.tableSettings),
        variationName,
        calendar: currentState.calendar,
      })
    } else {
      const initTableSettings = this.listService.calcTableSettings(
        currentDisplay,
        variationName,
        buttonsFilter || currentDisplay.buttonsFilter,
      )

      // Stockés en session
      const tableSettings = this.getSavedTableSettings(
        initTableSettings,
        displayName,
        variationName,
      )

      context.setState({
        currentDisplay,
        displayName,
        tableSettings: new TableSettings(tableSettings),
        variationName,
        calendar: {
          viewType: 'dayGridMonth',
          start: null,
          end: null,
        },
      })
    }
  }

  @Action(NextListPage)
  async nextListPage(context: StateContext<ListState>, {}: NextListPage) {
    context.setState((state) => ({
      ...state,
      tableSettings: new TableSettings({
        ...state.tableSettings,
        offset: state.tableSettings.offset + state.tableSettings.pageLength,
      }),
    }))
  }

  @Action(SearchList)
  async searchList(context: StateContext<ListState>, { search }: SearchList) {
    context.setState((state) => ({
      ...state,
      tableSettings: new TableSettings({
        ...state.tableSettings,
        search,
        offset: 0,
      }),
    }))
  }

  @Action(RefreshList)
  async refreshList(context: StateContext<ListState>, { offset }: RefreshList) {
    context.setState((state) => ({
      ...state,
      tableSettings: new TableSettings({
        ...state.tableSettings,
        offset,
      }),
    }))
  }

  @Action(SaveListTableSettings)
  async saveListTableSettings(context: StateContext<ListState>, {}: void) {
    this.saveCurrentStageTableSettings(context)
  }

  @Action(DeleteListTableSettings)
  async deleteListTableSettings(context: StateContext<ListState>, {}: void) {
    const tableSettings = this.deleteCurrentStageTableSettings(context)

    context.setState((state) => ({
      ...state,
      tableSettings: new TableSettings({
        ...tableSettings,
        fields: state.tableSettings.fields,
      }),
    }))
  }

  @Action(SetButtonFilterForList)
  async setButtonFilterForList(
    { setState }: StateContext<ListState>,
    { buttonsFilter }: SetButtonFilterForList,
  ) {
    setState((state) => ({
      ...state,
      tableSettings: new TableSettings({
        ...state.tableSettings,
        buttonsFilter: cloneDeep(buttonsFilter),
        buttonsFilterSql: this.listService.buttonsFilterToSQL(buttonsFilter),
      }),
    }))
  }

  @Action(SetButtonFilter)
  async setButtonFilter(
    { setState }: StateContext<ListState>,
    { buttonsFilter }: SetButtonFilter,
  ) {
    setState((state) => ({
      ...state,
      tableSettings: new TableSettings({
        ...state.tableSettings,
        offset: 0,
        buttonsFilter: cloneDeep(buttonsFilter),
        buttonsFilterSql: this.listService.buttonsFilterToSQL(buttonsFilter),
      }),
    }))
  }

  @Action(FiltersChange)
  async onFiltersChange(
    { setState }: StateContext<ListState>,
    { tablesFilter, selectedValues }: FiltersChange,
  ) {
    setState((state) => ({
      ...state,
      tableSettings: new TableSettings({
        ...state.tableSettings,
        filters: tablesFilter,
        selectValues: selectedValues,
        offset: 0,
      }),
    }))
  }

  @Action(FieldReorder)
  async onFieldReorder(
    { setState }: StateContext<ListState>,
    { fields }: FieldReorder,
  ) {
    this.listService.calcFieldOrder(fields)

    setState((state) => ({
      ...state,
      tableSettings: new TableSettings({
        ...state.tableSettings,
        fields,
      }),
    }))
  }

  @Action(ToggleVisible)
  async toggleVisible(
    { getState, setState }: StateContext<ListState>,
    { field }: ToggleVisible,
  ) {
    const fieldFromState = getState().tableSettings.fields.find(
      (f) => f === field,
    )

    fieldFromState.visible = fieldFromState.visible === false

    setState((state) => ({
      ...state,
      tableSettings: new TableSettings({
        ...state.tableSettings,
        fields: state.tableSettings.fields,
      }),
    }))
  }

  @Action(ToggleOrder)
  async toggleOrder(
    { setState }: StateContext<ListState>,
    { source }: ToggleOrder,
  ) {
    setState((state) => ({
      ...state,
      tableSettings: new TableSettings({
        ...state.tableSettings,
        orders: this.listService.toggleOrder(
          state.tableSettings.orders,
          source,
        ),
        offset: 0,
      }),
    }))
  }

  @Action(ChangeDate)
  async changeView(
    { setState }: StateContext<ListState>,
    { viewType, start, end }: ChangeDate,
  ) {
    setState((state) => ({
      ...state,
      tableSettings: new TableSettings({
        ...state.tableSettings,
      }),
      calendar: { viewType, start, end },
    }))
  }

  private getSavedTableSettings(
    tableSettings: TableSettings,
    displayName: string,
    variationName: string,
  ): TableSettings {
    const savedTableSettings: TableSettings = JSON.parse(
      sessionStorage.getItem(`${displayName}_${variationName}`),
    )

    if (!savedTableSettings) {
      return tableSettings
    }

    // Search
    if (
      savedTableSettings.search &&
      savedTableSettings.search !== tableSettings.search
    ) {
      tableSettings.search = savedTableSettings.search
    }

    // PageLength
    if (
      savedTableSettings.pageLength &&
      savedTableSettings.pageLength !== tableSettings.pageLength
    ) {
      tableSettings.pageLength = savedTableSettings.pageLength
    }

    // Offset
    if (
      savedTableSettings.offset &&
      savedTableSettings.offset !== tableSettings.offset
    ) {
      tableSettings.offset = savedTableSettings.offset
    }

    // Orders
    if (
      savedTableSettings.orders &&
      savedTableSettings.orders !== tableSettings.orders
    ) {
      tableSettings.orders = savedTableSettings.orders
    }

    // Filters
    if (
      savedTableSettings.filters &&
      savedTableSettings.filters !== tableSettings.filters
    ) {
      tableSettings.filters = savedTableSettings.filters
    }

    // SelectValues
    if (
      savedTableSettings.selectValues &&
      savedTableSettings.selectValues !== tableSettings.selectValues
    ) {
      tableSettings.selectValues = savedTableSettings.selectValues
    }

    // ButtonsFilter
    if (
      savedTableSettings.buttonsFilter &&
      savedTableSettings.buttonsFilter !== tableSettings.buttonsFilter
    ) {
      tableSettings.buttonsFilter = savedTableSettings.buttonsFilter
    }

    return tableSettings
  }

  private saveCurrentStageTableSettings(context): void {
    const currentContextState = context.getState()

    sessionStorage.setItem(
      `${currentContextState.displayName}_${currentContextState.variationName}`,
      JSON.stringify(currentContextState.tableSettings),
    )
  }

  private deleteCurrentStageTableSettings(context): TableSettings {
    const currentContextState = context.getState()
    const tableSettings: TableSettings = currentContextState.tableSettings

    // Search
    tableSettings.search = null

    // PageLength
    tableSettings.pageLength = 10

    // Offset
    tableSettings.offset = 0

    // Filters
    tableSettings.filters = {}

    // SelectValues
    tableSettings.selectValues = {}

    sessionStorage.removeItem(
      `${currentContextState.displayName}_${currentContextState.variationName}`,
    )

    return tableSettings
  }
}
