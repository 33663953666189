import { Dictionary } from 'lodash'
import { Field } from './field.model'

export class DataJson {
  source: string
  display: string
  mobile: boolean
  mobile_mode: string
  fields: Dictionary<Field>
  default_display: string
  'public': boolean
  db_filter: string
  photo: string
  mobile_file: string
  search_requests: string
}
