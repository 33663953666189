import { BehaviorSubject, Observable } from 'rxjs'
import { io } from 'socket.io-client'
import { Injectable } from '@angular/core'
import { SocketEvent } from './socket-event.model'
import { GlobalVars } from 'src/app/shared/vars/globalvars'


@Injectable({ providedIn: 'root' })
export class SocketService {
  private socket

  constructor(public _g: GlobalVars) {}

  connect() {
    //a mettre dans le constructeur mais pose problème car _g n'est pas initialisé
    const url = this._g.appSettings.chatUrl

    // Exctract domain name from API URL
    const regex = /^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/\n]+)/g
    const [_, apiDomain] = regex.exec(this._g.restRoot)

    if (!this.socket && url && url !== '${CHAT_URL}') {
      this.socket = io(url, {
        query: {
          token: this._g.token,
          userId: this._g.userId,
          accountId: this._g.userAccountId,
          apiDomain,
        },
      })
    }
  }

  // Envoi d'un event
  sendEvent(type: string, event: SocketEvent) {
    if (this.socket) {
      this.socket.emit(type, event)
    }
  }

  // récupération des messages
  getEventOfType(type: string): Observable<SocketEvent> {
    const subject = new BehaviorSubject(null)

    if (this._g.appSettings.chatUrl && this.socket) {
      // S'il n'y a pas d'url de socket définie, pas besoin d'écouter les évènements..
      this.socket.on(type, (data) => {
        subject.next(data)
      })
    }

    return subject
  }
}
