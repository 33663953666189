import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http'
import { Inject, Injectable, LOCALE_ID } from '@angular/core'
import { Observable } from 'rxjs'
import { GlobalVars } from '../vars/globalvars'

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
  currentLocale: string

  constructor(@Inject(LOCALE_ID) public locale: string, public _g: GlobalVars) {
    this.currentLocale = this.locale.split('-', 1)[0]
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    // this.currentLocale ne change pas sans refresh, this._g.language est mise à jour
    const language = this._g?.language ? this._g.language : this.currentLocale
    const clonedRequest = req.clone({
      params: req.params.set('language', language),
    })

    return next.handle(clonedRequest)
  }
}
