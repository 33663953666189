import { Dictionary } from 'lodash'
import { Transform, Type } from 'class-transformer'
import { MenuOptions } from './menu-options.model'
import { Reminder } from './reminder.model'
import { dictionaryType } from '../../utilities/type.utils'


export class Menu {
  type: string

  label: string

  short_label: string

  request: string

  col: string

  after: string

  name: string

  _key: string

  icon: string

  display: string

  dashboard: string

  displayType: string

  shortcut: boolean

  color: string

  badgeSQL: string

  @Type(() => MenuOptions)
  options: MenuOptions

  notOnApp: boolean

  format: string

  digits: number

  @Type(() => Reminder)
  @Transform(({ value }) => dictionaryType(Reminder, value))
  displays: Dictionary<Reminder>

  visible: boolean

  parent: string

  chartType: string

  mapType: string

  mapCluster: boolean

  mapDraggable: boolean

  interval: number

  mapShowPhotos: boolean

  mapShowAllIntoWindows: boolean

  dataField: string

  labelField: string

  web: boolean

  mobile: boolean

  url: string

  displayInPlace: boolean

  addInPlace: boolean

  editInPlace: boolean

  //champs calculé à partir du JSON
  @Type(() => Menu)
  subMenu?: Menu[]

  //champs issus du menu dashboard pour la vue mobile uniquement
  fakeSubMenu?: any[]
}
