import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { DataLoaderService } from '../shared/modules/core/auth/data-loader.service';

@Injectable({ providedIn: 'root' })
export class GatewayResolver implements Resolve<any> {
  constructor(private dataLoaderService: DataLoaderService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<void> {
    return this.dataLoaderService.loadBaseObjects();
  }
}
