// Angular
import { AppComponent } from './app.component'
import { APP_INITIALIZER, Injector, LOCALE_ID, NgModule } from '@angular/core'
import { CommonModule, registerLocaleData } from '@angular/common'
import { RouterModule } from '@angular/router'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { routes } from './routes'
import { appInitializerFactory } from './app-initializer.factory'
import { environment } from 'src/environements/environment'
import { BrowserModule } from '@angular/platform-browser'
import { HttpClient, HttpClientModule } from '@angular/common/http'
import localeFr from '@angular/common/locales/fr'
import localeEs from '@angular/common/locales/es'

// Lib
import { DndModule } from '@ng-dnd/core'
import {
  GoogleLoginProvider,
  MicrosoftLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule,
} from '@abacritt/angularx-social-login'
import { NgxSpinnerModule } from 'ngx-spinner'
import { ToastModule } from 'primeng/toast'
import { NgSelectModule } from '@ng-select/ng-select'

import { DialogService } from 'primeng/dynamicdialog'
import { MessageService } from 'primeng/api'
import {
  TranslateService,
  TranslateLoader,
  TranslateModule as NgxTranslateModule,
} from '@ngx-translate/core'

import { FullCalendarModule } from '@fullcalendar/angular'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { MonacoEditorModule } from 'ngx-monaco-editor-v2'

// Shared
import { SharedModules } from './shared/modules/shared.modules'
import { ApiOfflineModule } from './shared/modules/components/api-offline/api-offline.module'
import {
  SentryConfig,
  SentryModule,
} from './shared/modules/core/sentry/sentry.module'
import { PLATFORM_SPECIFIC } from './shared/services/platform-specific.service'
import { PlatformSpecificWebService } from './shared/services/platform-specific-web.service'
import { GlobalVars } from './shared/vars/globalvars'
import { GeneralDialogModule } from './shared/modules/core/general-dialog/general-dialog.module'
import { CustomTranslateLoader } from './shared/modules/core/translate/custom-translate.loader'

import { AuthModule } from './shared/modules/core/auth/auth.module'
import { LikedUserModule } from './shared/modules/components/liked-user/liked-user.module'
import { ConfirmModalModule } from './shared/modules/confirm-modal/confirm-modal.module'

// Pages
import { StoreModule } from './pages/store/store.module'
import { UnauthorizedModule } from './pages/unauthorized/unauthorized.module'

import { LottieModule } from 'ngx-lottie'
import { httpInterceptorProviders } from './shared/interceptors'

registerLocaleData(localeEs, 'es')
registerLocaleData(localeFr, 'fr')

// Sentry configuration for this app
const sentryConfig: SentryConfig = {
  options: {
    dsn: environment.sentry.dsn,
    environment: environment.name,
    beforeSend(event: any) {
      return event
    },
  },
  errorHandling: environment.sentry.errorHandling,
  errorDialog: environment.sentry.errorDialog,
}

export function playerFactory() {
  return import('lottie-web')
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    RouterModule.forRoot(routes),
    CommonModule,
    StoreModule,
    SharedModules,
    DndModule,
    NgSelectModule,
    UnauthorizedModule,
    SentryModule.forRoot(sentryConfig),
    NgxSpinnerModule,
    ToastModule,
    SocialLoginModule,
    ApiOfflineModule,
    NgxTranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader,
        deps: [HttpClient, GlobalVars],
      },
    }),
    AuthModule,
    BrowserModule,
    HttpClientModule,
    GeneralDialogModule,
    BrowserAnimationsModule,
    LikedUserModule,
    ConfirmModalModule,
    FullCalendarModule,
    NgbModule,
    MonacoEditorModule.forRoot(),
    LottieModule.forRoot({ player: playerFactory }),
  ],
  providers: [
    { provide: PLATFORM_SPECIFIC, useClass: PlatformSpecificWebService },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: new Promise(async (resolve) => {
        let accountLoginUrl = new URL(environment.authentication_uri)

        if (environment.authentication_dynamic_uri_code) {
          const [account] = window.location.hostname.split('.')
          const externalUri = window.location.origin.replace(
            `${account}`,
            `${environment.authentication_dynamic_uri_code}`,
          )

          accountLoginUrl = new URL(externalUri + '/login')
        }

        let disableWebLogin = environment.disable_web_login || false
        const urlParams = new URLSearchParams(window.location.search)
        const forceLogin = urlParams.get('forceLogin')

        if (forceLogin || forceLogin === 'true') {
          disableWebLogin = false
        }

        const authenticationPaths = ['/first-connection', '/invitation']

        if (
          disableWebLogin === false &&
          (window.origin === accountLoginUrl.origin ||
            authenticationPaths.includes(window.location.pathname))
        ) {
          resolve({
            autoLogin: false,
            providers: [
              {
                id: GoogleLoginProvider.PROVIDER_ID,
                provider: new GoogleLoginProvider(
                  environment.google_client_id,
                  {
                    oneTapEnabled: true,
                    prompt: 'select_account',
                  },
                ),
              },
              {
                id: MicrosoftLoginProvider.PROVIDER_ID,
                provider: new MicrosoftLoginProvider(
                  environment.microsoft_client_id,
                  {
                    prompt: 'select_account',
                    redirect_uri: accountLoginUrl.origin,
                    logout_redirect_uri: accountLoginUrl.origin + '/logout',
                  },
                ),
              },
            ],
          } as SocialAuthServiceConfig)
        } else {
          // Pas d'authentification Google, on est sur l'URL de l'account
          resolve({
            autoLogin: false,
            providers: [],
          })
        }
      }),
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, GlobalVars, Injector],
      multi: true,
    },
    DialogService,
    MessageService,
    { provide: LOCALE_ID, useValue: 'fr' },
    httpInterceptorProviders,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {}
}
