import { Inject, Injectable } from '@angular/core'

import { AuthenticationService } from './authentication.service'
import { filter, first } from 'rxjs/operators'
import { AUTH_SERVICE } from 'ngx-auth'

import { Store } from '@ngxs/store'

import { SocketService } from '../socket/socket.service'
import { GlobalVars } from 'src/app/shared/vars/globalvars'
import { Utilities } from 'src/app/shared/utilities/utilities'
import { LoadJson } from 'src/app/shared/store/json.actions'
import { CompleteJsonStore } from 'src/app/shared/store/json.store'

@Injectable({ providedIn: 'root' })
export class DataLoaderService {
  constructor(
    @Inject(AUTH_SERVICE) private authenticationService: AuthenticationService,
    private _g: GlobalVars,
    private store: Store,
    private socketService: SocketService,
    private utilities: Utilities,
  ) {}

  /**
   * Wrappe juste la méthode initiale dans une promise pour l'utiliser plus facilement.
   * On migrera progressivement son fonctionnement.
   */
  public async loadBaseObjects(): Promise<void> {
    await this.authenticationService.loadProfile()
    await this.readCompleteDefinitionFromWeb()
    this.utilities.createMenuAndButtons({ definition: this._g.menuList })
    this.socketService.connect()
  }

  private async readCompleteDefinitionFromWeb() {
    this.store.dispatch(new LoadJson())
    return await this.store
      .select(CompleteJsonStore.state)
      .pipe(
        filter((v) => !!v),
        first(),
      )
      .toPromise()
  }
}
