import { Inject, Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router'

import { AUTH_SERVICE } from 'ngx-auth'
import { AuthenticationService } from '../shared/modules/core/auth/authentication.service'
import { RedirectionService } from '../shared/services/redirection.service'

@Injectable({
  providedIn: 'root',
})
export class IsSignedInGuard implements CanActivate {
  constructor(
    @Inject(AUTH_SERVICE) private authenticationService: AuthenticationService,
    private redirectionService: RedirectionService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const isSignedIn = this.authenticationService.isAuthorized$.value
    const pathname = window.location.pathname

    if (isSignedIn !== true) {
      if (pathname && pathname !== '/') {
        localStorage.setItem('lastPathname', pathname)
      }

      this.redirectionService.handleRedirection('/login')
    }

    return isSignedIn
  }
}
