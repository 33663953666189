import { ChangeDetectorRef, Directive, OnDestroy } from '@angular/core'
import { Observable, Subject, Subscription } from 'rxjs'

/**
 * Classe abstraite permettant de lier le cycle de vie des observables au cycle de vie des composants.
 */
@Directive()
export abstract class RxjsComponent implements OnDestroy {
  /**
   * Tableau contenant l'ensemble des souscriptions
   */
  subs: Subscription[] = []

  destroy$ = new Subject()

  constructor(private cdRef?: ChangeDetectorRef) {}

  /**
   * Permet d'ajouter des souscriptions à arrêter à la destruction du composant
   */
  register(...sub: Subscription[]) {
    this.subs = this.subs.concat(sub)
  }

  /**
   * En plus d'ajouter les observables passé en paramètre au tableau de souscription, on déclanche un cycle de détection de changements à chaque nouvelle valeur
   */
  markForCheck(...observables: Observable<any>[]) {
    if (!this.cdRef) {
      throw new Error(
        `L'usage de "markForCheck" nécessite d'avoir la référence du change detector injectée par la classe qui étend RxjsComponent`,
      )
    }

    this.register(
      ...observables.map((observable) =>
        observable.subscribe(
          () => this.cdRef.markForCheck(),
          () => this.cdRef.markForCheck(),
        ),
      ),
    )
  }

  /**
   * Détruit toutes les souscriptions à la destruction du composant
   */
  ngOnDestroy() {
    this.destroy$.next(null)
    this.destroy$.complete()
    this.subs.forEach((sub) => sub.unsubscribe())
  }
}
