import { Dictionary } from 'lodash'
import { DisplayModel } from './display.model'

export class EnrichedDisplay extends DisplayModel {}

export class EnrichedDisplayVariations {
  constructor(
    public baseDisplay: EnrichedDisplay,
    public variations: Dictionary<EnrichedDisplay>,
  ) {}
}
