import { Inject, Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router'

import { AUTH_SERVICE } from 'ngx-auth'

import { of } from 'rxjs'
import { environment } from 'src/environements/environment'
import { AuthenticationService } from '../shared/modules/core/auth/authentication.service'
import { RedirectionService } from '../shared/services/redirection.service'

@Injectable({
  providedIn: 'root',
})
export class AccountRedirectionGuard implements CanActivate {
  constructor(
    @Inject(AUTH_SERVICE) private authenticationService: AuthenticationService,
    private router: Router,
    private redirectionService: RedirectionService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // Params
    const isSignedIn = this.authenticationService.isAuthorized$.value

    let externalUrl = new URL(environment.authentication_uri)

    if (environment.authentication_dynamic_uri_code) {
      const [account] = window.location.hostname.split('.')
      const externalUri = window.location.origin.replace(
        `${account}`,
        `${environment.authentication_dynamic_uri_code}`,
      )

      externalUrl = new URL(externalUri + '/login')
    }

    const pathname = window.location.pathname

    if (isSignedIn && window.origin === externalUrl.origin) {
      // On redirige vers l'URL de l'account
      if (localStorage.getItem('redirectedFromOrigin')) {
        const accountlUrl = new URL(
          localStorage.getItem('redirectedFromOrigin'),
        )
        window.location.href = accountlUrl.origin + pathname
      } else {
        // On redirige vers le login
        this.redirectionService.handleRedirection('/login')
      }
    } else if (
      isSignedIn &&
      (!pathname || pathname === '/') &&
      localStorage.getItem('lastPathname') &&
      localStorage.getItem('lastPathname') !== '/'
    ) {
      const lastPathname = `${localStorage.getItem('lastPathname')}`
      localStorage.removeItem('lastPathname')

      this.router.navigateByUrl(lastPathname, {
        replaceUrl: true,
      })
    }

    return of(true)
  }
}
