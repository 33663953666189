import { Dictionary } from 'lodash'
import { SafeValue } from '@angular/platform-browser'
import { Constraint } from './constraint.model'
import { DisplayCondition } from './display-condition.model'

export class Field {
  _key: string

  _newField: boolean

  addDisplay: string

  addAsTag?: {
    display?: string
    labelField: string
    feedFields: any
  }

  subField: Field

  after: string

  baseUrl: string

  buttonsAdd: boolean

  calculation: any

  caption: string

  tooltip: string

  chart?: Dictionary<any>

  button_type: string

  col: string

  message: string

  max: number

  min: number

  animate: boolean

  step: number

  dataSource: string

  dataSource2: string

  dataType: string

  default: any

  digits: number

  disableGallery

  boolean

  isQuestionId: boolean

  display: string

  displayType: string

  format: string

  icon: string

  primaryColor: string

  actionColor: string

  secondaryColor: string

  index: number

  labelField: string

  groupField?: string
  selectableGroup?: boolean
  selectableGroupAsModel?: boolean

  lines: number

  maxLines: number

  multiple: boolean

  parent: string

  parentField: string

  pivot: string

  request: string

  searchable: boolean

  translatable?: boolean

  section: string

  show: string

  sortable: boolean

  source: string

  source2: string

  help?: string

  style: any

  subtype: string

  requestType: string

  type: string

  readOnly: boolean

  required: boolean

  isRecurrence: boolean

  showAppointments: boolean

  showAbsences: boolean

  displayLinkOnWarning: string

  displayLinkOnWarningDisplay: string

  displayAsBoolean: boolean

  displayAsWarning: string

  hideCaption = false
  hideValue = false

  displayLabel?: boolean

  source_id: string

  static: boolean

  target_id: string

  url: string | SafeValue

  // ça peut vraiment être un tableau de n'importe quoi apparement.
  values: any[]

  setValuesAfterUpdate: any[]

  setFiltersAfterUpdate: any[]

  saveValueInSession: boolean

  additionnalFields: any[]

  limit: number

  setAsResolved: boolean

  visible: boolean

  // Address Picker specific
  latitudeSource: string

  longitudeSource: string

  citySource: string

  zipcodeSource: string

  countrySource: string

  addressSource: string

  maxSelectItems: number

  maxFilesize: number

  withControlSameData: boolean

  constraints: Constraint[]

  displayCondition: DisplayCondition

  position: string

  mobile: boolean

  ignoredData: boolean

  link: any

  class: string

  items: any[]

  canAddInForm?: boolean

  canAddInFormDisplay?: string

  canAddInFormVariation?: string

  canAddInFormInPlace?: boolean

  canEditInForm?: boolean

  canEditInFormDisplay?: string

  canEditInFormVariation?: string

  canEditInFormInPlace?: boolean

  autoHide?: boolean

  onIcon?: string

  offIcon?: string

  onLabel?: string

  offLabel?: string

  placeholder?: string

  captionClass?: string

  height?: string

  width?: string

  limitedOptionsForHtml = false

  minMultiple: number

  text?: string

  textClass?: string

  transcription?: boolean

  hideSelectIfReadonly?: boolean
  selectRequestMultipleConcat?: boolean
  selectRequestMultipleConcatCode?: string

  informationButton: any

  itemsFields: any[]

  constructor(obj: Partial<Field>) {
    Object.assign(this, obj)
  }
}
