import { DataModel } from './data.model'

export class DataFormated<T extends DataModel> {
  specialData: SpecialData

  data: T

  readMoreClass: 'kizeo-collapsed'
}

export class SpecialData {
  id: number

  _is_new: boolean

  openDialog?: boolean;

  [key: string]: any

  constructor(obj: Partial<SpecialData>) {
    Object.assign(this, obj)
  }
}
