import { Order } from './order.model'
import { Dictionary } from 'lodash'
import { Field } from 'src/app/shared/models/json/field.model'
import { TableFilter } from 'src/app/shared/models/json/table-filter.model'

export class TableSettings {
  fields: Field[] = []
  buttonsFilter = {}
  buttonsFilterSql: string
  filters: Dictionary<TableFilter<any>> = {}
  selectValues: Dictionary<any> = {}
  orders: Order[] = []
  offset = 0
  pageLength = 10
  search: string

  constructor(obj: Partial<TableSettings>) {
    if (obj.fields) {
      this.fields = obj.fields
    }
    if (obj.buttonsFilter) {
      this.buttonsFilter = obj.buttonsFilter
    }
    if (obj.buttonsFilterSql) {
      this.buttonsFilterSql = obj.buttonsFilterSql
    }
    if (obj.filters) {
      this.filters = obj.filters
    }
    if (obj.selectValues) {
      this.selectValues = obj.selectValues
    }
    if (obj.orders) {
      this.orders = obj.orders
    }
    if (obj.offset) {
      this.offset = obj.offset
    }
    if (obj.pageLength) {
      this.pageLength = obj.pageLength
    }
    if (obj.search) {
      this.search = obj.search
    }
  }
}
