import { Action, Selector, State, StateContext } from '@ngxs/store'
import { LoadJson } from './json.actions'
import { Injectable } from '@angular/core'
import { CompleteJsonState } from './complete-json.state'
import { filter } from 'lodash'
import { plainToClass } from 'class-transformer'
import { DefinitionResource } from '../resources/definition.resource'
import { Utilities } from '../utilities/utilities'

@State<CompleteJsonState>({
  name: 'completeJson',
})
@Injectable()
export class CompleteJsonStore {
  @Selector()
  static state(state: CompleteJsonState) {
    return state.completeJson
  }

  @Selector()
  static menusTree(state: CompleteJsonState) {
    return filter(
      state.completeJson?.menusTree,
      (menu) =>
        !menu.parent &&
        menu.type !== 'figure' &&
        menu.visible !== false &&
        menu.web !== false,
    )
  }

  @Selector()
  static menusTreeMobile(state: CompleteJsonState) {
    return filter(
      state.completeJson?.menusTree,
      (menu) => !menu.parent && menu.type !== 'figure' && menu.mobile !== false,
    )
  }

  @Selector()
  static figures(state: CompleteJsonState) {
    return state.completeJson.figures
  }

  @Selector()
  static currentDisplay(displayName: string, variationName: string) {
    return (state: { completeJson: CompleteJsonState }) => {
      if (variationName && variationName !== 'null') {
        return state.completeJson.completeJson?.enrichedDisplays[displayName]
          ?.variations?.[variationName]
      } else {
        return state.completeJson.completeJson?.enrichedDisplays[displayName]
          ?.baseDisplay
      }
    }
  }

  @Selector()
  static currentDisplayLabel(displayName: string, variationName: string) {
    return (state: { completeJson: CompleteJsonState }) => {
      const currentDisplay = CompleteJsonStore.currentDisplay(
        displayName,
        variationName,
      )(state)
      return currentDisplay?.label
    }
  }

  constructor(
    private utilities: Utilities,
    private definitionResource: DefinitionResource,
  ) {}

  @Action(LoadJson)
  async loadJson(context: StateContext<CompleteJsonState>) {
    const completeJson =
      await this.definitionResource.readCompleteDefinitionFromWeb()
    context.setState(plainToClass(CompleteJsonState, { completeJson }))
  }
}
