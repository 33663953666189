import { Injectable } from '@angular/core'

import { BehaviorSubject } from 'rxjs'
import { environment } from 'src/environements/environment'
import { Breadcrumb } from '../models/breadcrumb'
import { Menu } from '../models/json/menu.model'

@Injectable({ providedIn: 'root' })
export class GlobalVars {
  public appType = ''

  public appName = ''

  public appVersion = ''

  public appClass = ''

  public restRoot = ''

  public mobileMode = ''

  public restPrefix = ''

  public userLogin = ''

  public userAccount = ''

  public userAccountId = ''

  public registerPath = ''

  public redirectUrl = ''

  public userId?: string

  public appCodeName = ''

  public appLogoCodeName = ''

  public appUri = ''

  public generalBackground?: string

  // Token obtenu du BO après identification
  public token = ''

  // flag if the user is connected by social auth
  public userIsSocialAuthConnected = false

  // provider code for social auth
  public providerSocialAuth = ''

  // For fix bug if social OAuth signout after reload the page
  public haveSocialConnectionActive = false

  // Chaine récupérée sur le BO contenant la définition du menu
  public menuDef: any

  // Structure hiérarchisée du menu
  public menuList?: Menu[]

  public currentMenuKey?: string

  public figuresList?: any[]

  public menuButtons: any

  public shortcuts: any

  public appSettings: any = {}

  public completeJson: any

  // Chaine récupérée sur le BO contenant la définition des displays
  public displaysDef: any

  public currentDisplay?: string

  public currentItemId?: number

  public isDisplayInPlace?: boolean

  public isAddInPlace?: boolean

  public isEditInPlace?: boolean

  // Chaine récupérée sur le BO contenant la définition des dashboards
  public dashboardsDef: any

  public dashboardsLists: any

  public currentDashboardGroup?: string = 'base'

  public currentDashboardGroup$: BehaviorSubject<string> =
    new BehaviorSubject<string>(null)

  public currentDashboardName?: string

  // Liste des définitions des reminders
  public reminders: any = {}

  // Grosse variable pour stocker globalement des infos entre les pages
  public context: any = { global: {} }

  // resultat de la recherche de all queries
  public queries: any = {
    requests: {},
    time: new Date(),
  }

  // language de l'utilisateur
  public language = ''

  public default_language = environment.default_language.trim()

  public available_languages = environment.available_languages
    .split(',')
    .map((value) => value.trim())

  public isApiOnline$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true,
  )

  public isTranslationsModal$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false)

  public translationSearch$: BehaviorSubject<string> =
    new BehaviorSubject<string>(null)

  public translationSearchType$: BehaviorSubject<string> =
    new BehaviorSubject<string>(null)

  public translationSearchCode$: BehaviorSubject<string> =
    new BehaviorSubject<string>(null)

  public isExerciseBreathing$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false)

  public translationsLiveMode$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false)

  public isStickyNotes$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false)

  public stickyNotesContext$: BehaviorSubject<{
    display: string
    itemId: number
  } | null> = new BehaviorSubject<{ display: string; itemId: number } | null>(
    null,
  )

  public stickyNotesInPlaceContext$: BehaviorSubject<{
    display: string
    itemId: number
  } | null> = new BehaviorSubject<{ display: string; itemId: number } | null>(
    null,
  )

  public stickyNotesContextCount$: BehaviorSubject<number | null> =
    new BehaviorSubject<number | null>(null)

  public isChangingLanguage: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false)

  public isChangingDashboard: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false)

  public menuTree?: Menu[]

  public figures: Array<any> = []

  public charts: Array<any> = []

  // Objet global de base de données (à priori seuelement pour la partie mobile)
  public db: any

  // Objet avec les infos du user courant
  public me: any = []

  public accountSettings: any = {}

  // messages pour la page d'accueil
  public messages: any = []

  // Array pour stocker les Routes
  public breadcrumbs: Breadcrumb[] = []

  // Array pour stocker les infos de données temporaires des formulaires
  public tempFormsData: any = []

  // Variable pour gérer les barres de progression en chargement de requetes
  public current_progress_bar_value = 0

  public max_progress_bar_value = 0

  public maxiRole = 'user'

  /*******
   *
   * Settings
   *
   */
  public modifyDisplaySettings = false

  public modifyDisplayClass = 'not-modify'

  /************
   *
   * Communications
   *
   */
  showCommunications = false

  communications = []

  /**
   * Il manquait la déclaration de cette variable
   */
  previousId: any

  headerType = ''

  headerLogo = ''

  // Variable pour tracer quand on est en synchronisation
  syncInProgress = false

  syncIsCancelled = false

  // Variable générale pour savoir si appli en background ou pas
  appInBackground = false

  // Infos sur la bannière éventuelle
  banner = { title: '', subtitle: '', color: '', image: '' }

  /************
   *
   * Regex
   *
   */
  readonly emailCheckReg =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  // Variable qui permettra de signaler qu'un rafraichissement des données est nécessaire (lors de l'ajout ou la modification de données)
  public isRefreshingDataNeeded$: BehaviorSubject<string[]> =
    new BehaviorSubject<string[]>([])

  public isCampainToTest$: BehaviorSubject<number> =
    new BehaviorSubject<number>(null)

  public dataAdded$: BehaviorSubject<{ source: string; id: number }[]> =
    new BehaviorSubject<{ source: string; id: number }[]>([])

  public stayOnDetailOnUpdate?: boolean

  public isAutoSaving?: boolean

  public celebrate: any

  public currentManagementSkillsCampainIndex$: BehaviorSubject<string> =
    new BehaviorSubject<string>(null)

  public disable_change_account = environment.disable_change_account
  public disable_chat = environment.disable_chat
  public disable_notifications = environment.disable_notifications
  public disable_breathing = environment.disable_breathing
  public disable_notes = environment.disable_notes
  public disable_recaptcha = environment.disable_recaptcha
  public disable_gratifications = environment.disable_gratifications

  constructor() {
    globalVars = this
    this.context.global = this
  }
}

export let globalVars: GlobalVars
