import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'

import { TranslateService } from '@ngx-translate/core'
import { DialogService } from 'primeng/dynamicdialog'
import { MessageService } from 'primeng/api'
import { IPlatformSpecific } from './platform-specific.service'
import { GlobalVars } from '../vars/globalvars'
import { GeneralDialogComponent } from '../modules/core/general-dialog/general-dialog.component'

@Injectable()
export class PlatformSpecificWebService implements IPlatformSpecific {
  constructor(
    private _g: GlobalVars,
    private http: HttpClient,
    private translateService: TranslateService,
    private messageService: MessageService,
    public dialogService: DialogService,
  ) {}

  /***********  Test Online */
  isOnline() {
    return true
  }

  getDeviceDataDirectory() {
    return ''
  }

  // Fake write File (on n'en a pas besoin pour le Web)
  writeFile(params) {}

  // Fake read File (on n'en a pas besoin pour le Web)
  readAsText(params) {}

  readAsDataURL(params) {}

  fileExists(path, file) {
    return true
  }

  // Message : sert pour faire une alerte utilsateur, et/ou stocker des données de logs
  /*
      {title, message, function, logData, user:alert/toast/none, log:info/error/none}

  */
  platformAlert(params) {
    //   Logguer (avant tout)
    if (params.log === 'error' || params.log === 'info') {
      const logMessage = {
        message: params.message,
        data: params.logData,
        user: this._g.userId,
        account: this._g.userAccount,
        logType: params.log,
      }
      // On écrit dans le log
      if (this._g.token) {
        const headers = new HttpHeaders()
        headers.append('RequestOrigin', 'mobileComplete')
        headers.append('Authorization', 'Bearer ' + this._g.token)
        headers.append('Prefix', this._g.restPrefix.replace('/', ''))
        const url = this._g.restRoot + 'app/log/savelog'
        this.http.post<any>(url, logMessage, { headers }).subscribe()
      }
    }

    if (params.user === 'toast') {
      const message = this.translateService.instant(params.message)
      this.messageService.add({
        severity: params.log,
        summary:
          params.title || this.translateService.instant('ERROR.DIALOG_TITLE'),
        detail: message,
      })
    } else if (params.user && params.user !== 'none') {
      this.dialogService.open(GeneralDialogComponent, {
        header:
          params.title || this.translateService.instant('ERROR.DIALOG_TITLE'),
        data: {
          body: params.message,
          showOK: true,
          showCancel: false,
          showYes: false,
          showNo: false,
        },
        baseZIndex: 500000,
      })
    }
  }

  getRequestOrigin() {
    return 'mobileComplete'
  }
}
