import { CommonModule } from '@angular/common'
import {
  ErrorHandler,
  Inject,
  Injectable,
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core'
import * as Sentry from '@sentry/browser'

export interface SentryConfig {
  options: {
    dsn: string
    environment?: string
    beforeSend: any
  }
  errorHandling: boolean
  errorDialog: boolean
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor(@Inject('SENTRY_CONFIG') private sentryConfig: SentryConfig) {
    Sentry.init(this.sentryConfig.options)
  }

  handleError(error) {
    const eventId = Sentry.captureException(error?.originalError || error)

    if (this.sentryConfig.errorDialog) {
      Sentry.showReportDialog({ eventId })
    }
  }
}

@Injectable()
export class SentryService {
  constructor() {}

  setKizeoUserInfo(global) {
    this.setTag('customer', global.userAccount)
    this.setTag('user_id', `${global.userId}`)
    this.setTag('customer_id', `${global.userAccountId}`)
    this.setTag('corp_user_id', global.corpUser?.corp_user_id)
    this.setTag('corp_customer_id', global.corpUser?.corp_customer_id)

    this.setUser({
      ip_address: '{{auto}}',
      first_name: global.me.first_name,
      last_name: global.me.last_name,
      email: global.me.email,
      username: global.me.login,
    })
  }

  setUser(user: Sentry.User) {
    Sentry.setUser(user)
  }

  setTag(key: string, value: any) {
    Sentry.setTag(key, value)
  }

  setExtra(key: string, value: any) {
    Sentry.setExtra(key, value)
  }

  addBreadcrumbs(breadcrumb: Sentry.Breadcrumb) {
    Sentry.addBreadcrumb(breadcrumb)
  }

  captureMessage(message: string, captureContext?: any | Sentry.Severity) {
    Sentry.captureMessage(message, captureContext)
  }

  captureException(exception: any, title, captureContext?: any) {
    exception.name = `${title} : ${exception.name}`

    Sentry.captureException(exception, captureContext)
  }

  captureEvent(event: Sentry.Event) {
    Sentry.captureEvent(event)
  }
}

@NgModule({
  imports: [CommonModule],
})
export class SentryModule {
  constructor(@Optional() @SkipSelf() parentModule?: SentryModule) {
    if (parentModule) {
      throw new Error(
        'SentryModule is already loaded. Import it in the AppModule only',
      )
    }
  }

  static forRoot(config: SentryConfig): ModuleWithProviders<SentryModule> {
    return {
      ngModule: SentryModule,
      providers: [
        { provide: 'SENTRY_CONFIG', useValue: config },
        {
          provide: ErrorHandler,
          useClass: config.errorHandling ? SentryErrorHandler : ErrorHandler,
        },
        SentryService,
      ],
    }
  }
}
