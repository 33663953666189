import { uniqueId } from 'lodash'
export class FileModel {
  /**
   * ID du fichier
   */
  id: number

  /**
   * Nom du fichier
   */
  name: string

  /**
   * Nom du fichier tel que stocké sur S3
   */
  file: string

  /**
   * contenu du fichier en base64
   */
  content?: string

  /**
   * taille du fichier en octets
   */
  size: string

  /**
   * Mime type : Application/pdf par exemple
   */
  mimetype: string

  create_time: string

  type: 'upload' | 'photo' | 'audio'

  // Permet de cloisonner les fichiers pour un Field
  request_field?: string

  /**
   * clé d'unicité permettant de ne pas confondre deux fichiers ayant le même nom
   */
  unicity: string

  is_force_preview: any = 0

  openDialog = false

  order: number

  transcription?: string

  constructor(obj: Partial<FileModel>) {
    Object.assign(this, obj)

    this.unicity = uniqueId()
  }
}
