import { Component, OnInit } from '@angular/core';
import { GlobalVars } from 'src/app/shared/vars/globalvars';

@Component({
  selector: 'mv-api-offline',
  templateUrl: './api-offline.component.html',
  styleUrls: ['./api-offline.component.scss'],
})
export class ApiOfflineComponent implements OnInit {
  public isApiOnline = true;

  constructor(public _g: GlobalVars) {}

  ngOnInit() {
    this._g.isApiOnline$.subscribe((isApiOnline: boolean) => {
      this.isApiOnline = isApiOnline;
    });
  }
}
