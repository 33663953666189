import { Component } from '@angular/core'

import { DefinitionResource } from 'src/app/shared/resources/definition.resource'
import { Utilities } from 'src/app/shared/utilities/utilities'
import { GlobalVars } from 'src/app/shared/vars/globalvars'
import { LanguageIcon } from './enum/language-icon.enum'
import { Language } from './enum/language.enum'
import { TranslateService } from '@ngx-translate/core'
import { Router } from '@angular/router'

export const DEBUG_CODE = 'DEBUG_HEROES'
@Component({
  selector: 'mv-dropdown-language',
  templateUrl: './dropdown-language.component.html',
  styleUrls: ['./dropdown-language.component.scss'],
})
export class DropdownLanguageComponent {
  private LANGUAGE = 'language'

  public userHasDebugRights: boolean
  public debugCode = DEBUG_CODE

  constructor(
    public _g: GlobalVars,
    private definitionResource: DefinitionResource,
    public utilities: Utilities,
    private translate: TranslateService,
    private router: Router,
  ) {
    this.userHasDebugRights =
      this._g.me.has_all_rights === true ||
      this._g.me.has_all_rights === 1 ||
      this._g.me.has_all_rights === '1'
        ? true
        : false
  }

  async changeLanguage(language) {
    const currentLangIsDebug = this._g.language === DEBUG_CODE
    const newLangIsDebug = language === DEBUG_CODE

    this._g.language = language
    this.translate.use(language)

    // On passe la valeur en cache pour pouvoir la récupérer en cas de refresh
    this.utilities.setLocally(this.LANGUAGE, this._g.language)

    // On met à jour le complete
    await this.definitionResource.readCompleteDefinitionFromWeb(language)
    this.utilities.createMenuAndButtons({ definition: this._g.menuList })

    // Permet de mettre à jour les variables globales pour l'affichage
    this._g.isChangingLanguage.next(true)

    if (currentLangIsDebug || newLangIsDebug) {
      this.router.navigate(['/']).then(() => window.location.reload())
    }
  }

  public getCurrentLanguageIcon() {
    if (this._g.language === this.debugCode) {
      return ' fas fa-bug'
    }

    return LanguageIcon[this._g.language.substring(0, 2)]
  }

  public getCurrentLanguageLabel() {
    if (this._g.language === this.debugCode) {
      return this.debugCode
    }

    return Language[this._g.language.substring(0, 2)]
  }

  public getLanguageIcon(language: string) {
    return LanguageIcon[language]
  }

  public getLanguageLabel(language: string) {
    return Language[language]
  }

  public translationsLiveMode(): void {
    if (!this.userHasDebugRights) {
      return
    }

    this.translate.resetLang(this._g.default_language)
    this.translate.getLangs().forEach((lang) => {
      this.translate.resetLang(lang)
    })

    this.translate.setDefaultLang(this.debugCode)
    this.translate.use(this.debugCode)

    this.changeLanguage(this.debugCode)
  }
}
