import { Injectable } from '@angular/core'
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http'

import { Observable } from 'rxjs'

const INTERCEPTOR_EXCEPTION = 'disableWithCredentials'

/** Inject With Credentials into the request */
@Injectable()
export class AddCredentialsInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (req.headers.has(INTERCEPTOR_EXCEPTION)) {
      const headers = req.headers.delete(INTERCEPTOR_EXCEPTION)
      return next.handle(req.clone({ headers }))
    }

    req = req.clone({
      withCredentials: true,
    })

    return next.handle(req)
  }
}
