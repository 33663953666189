<p-dialog
  [visible]="!isApiOnline"
  [draggable]="false"
  [closable]="false"
  [showHeader]="false"
  [modal]="true"
  [contentStyleClass]="'offline-overlay w-100 h-100'"
  [style]="{
    height: '90vh',
    width: '90vw',
    opacity: 0.9,
    'border-radius': '5px',
    'box-shadow': 'none'
  }"
  appendTo="body"
>
  <div
    class="error_holder w-100 h-100 d-flex flex-column align-items-center justify-content-center"
  >
    <img class="not-found" src="assets/images/404.jpg" />
    <h1 class="uppercase title cyan">
      {{ 'API_OFFLINE.TITLE' | translate }}
    </h1>
    <br />
    <h2 class="uppercase">
      {{ 'API_OFFLINE.DESCRIPTION' | translate }}
    </h2>
  </div>
</p-dialog>
