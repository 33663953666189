import 'intl'
import 'intl/locale-data/jsonp/en'
import 'intl/locale-data/jsonp/fr'
import 'intl/locale-data/jsonp/es'
import 'intl/locale-data/jsonp/de'

import { Injectable } from '@angular/core'

import * as moment from 'moment'

import { DomSanitizer } from '@angular/platform-browser'

import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http'

import { cloneDeep, isArray, difference, last } from 'lodash'

import { plainToClass } from 'class-transformer'

import { TranslateService } from '@ngx-translate/core'
import { GlobalVars } from '../vars/globalvars'
import { PlatformSpecificService } from '../services/platform-specific.service'
import { FieldService } from '../services/field.service'
import { DataModel } from '../models/data.model'
import { DisplayModel } from '../models/json/display.model'
import { DataFormated, SpecialData } from '../models/data-formated.model'
import { FileWrapperModel } from '../models/file-wrapper.model'
import { Field } from '../models/json/field.model'
import { PhotoWrapperModel } from '../models/photo-wrapper.model'
import { SocialUserModelProvider } from '../models/social-user.model'
import {
  FORBIDDEN_ERROR_CODE,
  NOT_FOUND_ERROR_CODE,
} from '../resources/request.ressource'
import { MessageService } from 'primeng/api'

@Injectable({ providedIn: 'root' })
export class Utilities {
  // Variable spéciale pour stocker les fichiers en cours d'écriture pour éviter les écritures concurrentielles
  fileWriting: any = []

  /*********************
   * référencement de moment dans utilities pour pouvoir l'utiliser
   * dans les eval des filtres notamment (appeler utilities.moment()...)
   */
  moment = moment

  util = this

  constructor(
    private _g: GlobalVars,
    private http: HttpClient,
    private platspec: PlatformSpecificService,
    private sanitizer: DomSanitizer,
    private fieldService: FieldService,
    private translate: TranslateService,
    private messageService: MessageService,
  ) {
    // On rafraichit la bannière en cas de changement de langue
    this._g.isChangingLanguage.subscribe((value: boolean) => {
      if (value && this._g.currentDashboardName) {
        this.initDashboard(this._g.currentDashboardName)
      }
    })
  }

  /**************************************
   * Calcule les ReadOnly en fonction des data et des définition des fields
   */
  calculateReadOnly(fields, data) {
    // Utilisé par l'éval
    const context = this._g.context

    fields.forEach((field) => {
      if (
        data?.id &&
        (field?.editReadOnly === true || field?.editReadOnly === 'true')
      ) {
        field.readOnly = true
      } else if (
        data?.id &&
        typeof data?.canEdit === 'boolean' &&
        data?.canEdit === false
      ) {
        field.readOnly = true
      } else if (field.readOnly) {
        if (field.readOnly === true || field.readOnly === 'true') {
          field.readOnly = true
        } else if (field.readOnly === false || field.readOnly === 'false') {
          field.readOnly = false
        } else {
          // On est sur une formule
          if (isArray(field.readOnly)) {
            field.readOnly = this.canAction(data, field.readOnly)
          }
        }
      }
    })
  }

  /**
   * Permet de savoir si on peut effectuer une action sur un élément.
   * Si action est un tableau de condition on les évalue jusqu'à trouver une condition non passante
   * Si action est un boolean on regarde s'il est à true.
   */
  canAction<T extends DataModel>(
    data: T,
    action: boolean | Array<{ field: keyof T; operator: string; value: any }>,
  ): boolean {
    if (isArray(action) && data) {
      return action.every((cond) => {
        if (cond.operator === null && cond.value === 'is null') {
          return !data[cond.field]
        }
        // Compatibilité ancien format
        else if (cond.operator === '===' && cond.value === 'user') {
          return data[cond.field] === this._g.userId
        } else if (cond.operator === '!==' && cond.value === 'user') {
          return data[cond.field] !== this._g.userId
        } else if (cond.operator === '=' && cond.value === 'current_user_id') {
          return data[cond.field] === this._g.userId
        } else if (cond.operator === '!=' && cond.value === 'current_user_id') {
          return data[cond.field] !== this._g.userId
        } else if (cond.operator === '!=' && cond.value === 'current_user_id') {
          return data[cond.field] !== cond.value
        } else if (cond.operator === '=') {
          return data[cond.field] === cond.value
        } else if (cond.operator === '!=') {
          return data[cond.field] !== cond.value
        }

        return null
      })
    } else {
      return action !== false
    }
  }

  /*************
   * Determine la visibilité en fonction de la formule de visibilité
   */
  determineVisibility(formula, data, source = null) {
    if (formula === undefined || formula === '') {
      return true
    } else if (formula === 'true' || formula === true) {
      return true
    } else if (formula === false || formula === 'false') {
      return false
    } else {
      let newFormula = formula

      if (data && typeof newFormula === 'string') {
        newFormula = newFormula.replace(/field\[\'/g, "context.dataItem['")

        try {
          const context = { ...this, data: data }

          return this.launch(newFormula, context)
        } catch (error) {
          console.warn(error?.originalError || error)
          return true
        }
      } else {
        return true
      }
    }
  }

  /* Remarque : on assume que la requete et la tables s'appellent pareil */
  async getCommunicationsData(communicationRequest, result) {
    if (this._g.displaysDef[communicationRequest].dataDisplay) {
      // Récupération des communications
      const data = await this.getDataPromise(communicationRequest, {
        dataFilter:
          "(begin_date is null or begin_date<='" +
          moment(Date()).format('YYYY-MM-DD') +
          "') and (end_date is null or end_date>='" +
          moment(Date()).format('YYYY-MM-DD') +
          "') and IFNULL(current, 0) = 1",
        fieldOrder: this._g.displaysDef[communicationRequest].fieldOrder
          ? this._g.displaysDef[communicationRequest].fieldOrder
          : 'id',
        fieldOrderDesc: this._g.displaysDef[communicationRequest]
          .fieldOrderDirection
          ? this._g.displaysDef[communicationRequest].fieldOrderDirection
          : 'desc',
      })
      // Enrichissement des données
      const commDisplay = this.enrichDisplay(
        this._g.displaysDef[communicationRequest],
      )
      result.communications = this.formatDataForDisplay(
        data.data,
        commDisplay,
        'form',
      )
    }
  }

  async getDiaporamasData(diaporamaRequest, result) {
    // Récupération des diaporamas
    const data = await this.getDataPromise(diaporamaRequest, {
      dataFilter: '(delete_time is null)',
      fieldOrder: this._g.displaysDef[diaporamaRequest]?.fieldOrder
        ? this._g.displaysDef[diaporamaRequest].fieldOrder
        : null,
      fieldOrderDesc: this._g.displaysDef[diaporamaRequest]?.fieldOrderDirection
        ? this._g.displaysDef[diaporamaRequest].fieldOrderDirection
        : null,
    })

    result.diaporamas = data?.data
  }

  sortReminders(a: any, b: any) {
    if (a.reminderDetails.priority < b.reminderDetails.priority) {
      return -1
    }

    if (a.reminderDetails.priority > b.reminderDetails.priority) {
      return 1
    }
    return 0
  }

  getReminders(params) {
    // On récupére la définition de chaque éléments
    // On fusionne avec les définition des displays
    this._g.reminders['data'] = []

    if (this._g.reminders.displays) {
      const remindersArray = this.generateArray(this._g.reminders.displays)
      remindersArray.forEach(async (reminderItem) => {
        // On récupère la définition de base du display
        const baseDisplay = cloneDeep(this._g.displaysDef[reminderItem.display])
        // ON fusionne les 2
        const reminderDisplay = this.mergeDeep(baseDisplay, reminderItem)

        // on enlève les notions de boutons de filtre et de variations dont on se fiche
        // et qui peuvent potentiellement nous embeter
        delete reminderDisplay.buttonsFilter
        delete reminderDisplay.alias_displays
        delete reminderDisplay.displayVariations
        delete reminderDisplay.totals

        let items = []
        const callbackParams = await this.getDisplayListData({
          display: reminderDisplay,
          items,
        })
        items = cloneDeep(callbackParams.data)
        let index = 0
        let postitStyle = 'none'
        if (Math.random() * 3 > 2) {
          postitStyle = 'right'
        }
        if (Math.random() * 3 > 1) {
          postitStyle = 'left'
        }
        items.forEach((item) => {
          item.reminderDetails = {
            display: reminderItem.display,
            id: item.id,
            title: reminderItem.reminderTitle,
            icon: reminderItem.reminderIcon,
            color: reminderItem.reminderIconColor,
            postitStyle,
            isFirst: index === 0,
            priority: reminderItem.priority,
          }
          index++
        })

        this._g.reminders['data'].push(...items)

        this._g.reminders['data'] = this._g.reminders['data'].sort(
          this.sortReminders,
        )

        if (params.onSuccess) {
          params.onSuccess()
        }
      })
    }
  }

  /*****************
   *  récupération des données
   */
  async getDisplayListData(params) {
    const utilities = this
    const context = this._g.context

    if (
      this._g.appType === 'app' &&
      this._g.displaysDef[params.display.displayRequest].dataDisplay
        .mobile_mode === 'distant' &&
      !this.platspec.isOnline()
    ) {
      alert('Les données ne peuvent être récupérée, vous devez être connecté')
      return null
    }

    /*****
     * Cacul du filtre
     */
    // Filtre du display
    let currentFilter = ''

    if (params.display.displayFilter) {
      // On a éventuellement une formule de calcul

      if (params.display.displayFilter.substring(0, 1) === '=') {
        try {
          currentFilter = this.launch(params.display.displayFilter, this)
        } catch (error) {
          console.warn(error)
        }
      } else {
        currentFilter = params.display.displayFilter
      }
    }

    // Traitement du filtre des boutons
    if (params.filterFromButtons && params.filterFromButtons.trim()) {
      if (currentFilter) {
        currentFilter += ' AND ' + params.filterFromButtons
      } else {
        currentFilter = params.filterFromButtons
      }
    }

    const today = new Date()

    return await this.getDataPromise(params.display.displayRequest, {
      searchString: params.searchString,
      dataFilter: currentFilter,
      minLines: params.minLine,
      maxLines: params.maxLine,
      fieldOrder: params.display.fieldOrder,
      fieldOrderDesc: params.display.fieldOrderDesc,
    })
  }

  /*
  TODO Je dois savoir maintenant le faire au moins via le Web en récup directement les données de la requete
  */
  calculateDisplayTotals(params) {
    const that = this
    const totals: any = {}
    if (params.display.totals) {
      params.display.totals.forEach((total) => {
        let sum = 0
        totals[total.field] = 0
        params.items.forEach((element) => {
          sum += element.data[total.field]
        })
        totals[total.field] = sum
        if (total.format === 'decimal') {
          try {
            totals[total.field] = new Intl.NumberFormat(that._g.language, {
              maximumFractionDigits: total.digits,
              minimumFractionDigits: total.digits,
            }).format(totals[total.field])
          } catch (ex) {}
        }
      })
    }
    return totals
  }

  launch(command, this_ref) {
    if (command[0] === '=') {
      command = command.slice(1)
    }

    // Utilisé par scripts en base

    const that = this_ref
    const context = {
      ...this._g.context,
      utilities: this,
      dataItem: this_ref.dataItem ? this_ref.dataItem : this_ref.data,
    }

    // On contextualise le script
    function evalInContext() {
      return eval(command)
    }

    return evalInContext.call(this_ref, context, that)
  }

  /***********  Définition d'une donnée persistante - session uniquement */
  setSessionLocally(key, value) {
    window.sessionStorage.setItem(key, value)
  }

  /***********  Définition d'une donnée persistante */
  setLocally(key, value) {
    window.localStorage.setItem(key, value)
  }

  /***********  Récupération d'une donnée persistante - session uniquement */
  getSessionLocally(key) {
    return window.sessionStorage.getItem(key)
  }

  /***********  Récupération d'une donnée persistante */
  getLocally(key) {
    return window.localStorage.getItem(key)
  }

  /*********** Définition des Headers HTTP à envoyer à chaque demande */
  getHTTPHeaders(): HttpHeaders {
    let headers = new HttpHeaders()
    headers = headers.append('RequestOrigin', this.platspec.getRequestOrigin())
    if (this._g.token) {
      headers = headers.append('Authorization', 'Bearer ' + this._g.token)
    }
    headers = headers.append('Prefix', this._g.restPrefix.replace('/', ''))
    headers = headers.append('Role', this._g.maxiRole)

    return headers
  }

  getHTTPHeadersObject() {
    const headers = {}
    headers['RequestOrigin'] = 'mobileComplete'
    headers['Authorization'] = 'Bearer ' + this._g.token
    headers['Prefix'] = this._g.restPrefix.replace('/', '')

    return headers
  }

  sortObject(a: any, b: any) {
    if (a.index < b.index) {
      return -1
    }

    if (a.index > b.index) {
      return 1
    }
    return 0
  }

  /**** Calcule les indexes à partir des afters */
  setIndexes(obj) {
    for (const i in obj) {
      const cobj = obj[i]
      // after='id' -> C'est pour les datatables
      if (
        cobj.after === undefined ||
        cobj.after === '' ||
        cobj.after === 'id'
      ) {
        this.unitSetIndex(obj, i, 0)
        break
      }
    }
  }

  unitSetIndex(obj, i, cindex) {
    for (const j in obj) {
      const cobj = obj[j]
      if (cobj.after == obj[i]._key) {
        cobj.index = cindex + 1
        this.unitSetIndex(obj, j, cindex + 1)
      }
    }
  }

  /**********************
   *  Crée une array à partir d'un objet
   *  Pour chaque valeur de l'objet, rajoute une clé index à 0 ou -1 (-1 si pas d'index déjà défini)
   *  ainsi qu'une clé "_key" contenant la clé dans l'objet
   */
  generateArray(obj) {
    if (obj) {
      const tempObj = cloneDeep(obj)
      return Object.keys(tempObj).map((key) => {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          obj[key]._key = key
        }
        return obj[key]
      })
    } else {
      return []
    }
  }

  setMenuChild(params) {
    params.menuItem.subMenu = []
    for (const subMenuItem of params.definition) {
      if (
        (subMenuItem.type === 'menu' ||
          subMenuItem.type === 'divider' ||
          subMenuItem.type === 'step') &&
        params.menuItem.name == subMenuItem.parent &&
        subMenuItem.visible !== false &&
        (this._g.appType !== 'app' || subMenuItem.notOnApp !== true)
      ) {
        this.setMenuChild({
          menuItem: subMenuItem,
          definition: params.definition,
        })
        params.menuItem.subMenu.push(subMenuItem)
      }
    }
    this.setIndexes(params.menuItem.subMenu)
    params.menuItem.subMenu.sort(this.sortObject)
  }

  createMenuAndButtons(params) {
    // Création de la structure hiérarchique des menus
    const shortcuts = []
    this._g.reminders = {}

    const tempDefinition = cloneDeep(params.definition)

    // Création des infos pour les figures et les boutons
    const menuArray = tempDefinition
    menuArray.forEach((menuItem: any) => {
      if (this._g.appType !== 'app' || menuItem.notOnApp !== true) {
        if (menuItem.shortcut === true) {
          shortcuts.push(menuItem)
        }

        if (menuItem.type === 'reminder') {
          this._g.reminders = cloneDeep(menuItem)
        }
      }
    })

    params.definition = tempDefinition
    this._g.shortcuts = shortcuts
  }

  public getCurrentDashboard(dashboardName: string) {
    if (!this._g.dashboardsLists) return

    const currentDashboardGroup =
      this._g.dashboardsLists[this._g.currentDashboardGroup]

    if (!currentDashboardGroup) return

    const currentDashboard =
      currentDashboardGroup.find((dashLink) => {
        return (
          this._g.dashboardsDef[dashLink.code] &&
          (dashLink.code === dashboardName ||
            dashLink?.display ||
            dashLink?.link?.display === dashboardName)
        )
      }) || Object.values(currentDashboardGroup)[0]

    return currentDashboard
  }

  public handleDisplayDashboard(dashboardName: string) {
    const currentDashboard = this.getCurrentDashboard(dashboardName)

    if (!currentDashboard) return

    const {
      bannerImage,
      bannerTitle,
      bannerSubtitle,
      bannerColor,
      backgroundImage,
      dashboardGroup,
      dashboardTitle,
      _show_communications,
      _show_moods,
      type,
      _key,
      ...dashboard
    } = this._g.dashboardsDef[currentDashboard.code]

    this._g.currentDashboardGroup = dashboardGroup || 'base'
    localStorage.setItem('currentDashboard', dashboardGroup || 'base')

    this._g.banner.title = bannerTitle?.replace(
      '{{first_name}}',
      this._g.me?.first_name || '',
    )
    this._g.banner.subtitle = bannerSubtitle?.replace(
      '{{first_name}}',
      this._g.me?.first_name || '',
    )
    this._g.banner.image = bannerImage ? bannerImage : null
    this._g.banner.color = bannerColor
  }

  initDashboard(dashboardName: string) {
    if (!this._g.dashboardsDef[dashboardName]) return

    // Pour le changement de locale
    this._g.currentDashboardName = dashboardName

    const {
      bannerImage,
      bannerTitle,
      bannerSubtitle,
      bannerColor,
      backgroundImage,
      dashboardGroup,
      dashboardTitle,
      _show_communications,
      _show_moods,
      type,
      _key,
      ...dashboard
    } = this._g.dashboardsDef[dashboardName]

    const currentDashboardGroup = dashboardGroup || 'base'
    this._g.currentDashboardGroup = currentDashboardGroup
    localStorage.setItem('currentDashboard', currentDashboardGroup)

    this._g.currentDashboardGroup$.next(currentDashboardGroup)

    if (this._g.dashboardsLists[this._g.currentDashboardGroup]) {
      this._g.dashboardsLists[this._g.currentDashboardGroup].forEach(
        (dashLink) => {
          dashLink.selected = dashLink.code === dashboardName
        },
      )
    }

    this._g.banner.title = bannerTitle?.replace(
      '{{first_name}}',
      this._g.me.first_name || '',
    )

    this._g.banner.subtitle = bannerSubtitle?.replace(
      '{{first_name}}',
      this._g.me.first_name || '',
    )
    this._g.banner.image = bannerImage ? bannerImage : null
    this._g.banner.color = bannerColor

    const preparedDashboard = this.getDashboard({
      definition: dashboard,
    })

    return {
      dashboard,
      _show_communications,
      _show_moods,
      preparedDashboard,
    }
  }

  getDashboard(params) {
    // Création des infos pour les figures et les boutons
    const itemsArray = this.generateArray(params.definition)

    return {
      reminders: itemsArray.filter((item) => item.type === 'reminder'),
      charts: itemsArray
        .filter((item) => item.type === 'chart')
        .sort((a, b) => a._index - b._index),
      figures: itemsArray
        .filter((item) => item.type === 'figure')
        .sort((a, b) => a._index - b._index),
    }
  }

  /*******
   * On rajoute les icones et les displays pour toutes les lignes d'après la config
   * des champs dates, numérique et select simple
   */
  simpleDataEnrich(params) {
    // Clonage
    const newData = cloneDeep(params.data)

    const dataName = params.dataName
    const that = this

    const displaysDef = this._g.displaysDef
    // On rajoute les icones et les displays pour toutes les lignes d'après la config des champs
    // dates, numérique et select simple
    if (displaysDef[dataName]) {
      // tslint:disable-next-line:forin
      for (const fieldName in displaysDef[dataName].dataDisplay?.fields) {
        const currentField =
          displaysDef[dataName].dataDisplay?.fields[fieldName]
        if (currentField.dataType === 'select') {
          // On recherche les infos correspondantes
          for (const currentLine of newData) {
            currentField.values.forEach(function (value) {
              const labelField = currentField.labelField
              if (value.value == currentLine[fieldName]) {
                currentLine[fieldName + '_display'] = value.label
                currentLine[fieldName + '_data'] = {
                  id: value.value,
                }
                currentLine[fieldName + '_data'][labelField] = value.label

                currentLine[fieldName + '_icon'] = value.icon
                currentLine[fieldName + '_color'] = value.color
              }
            })
          }
        } else if (
          currentField.dataType === 'date' ||
          currentField.dataType === 'datetime' ||
          currentField.dataType === 'time'
        ) {
          // On définit les display des données data
          newData.forEach(function (currentLine, index) {
            if (currentLine[fieldName]) {
              if (currentField.dataType === 'date') {
                currentLine[fieldName] = moment(
                  currentLine[fieldName],
                  'YYYY-MM-DD',
                )
                currentLine[fieldName + '_display'] = moment(
                  currentLine[fieldName],
                ).format('ll')
                currentLine[fieldName + '_value'] = new Date(
                  currentLine[fieldName],
                )
              } else if (currentField.dataType === 'datetime') {
                currentLine[fieldName] = moment(
                  currentLine[fieldName],
                  'YYYY-MM-DD HH:mm:ss',
                )
                currentLine[fieldName + '_display'] = moment(
                  currentLine[fieldName],
                ).format('lll')
                currentLine[fieldName + '_value'] = new Date(
                  currentLine[fieldName],
                )
              } else if (currentField.dataType === 'time') {
                currentLine[fieldName] = moment(
                  '1970-01-01 ' + currentLine[fieldName],
                  'YYYY-MM-DD HH:mm:ss',
                )
                currentLine[fieldName + '_display'] = moment(
                  currentLine[fieldName],
                ).format('LT')
                currentLine[fieldName + '_value'] = new Date(
                  currentLine[fieldName],
                )
              } else {
                currentLine[fieldName] = undefined
                currentLine[fieldName + '_display'] = ''
                currentLine[fieldName + '_value'] = undefined
              }
            } else {
              currentLine[fieldName + '_display'] = ''
            }
          })
        } else if (
          currentField.dataType === 'real' ||
          currentField.dataType === 'int'
        ) {
          // On définit les display des données numériques
          newData.forEach(function (currentLine, index) {
            if (currentLine[fieldName]) {
              let theValue = currentLine[fieldName]
              if (theValue && !isNaN(theValue)) {
                if (currentField.dataType === 'real') {
                  theValue = parseFloat(theValue)
                  if (
                    currentField.format === 'decimal' ||
                    currentField.format === 'decimal_percent' ||
                    currentField.format === 'decimal_percent_x100'
                  ) {
                    let digits = 2
                    if (currentField.digits) {
                      digits = currentField.digits
                    }
                    try {
                      let displayValue = theValue
                      if (
                        currentField.format === 'decimal_percent_x100' ||
                        currentField.format === 'int_percent_x100'
                      ) {
                        displayValue = displayValue * 100
                      }
                      currentLine[fieldName + '_display'] =
                        new Intl.NumberFormat(that._g.language, {
                          maximumFractionDigits: digits,
                          minimumFractionDigits: digits,
                        }).format(displayValue)
                      if (
                        currentField.format === 'decimal_percent' ||
                        currentField.format === 'decimal_percent_x100' ||
                        currentField.format === 'int_percent' ||
                        currentField.format === 'int_percent_x100'
                      ) {
                        currentLine[fieldName + '_display'] += ' %'
                      }
                    } catch (ex) {}
                  }
                } else {
                  // Cas int
                  theValue = parseInt(theValue)
                  try {
                    let displayValue = theValue
                    if (currentField.format === 'int_percent_x100') {
                      displayValue = displayValue * 100
                    }
                    currentLine[fieldName + '_display'] = new Intl.NumberFormat(
                      that._g.language,
                      {
                        maximumFractionDigits: 0,
                      },
                    ).format(displayValue)
                    if (
                      currentField.format === 'int_percent' ||
                      currentField.format === 'int_percent_x100'
                    ) {
                      currentLine[fieldName + '_display'] += ' %'
                    }
                  } catch (ex) {}
                  currentLine[fieldName] = theValue
                }

                // Dans le cas d'une erreur ou si le format n'est pas défini
                if (!currentLine[fieldName + '_display']) {
                  currentLine[fieldName + '_display'] = theValue
                }
                currentLine[fieldName] = theValue
              } else {
                currentLine[fieldName + '_display'] = ''
              }
            }
          })
        }
      }
    }
    return newData
  }

  async getData(params) {
    const that = this

    // Appel on Start (pour mettre un loading par exemple)
    if (params && params.onStart) {
      await params.onStart()
    }
    const dataName = params.dataName
    const minLines = params.minLines ? params.minLines : 0
    const maxLines = params.maxLines ? params.maxLines : 99
    const fieldOrder = params.fieldOrder ? params.fieldOrder : ''
    const fieldOrderDirection = params.fieldOrderDesc ? 'desc' : 'asc'

    // let forceLocal = params.forceLocal ? true : false;
    const forceLocal = false

    const displaysDef = this._g.displaysDef
    const restRoot = this._g.restRoot
    const dbObject = this._g.db

    const dummy = false

    // ATTENTION AU SYNCHRONISE SUR LES QUERY OU ALORS FORCER DISTANT SUR LES QUERY -> PAS BEAU!!
    // NE PAS FAIRE dbDef!=undefined. Je ne dois pas passer la tant que j'ai pas le windo.dbdef
    if (
      displaysDef?.[dataName]?.dataDisplay?.type === 'query' ||
      displaysDef?.[dataName]?.dataDisplay?.type === 'scalar'
    ) {
      if (this.platspec.isOnline() && !forceLocal) {
        // Gestion de la progress bar
        that._g.max_progress_bar_value += 100
        const url = this._g.restRoot + 'app/data/' + dataName + '/get_query'
        this.http.get<any>(url, { headers: this.getHTTPHeaders() }).subscribe(
          (data) => {
            // Gestion de la progress bar
            that._g.current_progress_bar_value += 100
            setTimeout(function () {
              that._g.current_progress_bar_value -= 100
              that._g.max_progress_bar_value -= 100
            }, 2000)

            if (data.status === 'ok') {
              params.callbackSuccess({ ...data, hasMoreData: false })
              if (this._g.appType === 'app') {
                // Pour corriger un pb d'écriture concurrentielle. Si je lis
                // 2 fois getData très très rapprochés sur la même requete,
                // il essaie d'écrire en même temps
                if (that.fileWriting.indexOf(dataName) === -1) {
                  that.fileWriting.push(dataName)

                  // On écrit le résultat pour le réutiliser plus tard
                  this.platspec.writeFile({
                    directory: this.platspec.getDeviceDataDirectory(),
                    filename: dataName + '.json',
                    data: JSON.stringify(data.data),
                    replace: true,
                    onSuccess(success) {
                      that.fileWriting.splice(
                        that.fileWriting.indexOf(dataName),
                        1,
                      )
                    },
                    onError: (error) => {
                      console.warn(error?.originalError || error)
                      that.fileWriting.splice(
                        that.fileWriting.indexOf(dataName),
                        1,
                      )
                    },
                  })
                }

                // }
              }

              // Je rajoute ça pour faire une vraie promesse
              return new Promise((resolve) => {})
            }
            // Appel on Stop (pour arreter le loading par exemple)
            if (params && params.onStop) {
              params.onStop()
            }

            return data
          },
          // Error
          (error) => {
            // Appel on Stop (pour arreter le loading par exemple)
            if (params && params.onStop) {
              params.onStop()
            }

            // Gestion de la progress bar
            that._g.current_progress_bar_value += 100
            setTimeout(function () {
              that._g.current_progress_bar_value -= 100
              that._g.max_progress_bar_value -= 100
            }, 2000)

            this.platspec.platformAlert({
              message: this.translate.instant('ERROR.LOAD_DATA'),
              logData: error,
              user: 'toast',
              log: 'error',
              function: 'getData-utilities.ts-scalar',
            })
            console.warn(error?.originalError || error)
          },
        )
      } else {
        // on lit le fichier sauvegardé précédemment
        if (this._g.appType === 'app') {
          this.platspec.readAsText({
            directory: this.platspec.getDeviceDataDirectory(),
            filename: dataName + '.json',
            onSuccess(result) {
              params.callbackSuccess({
                isOld: true,
                hasMoreData: false,
                data: JSON.parse(result),
              })
              // Appel on Stop (pour arreter le loading par exemple)
              if (params && params.onStop) {
                params.onStop()
              }

              // Je rajoute ça pour faire une vraie promesse
              return new Promise((resolve) => {})
            },
            onError: (error) => {
              console.warn(error?.originalError || error)
              // Appel on Stop (pour arreter le loading par exemple)
              if (params && params.onStop) {
                params.onStop()
              }
            },
          })
        }
      }
      return
    }

    // Récupération directe des données sur le web
    if (
      this._g.appType === 'web' ||
      !displaysDef[params.dataName].dataDisplay ||
      displaysDef[params.dataName].dataDisplay.mobile_mode !== 'local'
    ) {
      if (
        (this._g.appType === 'web' || this.platspec.isOnline()) &&
        !forceLocal
      ) {
        // calcul du filtre
        let filter = ''
        if (filter && params.dataId) {
          filter += params.dataName + '.id=' + params.dataId
        }

        if (params.dataFilter) {
          if (filter) {
            filter += ' AND '
          }
          filter += params.dataFilter
        }

        let url: string
        if (params.searchString) {
          let filterToSend = ''
          if (filter) {
            filterToSend = encodeURIComponent(filter)
          } else {
            // Bidouille parce que la route de ry n'accepte pas un parametre vide
            filterToSend = encodeURIComponent('1=1')
          }
          url =
            restRoot +
            'app/data/' +
            dataName +
            '/search/' +
            encodeURIComponent(params.searchString) +
            '/' +
            filterToSend +
            '/' +
            minLines +
            '/' +
            maxLines +
            (!fieldOrder ? '' : '/' + fieldOrder + '/' + fieldOrderDirection)
        } else if (filter) {
          url =
            restRoot +
            'app/data/' +
            dataName +
            '/get/filtered/' +
            encodeURIComponent(filter) +
            '/' +
            minLines +
            '/' +
            maxLines +
            (!fieldOrder ? '' : '/' + fieldOrder + '/' + fieldOrderDirection)
        } else {
          url =
            restRoot +
            'app/data/' +
            dataName +
            '/get/' +
            (!params.dataId ? '0' : params.dataId) +
            '/' +
            minLines +
            '/' +
            maxLines +
            (!fieldOrder ? '' : '/' + fieldOrder + '/' + fieldOrderDirection)
        }

        if (this._g.currentDisplay) {
          url += '?displays=' + this._g.currentDisplay
        }

        /*****************
         *
         *  On récupère les données et on les
         *  enrichit, en rajoutant quand nécessaire les display
         *  et pour les select simples les icones
         *
         */
        // Gestion de la progress bar
        that._g.max_progress_bar_value += 100
        this.http.get<any>(url, { headers: this.getHTTPHeaders() }).subscribe(
          (data) => {
            // Appel on Stop (pour arreter le loading par exemple)
            if (params && params?.onHttpDone) {
              params.onHttpDone()
            }

            // Gestion de la progress bar
            that._g.current_progress_bar_value += 100
            setTimeout(() => {
              that._g.current_progress_bar_value -= 100
              that._g.max_progress_bar_value -= 100
            }, 2000)

            // On récupère les données et ça s'est bien passé
            if (data.status === 'ok') {
              const newData = that.simpleDataEnrich({
                data: data.data,
                dataName,
              })
              // Appel on Stop (pour arreter le loading par exemple)
              if (params && params?.onStop) {
                params.onStop()
              }
              params.callbackSuccess({
                ...data,
                data: newData,
              })

              // Je rajoute ça pour faire une vraie promesse
              return new Promise((resolve) => {})
            } else {
              // Appel on Stop (pour arreter le loading par exemple)
              if (params && params.onStop) {
                params.onStop()
              }
              if (params.callbackError) {
                params.callbackError(data)
              }
            }

            return data
          },
          // Error
          function (response) {
            // Gestion de la progress bar
            that._g.current_progress_bar_value += 100
            setTimeout(function () {
              that._g.current_progress_bar_value -= 100
              that._g.max_progress_bar_value -= 100
            }, 2000)

            if (params.callbackError) {
              params.callbackError(response)
            }
          },
        )
      } else {
        // Appel on Stop (pour arreter le loading par exemple)
        if (params && params.onStop) {
          params.onStop()
        }

        // Gestion de la progress bar
        that._g.current_progress_bar_value += 100
        setTimeout(function () {
          that._g.current_progress_bar_value -= 100
          that._g.max_progress_bar_value -= 100
        }, 2000)

        if (!forceLocal) {
          this.platspec.platformAlert({
            message: this.translate.instant('INFO.NO_NETWORK'),
            logData: '',
            user: 'toast',
            log: 'info',
            function: 'getData-utilities.ts',
          })
        }
      }

      // Récupération des données en local via la base
    } else {
      let sqlStringBegin: string = 'select ' + params.dataName + '.* '
      let sqlStringTables: string = ' from  ' + params.dataName
      const tableObject = that._g.displaysDef[params.dataName].dataDisplay

      for (const currentField of tableObject.fields) {
        if (
          currentField.dataType === 'select_request' &&
          that._g.displaysDef[currentField.request].dataDisplay.mobile_mode ===
            'local' &&
          currentField.multiple !== true
        ) {
          const currentSubTableObject =
            that._g.displaysDef[currentField.request].dataDisplay
          // Traitement des champs de base
          for (const currentSubField of currentSubTableObject.fields) {
            sqlStringBegin +=
              ', ' +
              currentField.source +
              '_request.' +
              currentSubField.source +
              ' as ' +
              currentField.source +
              '__' +
              currentSubField.source
          }
          // Traitement des champs génériques
          if (currentSubTableObject.display) {
            sqlStringBegin +=
              ', ' +
              currentField.source +
              '_request.' +
              currentSubTableObject.display +
              ' as ' +
              currentField.source +
              '_display'
          }
          if (currentSubTableObject.icon) {
            sqlStringBegin +=
              ', ' +
              currentField.source +
              '_request.' +
              currentSubTableObject.icon +
              ' as ' +
              currentField.source +
              '_icon'
          }
          if (currentSubTableObject.color) {
            sqlStringBegin +=
              ', ' +
              currentField.source +
              '_request.' +
              currentSubTableObject.color +
              ' as ' +
              currentField.source +
              '_color'
          }
          if (currentSubTableObject.photo) {
            sqlStringBegin +=
              ', ' +
              currentField.source +
              '_request.' +
              currentSubTableObject.photo +
              ' as ' +
              currentField.source +
              '_photo'
          }
          sqlStringTables +=
            ' left outer join ' +
            currentField.request +
            ' ' +
            currentField.source +
            '_request ON ' +
            params.dataName +
            '.' +
            currentField.source +
            '=' +
            currentField.source +
            '_request.id'
        }
      }

      let sqlString: string = sqlStringBegin + sqlStringTables

      let sqlFilter = ''
      if (params.dataId) {
        sqlFilter = params.dataName + ".id='" + params.dataId + "'"
      }
      if (params.dataFilter) {
        if (sqlFilter) {
          sqlFilter += ' AND '
        }
        sqlFilter += params.dataFilter
      }
      // Définition du filtre d'après la chaine de recherche
      if (params.searchString) {
        if (sqlFilter) {
          sqlFilter += ' AND ('
        } else {
          sqlFilter = '('
        }

        let subFilter = ''

        for (const fieldName in displaysDef[params.dataName].dataDisplay
          .fields) {
          const currentField =
            displaysDef[params.dataName].dataDisplay.fields[fieldName]
          if (currentField.searchable) {
            if (subFilter) {
              subFilter += ' OR '
            }
            subFilter +=
              ' ' +
              params.dataName +
              '.' +
              fieldName +
              " like '%" +
              params.searchString +
              "%'"
            // On rajoute le champs display
            if (currentField.dataType === 'select_request') {
              subFilter +=
                ' OR ' +
                fieldName +
                "_display like '%" +
                params.searchString +
                "%'"
            }
          }
        }
        sqlFilter += subFilter + ')'
      }
      if (sqlFilter) {
        sqlString +=
          ' WHERE ' + params.dataName + '._deleted=0 and ' + sqlFilter
      } else {
        sqlString += ' WHERE ' + params.dataName + '._deleted=0'
      }

      if (fieldOrder) {
        sqlString += ' order by ' + fieldOrder + ' ' + fieldOrderDirection
      }

      // Gestion de la progress bar
      that._g.max_progress_bar_value += 100

      dbObject.executeSql(
        sqlString,
        [],
        // Success
        (result) => {
          // Gestion de la progress bar
          this._g.current_progress_bar_value += 100
          setTimeout(() => {
            this._g.current_progress_bar_value -= 100
            this._g.max_progress_bar_value -= 100
          }, 2000)

          const dataToPass: any = {
            data: [],
          }

          if (result.rows?.length > 0) {
            for (let i = 0; i < result.rows?.length; i++) {
              if (maxLines && i > maxLines - 1) {
                dataToPass.hasMoreData = true
                break
              }
              if (!minLines || i >= minLines) {
                dataToPass.data.push(result.rows.item(i))
              }
            }
            const newData = that.simpleDataEnrich({
              data: dataToPass.data,
              dataName,
            })

            // Appel on Stop (pour arreter le loading par exemple)
            if (params && params.onStop) {
              params.onStop()
            }
            params.callbackSuccess({
              hasMoreData: dataToPass.hasMoreData
                ? dataToPass.hasMoreData
                : false,
              data: newData,
            })

            // Je rajoute ça pour faire une vraie promesse
            return new Promise((resolve) => {})
          } else {
            // Gestion de la progress bar
            that._g.current_progress_bar_value += 100
            setTimeout(function () {
              that._g.current_progress_bar_value -= 100
              that._g.max_progress_bar_value -= 100
            }, 2000)

            // Appel on Stop (pour arreter le loading par exemple)
            if (params && params.onStop) {
              params.onStop()
            }
            //callbackSuccess(dataToPass);
            // Pas de données disponibles
            params.callbackSuccess({
              hasMoreData: false,
              data: [],
            })

            // Je rajoute ça pour faire une vraie promesse
            return new Promise((resolve) => {})
          }
        },
        // Error
        (error) => {
          // Gestion de la progress bar
          this._g.current_progress_bar_value += 100
          setTimeout(() => {
            this._g.current_progress_bar_value -= 100
            this._g.max_progress_bar_value -= 100
          }, 2000)

          console.warn(error?.originalError || error)

          this.platspec.platformAlert({
            message: this.translate.instant('ERROR.LOAD_DATA'),
            logData: sqlString,
            user: 'toast',
            log: 'error',
            function: 'getData-utilities.ts',
          })

          // Appel on Stop (pour arreter le loading par exemple)
          if (params && params.onStop) {
            params.onStop()
          }
        },
      )
    }
  }

  // Fonction de savegarde sur le serveur d'une définition ou d'une partie de définition

  /**
   *
   * Détermine l'icone à afficher en fonction du  type de la pièce jointe
   *
   * */
  getIconForFile(filename) {
    let icon = 'fa fa-file'
    if (
      filename.toLowerCase().endsWith('.doc') ||
      filename.toLowerCase().endsWith('.docx')
    ) {
      icon = 'fa fa-file-word-o'
    } else if (
      filename.toLowerCase().endsWith('.xls') ||
      filename.toLowerCase().endsWith('.xlsx')
    ) {
      icon = 'fa fa-file-excel-o'
    } else if (filename.toLowerCase().endsWith('.pdf')) {
      icon = 'fa fa-file-pdf-o'
    } else if (
      filename.toLowerCase().endsWith('.png') ||
      filename.toLowerCase().endsWith('.jpg') ||
      filename.toLowerCase().endsWith('.jpeg')
    ) {
      icon = 'fa fa-file-image-o'
    }
    return icon
  }

  // d'un display
  saveSettings(params) {
    if (params.newDisplayDef) {
      const url = this._g.restRoot + 'app/settings/display_data/save'
      this.http
        .post<any>(url, params.newDisplayDef, {
          headers: this.getHTTPHeaders(),
        })
        .subscribe((data) => {
          if (data.status === 'ok') {
            if (params.onSaved) {
              params.onSaved()
            }
          }
        })
    }
  }

  // d'un menu
  saveMenuSettings(params) {
    if (params.newMenuDef) {
      const url = this._g.restRoot + 'app/settings/menu_data/save'
      this.http
        .post<any>(url, params.newMenuDef, { headers: this.getHTTPHeaders() })
        .subscribe((data) => {
          if (data.status === 'ok') {
            if (params.onSaved) {
              params.onSaved()
            }
          }
        })
    }
  }

  deleteUserDisplay(params) {
    const url = this._g.restRoot + 'app/settings/display_data/delete'
    this.http
      .post<any>(
        url,
        {
          display: params.display._key,
          variation: params.variation._key,
        },
        { headers: this.getHTTPHeaders() },
      )
      .subscribe((data) => {
        if (data.status === 'ok') {
          //alert('Pas good');
          if (params.onSaved) {
            params.onSaved()
          }
        } else {
          alert('Pas good')
        }
      })
  }

  /*****************
   *
   *
   * formatDataForDisplay : préformatte et prépare les données pour l'affichage
   * Cette fonction rajoute la notion de specialData: des données de type universelle
   * pour l'affichage
   * Exemple specialData.type_icon : valeur de l'icone de type
   * Type : On formatte pour une table ou un form
   */
  formatDataForDisplay<T extends DataModel>(
    dataArray: T[],
    currentDisplay: DisplayModel,
    type: 'table' | 'form' | 'view',
  ): DataFormated<T>[] {
    const newDataArray: DataFormated<T>[] = []

    // Réglage de la valeur de fieldOrderDesc
    if (currentDisplay.fieldOrderDesc !== true) {
      currentDisplay.fieldOrderDesc = false
    } else {
      currentDisplay.fieldOrderDesc = true
    }

    /*************
     * Préparation des données spéciales
     */
    dataArray.forEach((datum, index) => {
      const specialData = new SpecialData({
        id: datum.id,
        _is_new: datum._is_new,
      })

      const newData: DataFormated<T> = {
        specialData,
        data: datum,
        readMoreClass: 'kizeo-collapsed',
      }

      // TITRE
      if (currentDisplay.fieldTitle) {
        // Si ca commence par = il faut évaluer
        if (currentDisplay.fieldTitle.substring(0, 1) === '=') {
          try {
            specialData.title = this.launch(currentDisplay.fieldTitle, {
              data: datum,
            })
          } catch (error) {
            console.warn(error?.originalError || error)
          }
        } else if (currentDisplay.fieldTitle === 'isManager') {
          specialData.title = datum[currentDisplay.fieldTitle]
            ? 'IS_MANAGER'
            : 'IS_EMPLOYEE'
        } else if (datum[currentDisplay.fieldTitle]) {
          // Sinon on prend la données brute
          specialData.title = datum[currentDisplay.fieldTitle]
        } else {
          specialData.title = ''
        }
      }

      // SOUS TITRE GAUCHE
      if (currentDisplay.fieldSubtitleLeft) {
        // Si ca commence par = il faut évaluer
        if (currentDisplay.fieldSubtitleLeft.substring(0, 1) === '=') {
          try {
            specialData.subtitleLeft = this.launch(
              currentDisplay.fieldSubtitleLeft,
              { data: datum },
            )
          } catch (error) {
            console.warn(error?.originalError || error)
          }
        } else if (currentDisplay.fieldSubtitleLeft === 'isManager') {
          specialData.subtitleLeft = datum[currentDisplay.fieldSubtitleLeft]
            ? 'IS_MANAGER'
            : 'IS_EMPLOYEE'
        } else if (datum[currentDisplay.fieldSubtitleLeft]) {
          // Sinon on prend la données brute
          specialData.subtitleLeft = datum[currentDisplay.fieldSubtitleLeft]
        } else {
          specialData.subtitleLeft = ''
        }
      }

      // SOUS TITRE DROITE
      if (currentDisplay.fieldSubtitleRight) {
        // Si ca commence par = il faut évaluer
        if (currentDisplay.fieldSubtitleRight.substring(0, 1) === '=') {
          try {
            specialData.subtitleRight = this.launch(
              currentDisplay.fieldSubtitleRight,
              { data: datum },
            )
          } catch (error) {
            console.warn(error?.originalError || error)
          }
        } else if (currentDisplay.fieldSubtitleRight === 'isManager') {
          specialData.subtitleRight = datum[currentDisplay.fieldSubtitleRight]
            ? 'IS_MANAGER'
            : 'IS_EMPLOYEE'
        } else if (datum[currentDisplay.fieldSubtitleRight]) {
          // Sinon on prend la données brute
          specialData.subtitleRight = datum[currentDisplay.fieldSubtitleRight]
        } else {
          specialData.subtitleRight = ''
        }
      }

      // Date DEBUT
      if (currentDisplay.fieldStartDate) {
        // Si ca commence par = il faut évaluer
        if (currentDisplay.fieldStartDate.substring(0, 1) === '=') {
          try {
            specialData.startDate = moment(
              this.launch(currentDisplay.fieldStartDate, { data: datum }),
            )
          } catch (error) {
            console.warn(error?.originalError || error)
          }
        } else if (datum[currentDisplay.fieldStartDate]) {
          // Sinon on prend la données brute
          specialData.startDate = moment(datum[currentDisplay.fieldStartDate])
        } else {
          specialData.startDate = ''
        }
      }

      // Date FIN
      if (currentDisplay.fieldEndDate) {
        // Si ca commence par = il faut évaluer
        if (currentDisplay.fieldEndDate.substring(0, 1) === '=') {
          try {
            specialData.endDate = moment(
              this.launch(currentDisplay.fieldEndDate, { data: datum }),
            )
          } catch (error) {
            console.warn(error?.originalError || error)
          }
        } else if (datum[currentDisplay.fieldStartDate]) {
          // Sinon on prend la données brute
          specialData.endDate = moment(datum[currentDisplay.fieldEndDate])
        } else {
          specialData.endDate = ''
        }
      }

      // ORDER
      if (currentDisplay.fieldOrder) {
        if (
          datum[currentDisplay.fieldOrder] === 'null' ||
          !datum[currentDisplay.fieldOrder]
        ) {
          if (currentDisplay.fieldOrderType === 'num') {
            specialData.order = -100000
          } else {
            specialData.order = ''
          }
        } else {
          specialData.order = datum[currentDisplay.fieldOrder]
        }
        if (currentDisplay.fieldOrderType === 'num') {
          specialData.order = parseFloat(specialData.order)
        }
      } else {
        specialData.order = specialData.title
      }

      // GROUP
      if (currentDisplay.fieldGroup) {
        specialData.group = datum[currentDisplay.fieldGroup]
      }

      // DETAILS
      if (currentDisplay.fieldDetails) {
        // Si ca commence par = il faut évaluer
        if (currentDisplay.fieldDetails.substring(0, 1) === '=') {
          try {
            specialData.details = this.launch(currentDisplay.fieldDetails, {
              data: datum,
            })
          } catch (error) {
            console.warn(error?.originalError || error)
          }
        } else if (currentDisplay.fieldDetails === 'isManager') {
          specialData.details = datum[currentDisplay.fieldDetails]
            ? 'IS_MANAGER'
            : 'IS_EMPLOYEE'
        } else if (datum[currentDisplay.fieldDetails]) {
          // Sinon on prend la données brute
          specialData.details = datum[currentDisplay.fieldDetails]
        } else {
          specialData.details = ''
        }

        if (specialData.details !== '') {
          const regex = /<div><br><\/div>/g
          specialData.details = specialData.details.replace(regex, '<br>')
        }
      }

      // DETAILS HTML
      const fieldDetailsHtml = currentDisplay.fieldDetailsHtml
      specialData.detailsHtml = []

      if (fieldDetailsHtml && fieldDetailsHtml?.length > 0) {
        fieldDetailsHtml.forEach(async (field) => {
          const title = field.title
          const detail = field.detail
          const icon =
            field?.show === 'icon'
              ? {
                  icon: datum[`${field.display}_icon`],
                  color: datum[`${field.display}_color`],
                }
              : null
          const detailsHtml = {
            title,
            detail: '',
            icon,
          }

          // Si ca commence par = il faut évaluer
          if (detail.substring(0, 1) === '=') {
            try {
              detailsHtml.detail = this.launch(fieldDetailsHtml, {
                data: datum,
              })
            } catch (error) {
              console.warn(error?.originalError || error)
            }
          } else if (detail === 'isManager') {
            detailsHtml.detail = datum[detail] ? 'IS_MANAGER' : 'IS_EMPLOYEE'
          } else if (datum[detail]) {
            // Sinon on prend la données brute
            detailsHtml.detail = datum[detail]
          } else {
            detailsHtml.detail = '<i class="fas fa-times text-lavander"></i>'
          }

          // On remplace les token et on arrange le HTML
          if (detailsHtml.detail !== '') {
            let regex = /__TOKEN__/gi
            detailsHtml.detail = detailsHtml.detail.replace(
              regex,
              this._g.token,
            )

            regex = /<div><br><\/div>/g
            detailsHtml.detail = detailsHtml.detail.replace(regex, '<br>')
          }

          specialData.detailsHtml.push(detailsHtml)
        })
      }

      // DETAILS ARRAY
      const fieldDetailsArray = currentDisplay.fieldDetailsArray
      specialData.detailsArray = []
      if (fieldDetailsArray && fieldDetailsArray?.length > 0) {
        fieldDetailsArray.forEach(async (field) => {
          const title = field.title
          const detail = field.detail
          const detailsArray = {
            title,
            detail: [],
          }

          if (datum[detail]) {
            const fields = field.subtable.fields
            const rows = JSON.parse(datum[detail])
            specialData.fieldNamesDetailsArray = Object.keys(
              field.subtable.fields,
            )

            rows.forEach((row) => {
              const rowDetail = {}
              specialData.fieldNamesDetailsArray.map((name) => {
                switch (fields[name]?.show) {
                  case 'icon':
                  case 'icon+text':
                  case 'text+icon':
                    rowDetail[name] = JSON.parse(row[name])
                    break
                  case 'text':
                  default:
                    rowDetail[name] = row[name]
                    break
                }
              })
              detailsArray.detail.push(rowDetail)
            })
          }
          specialData.detailsArray.push(detailsArray)
        })
      }

      // Image
      if (
        currentDisplay.fieldImage &&
        datum &&
        (datum[currentDisplay.fieldImage] ||
          datum['_' + currentDisplay.fieldImage])
      ) {
        specialData.fieldImage = datum[currentDisplay.fieldImage]?.list?.length
          ? datum[currentDisplay.fieldImage].list[0]
          : null

        if (!specialData.fieldImage) {
          specialData.fieldImage = datum['_' + currentDisplay.fieldImage]?.list
            ?.length
            ? datum['_' + currentDisplay.fieldImage].list[0]
            : null
        }
      }

      // ImageColor
      if (
        currentDisplay.fieldImageColor &&
        datum &&
        (datum[currentDisplay.fieldImageColor] ||
          datum['_' + currentDisplay.fieldImageColor])
      ) {
        specialData.fieldImageColor =
          datum[currentDisplay.fieldImageColor + '_color'] ||
          datum['_' + currentDisplay.fieldImageColor + '_color']
      }

      // Si on définit un field Type il peut y avoir une photo, un icone ... une couleur associée
      if (currentDisplay.fieldType) {
        specialData.type = datum[currentDisplay.fieldType]
        specialData.type_display = datum[currentDisplay.fieldType + '_display']
        specialData.type_icon = datum[currentDisplay.fieldType + '_icon']
        specialData.type_color = datum[currentDisplay.fieldType + '_color']
        specialData.type_photo = datum[currentDisplay.fieldType + '_photo']
      }
      if (currentDisplay.fieldType2) {
        specialData.type2 = datum[currentDisplay.fieldType2]
        specialData.type2_display =
          datum[currentDisplay.fieldType2 + '_display']
        specialData.type2_icon = datum[currentDisplay.fieldType2 + '_icon']
        specialData.type2_color = datum[currentDisplay.fieldType2 + '_color']
        specialData.type2_photo = datum[currentDisplay.fieldType2 + '_photo']
      }

      // Si on définit un field Tag il peut y avoir  ... une couleur associée
      if (currentDisplay.fieldTag) {
        specialData.tag = datum[currentDisplay.fieldTag]
        if (
          currentDisplay.table.fields[currentDisplay.fieldTag]?.displayType ===
          'select'
        ) {
          if (datum[currentDisplay.fieldTag] !== 0) {
            const value = currentDisplay.table.fields[
              currentDisplay.fieldTag
            ].values.find((v) => v.value === datum[currentDisplay.fieldTag])
            specialData.tag_display = value.label
            specialData.tag_color = value.color
          }
        } else {
          specialData.tag_display = datum[currentDisplay.fieldTag + '_display']
          specialData.tag_color = datum[currentDisplay.fieldTag + '_color']
        }
      }

      // On peut définir un champ "big photo" qui prend tout le haut d'une card et dont on ne prend que la première photo)
      if (currentDisplay.fieldRRule && datum[currentDisplay.fieldRRule]) {
        specialData.rrule = JSON.parse(datum[currentDisplay.fieldRRule])
      }

      if (currentDisplay.fieldNbYes) {
        specialData.nbYes = datum[currentDisplay.fieldNbYes]
      }
      if (currentDisplay.fieldNbNo) {
        specialData.nbNo = datum[currentDisplay.fieldNbNo]
      }
      if (currentDisplay.fieldNbUnknown) {
        specialData.nbUnknown = datum[currentDisplay.fieldNbUnknown]
      }

      // Files: gestion des booléens
      if (datum['_files']?.list) {
        datum['_files']?.list.forEach((file) => {
          file.is_force_preview =
            file.is_force_preview === '1' || file.is_force_preview === 1 ? 1 : 0
          return file
        })
      }

      newData['_files'] = plainToClass(FileWrapperModel, datum['_files'])

      // Sette les nouvelles données dans le tableau
      newDataArray[index] = newData
    })

    /********************
     * Enrichissement des données de base
     */
    const fields = currentDisplay[type]?.fields
    if (fields) {
      for (const index in fields) {
        const field: Field = fields[index]
        if (field.type === 'iframe' && field.baseUrl) {
          if (field.baseUrl.substring(0, 1) === '=') {
            try {
              const iFrameEvalResult = this.launch(
                currentDisplay.fieldDetails,
                this,
              )
              field.url =
                this.sanitizer.bypassSecurityTrustResourceUrl(iFrameEvalResult)
            } catch (error) {
              console.error(
                this.translate.instant('ERROR.ERROR') + ':' + field.baseUrl,
              )
              console.warn(error?.originalError || error)
            }
          } else {
            field.url = this.sanitizer.bypassSecurityTrustResourceUrl(
              field.baseUrl,
            )
          }
        } else if (
          field.type === 'question-definition' ||
          field.subtype === 'question-definition'
        ) {
          newDataArray.forEach((line: DataFormated<DataModel>, index) => {
            if (line.data[field.source]) {
              try {
                line.data[field.source] = JSON.parse(line.data[field.source])
              } catch (error) {
                console.error(this.translate.instant('RRULES.PARSE_ERROR'))
                console.error(error)
              }
            } else {
              line.data[field.source] = { definition: {} }
            }
          })
        } else if (
          field.subtype === 'rrule' ||
          field.subtype === 'heroes-rrule' ||
          field.subtype === 'survey-rrule'
        ) {
          newDataArray.forEach((line: DataFormated<DataModel>, index) => {
            if (line.data[field.source]) {
              try {
                line.data[field.source] = JSON.parse(line.data[field.source])
              } catch (error) {
                console.error(this.translate.instant('RRULES.PARSE_ERROR'))
                console.error(error)
              }
            }
          })
        } else if (field.type === 'html') {
          newDataArray.forEach((line: DataFormated<DataModel>, index) => {
            if (line.data[field.source]) {
              // On remplce éventuellement le mot clé __TOKEN__ par le vrai token
              let regex = /__TOKEN__/gi
              line.data[field.source] = line.data[field.source].replace(
                regex,
                this._g.token,
              )

              regex = /<div><br><\/div>/g
              line.data[field.source] = line.data[field.source].replace(
                regex,
                '<br>',
              )
            }
          })
        } else if (field.type === 'boolean') {
          newDataArray.forEach(function (line: DataFormated<DataModel>, index) {
            if (!line.data[field.source]) {
              line.data[field.source] = false
            } else if (
              line.data[field.source] === 0 ||
              line.data[field.source] === '0'
            ) {
              line.data[field.source] = false
            } else {
              line.data[field.source] = true
            }
          })
        } else if (field.type === 'photo') {
          newDataArray.forEach((line: DataFormated<DataModel>) => {
            line.data[field.source] = plainToClass(
              PhotoWrapperModel,
              line.data[field.source],
            )
          })
        } else if (field.type === 'items') {
          newDataArray.forEach((line: DataFormated<DataModel>, index) => {
            if (line.data[field.source]) {
              try {
                line.data[field.source] = JSON.parse(line.data[field.source])
              } catch (error) {
                console.error(this.translate.instant('RRULES.PARSE_ERROR'))
                console.error(error)
              }
            } else {
              line.data[field.source] = { items: [] }
            }
          })
        }

        // On met à jour les champs calculés
        if (field.calculation) {
          newDataArray.forEach((line) => {
            const datas: any = line.data
            try {
              datas[field.source] = this.launch(field.calculation, {
                data: line.data,
              })
            } catch (error) {
              console.warn(error?.originalError || error)
            }
          })
        }
      }
    }

    return newDataArray
  }

  /*************************
   *
   * Prépare les données pour une nouvel enregistrement
   *
   */
  async prepareNew(fields, params): Promise<any> {
    // Préparation des variables accessibles pour une utilisation plus simple
    const that = this
    const utilities = this
    const data = {}

    // Utilisé par Eval
    const context = this._g.context

    let additionalParams: any = {}
    if (params.additionalParams) {
      additionalParams = cloneDeep(params.additionalParams)
    }
    this._g.context.global.previousId = params.previousId

    const promises = fields.map(async (field) => {
      // Valeurs en session
      if (field.saveValueInSession) {
        const fieldCustomKey =
          this._g.currentDisplay + '_' + field['_key'] + '_value_in_session'

        const valueInSession = this.getLocally(fieldCustomKey)

        if (
          valueInSession &&
          valueInSession !== 'null' &&
          valueInSession !== 'undefined'
        ) {
          field.default = valueInSession
        }
      }

      // On traite les valeurs par défaut
      if (field.default) {
        if (field.default === '#now#') {
          data[field.source] = moment()
        } else if (
          (typeof field.default === 'string' &&
            field.default.substring(0, 1) === '=') ||
          field.evalDefault
        ) {
          try {
            data[field.source] = this.launch(field.default, this)
          } catch (error) {
            console.warn(error?.originalError || error)
          }
        } else {
          if (
            typeof field.default === 'string' &&
            field.default.includes('#DD/MM/YYYY#')
          ) {
            const now = moment().format('DD/MM/YYYY')

            field.default = field.default.replace('#DD/MM/YYYY#', now)
          }

          data[field.source] = field.default
        }
      }

      // On complète les données si nécessaire (display/icone ....)
      if (data[field.source]) {
        if (field.type === 'select' && field.subtype === 'select_request') {
          data[field.source + '_display'] = ''
          //PROBLEME CA ARRIVE TROP TARD
          try {
            const resdata = await this.getDataPromise(field.request, {
              dataId: data[field.source],
            })

            if (resdata.data[0]) {
              if (field.labelField) {
                // Si  on a un labelField on l'utilise pour le display
                data[field.source + '_display'] =
                  resdata.data[0][field.labelField]
              } else {
                // Sinon on passe par le display de la request
                data[field.source + '_display'] =
                  resdata.data[0][
                    that._g.displaysDef[field.request]?.dataDisplay?.display
                  ]
              }

              if (that._g.displaysDef[field.request]?.dataDisplay?.icon) {
                data[field.source + '_icon'] =
                  resdata.data[0][
                    that._g.displaysDef[field.request]?.dataDisplay?.icon
                  ]
              }

              if (that._g.displaysDef[field.request]?.dataDisplay?.color) {
                data[field.source + '_color'] =
                  resdata.data[0][
                    that._g.displaysDef[field.request]?.dataDisplay?.color
                  ]
              }

              // On prépare les données au format select 2
              //si c'est un multiple il faut que les données soient dans un array
              if (field.multiple && !field.pivot) {
                data[field.source + '_data'] = resdata.data
              } else if (field.multiple && field.pivot) {
                data[field.source] = resdata.data
              } else {
                data[field.source + '_data'] = {
                  id: data[field.source],
                }
                data[field.source + '_data'][field.labelField] =
                  data[field.source + '_display']
              }
            }
          } catch (error) {
            this.platspec.platformAlert({
              message: this.translate.instant('ERROR.PREPARE_DATA'),
              logData: error,
              user: 'toast',
              log: 'error',
              function: 'prepareNew-utilities.ts',
            })
            console.warn(error?.originalError || error)
          }
        } else if (
          field.type === 'date' ||
          field.type === 'datetime' ||
          field.type === 'time'
        ) {
          if (field.type === 'date') {
            data[field.source + '_display'] = moment(data[field.source]).format(
              'll',
            )
          } else if (field.type === 'datetime') {
            data[field.source + '_display'] = moment(data[field.source]).format(
              'lll',
            )
          } else if (field.type === 'time') {
            data[field.source + '_display'] = moment(data[field.source]).format(
              'LT',
            )
          }
          data[field.source + '_value'] = new Date(data[field.source])
          // Dans le cas de l'appli on passe les valeurs des dates autrement
          if (that._g.appType === 'app') {
            if (field.type === 'date') {
              data[field.source] = moment(data[field.source]).format(
                'YYYY-MM-DD',
              )
            } else if (field.type === 'datetime') {
              data[field.source] = moment(data[field.source]).format(
                'YYYY-MM-DD HH:mm:ss',
              )
            } else if (field.type === 'time') {
              data[field.source] = moment(data[field.source]).format('HH:mm:ss')
            }
          }
        }

        if (field?.setValuesAfterUpdate) {
          for (const setValuesAfterUpdate of field.setValuesAfterUpdate) {
            if (setValuesAfterUpdate.request) {
              const {
                data: [result],
              } = await this.getDataPromise(setValuesAfterUpdate.request, {
                dataId: data[field.source],
              })
              if (result) {
                data[setValuesAfterUpdate.fieldName] =
                  result[setValuesAfterUpdate.fieldName]

                data[setValuesAfterUpdate.fieldName + '_data'] =
                  result[setValuesAfterUpdate.fieldName + '_data']
              }
            }
          }
        }
      }
    })

    await Promise.all(promises)
    return data
  }

  getIconForFieldType(field) {
    let icon = ''
    const type = field.dataType
    if (type === 'text') {
      icon = 'fa fa-font'
    } else if (type === 'longtext') {
      icon = 'fa fa-align-left'
    } else if (type === 'photo') {
      icon = 'fa fa-camera-retro'
    } else if (type === 'date') {
      icon = 'fa fa-calendar'
    } else if (type === 'datetime') {
      icon = 'fa fa-calendar-check-o'
    } else if (type === 'time') {
      icon = 'fa fa-clock'
    } else if (type === 'select') {
      icon = 'fa fa-list-ol'
    } else if (type === 'select_request') {
      icon = 'fa fa-list'
    } else if (type === 'html') {
      icon = 'fa fa-code'
    } else if (type === 'boolean') {
      icon = 'fa fa-check-square-o'
    } else if (type === 'attached') {
      icon = 'fa fa-file-text-o'
    } else if (type === 'int') {
      icon = 'fa fa-sort-numeric-asc'
    } else if (type === 'real') {
      icon = 'fa fa-superscript'
    } else if (type === 'charts') {
      icon = 'fa fa-pie-chart'
    }
    return icon
  }

  /****************************
   * Fonction d'enrichissement d'un display à partir des données sous jacentes notamment
   */
  enrichDisplay(baseDisplay: DisplayModel) {
    // Ajout des noms des sections à chaque champ et Enrichissement de chaque champ pour les form
    if (baseDisplay?.form) {
      const requestTable = baseDisplay.dataDisplay
      const currentSection = ''

      const arrayOfFields = this.generateArray(baseDisplay.form.fields)
      // Recherche première section
      let firstSection = ''
      for (const field of arrayOfFields) {
        if (field.type === 'divider' && firstSection === '') {
          firstSection = field.caption
        } else if (field.type === 'step' && firstSection === '') {
          firstSection = field.caption
        }
      }

      // On enrichit les champs individuels
      for (const field of arrayOfFields) {
        if (
          field.type !== 'divider' &&
          field.type !== 'step' &&
          field.type !== 'table' &&
          requestTable &&
          (requestTable.fields[field.source] || field.type === 'button_special')
        ) {
          this.enrichField(field, requestTable, currentSection)
        } else if (
          field.type === 'table' &&
          this._g.displaysDef[field.display] &&
          this._g.displaysDef[field.display].displayClass !== 'timeline'
        ) {
          field.displayType = 'table'
        } else if (
          field.type === 'table' &&
          this._g.displaysDef[field.display] &&
          this._g.displaysDef[field.display].displayClass === 'timeline'
        ) {
          field.displayType = 'timeline'
        } else if (field.type === 'files' || field.type === 'survey-files') {
          field.displayType = 'files'
        }

        // Et on pose les sections
        if (field.type !== 'divider' && field.type !== 'step') {
          field.section = firstSection
          if (field.parent) {
            for (const search of arrayOfFields) {
              if (search._key == field.parent) {
                field.section = search.caption
              }
            }
          }
        }
      }
    }

    // Enrichissement de chaque champ pour les tables
    if (baseDisplay?.table) {
      const requestTable = baseDisplay.dataDisplay
      for (const field of Object.values(baseDisplay.table.fields)) {
        this.enrichField(field, requestTable, '')
      }
    }

    // Enrichissement de chaque champ pour les dashboards
    if (baseDisplay?.dashboard) {
      const requestTable = baseDisplay.dataDisplay
      for (const field of Object.values(baseDisplay.dashboard.fields)) {
        this.enrichField(field, requestTable, '')
      }
    }

    // Enrichissement de chaque champ pour les dashboards
    if (baseDisplay?.view) {
      const requestTable = baseDisplay.dataDisplay
      for (const field of Object.values(baseDisplay.view.fields)) {
        this.enrichField(field, requestTable, '')
      }
    }

    return baseDisplay
  }

  /****************************
   * Fonction d'enrichissement d'un champ à partir des données sous jacentes notamment
   */
  enrichField(field, requestTable, currentSection) {
    const dataField = requestTable?.fields?.[field.source]

    if (requestTable) {
      if (field?.source && dataField) {
        field.section = currentSection
        field.dataSource = dataField.source
        field.dataSource2 = requestTable.fields[field.source2]?.source

        if (field.subField) {
          field.subField = new Field(field.subField)
          this.enrichField(field.subField, requestTable, currentSection)
        }

        // On récupère le caption de data si ce n'est pas défini dans le display
        if (!field.caption) {
          field.caption = dataField.caption
        }

        // On récupère le default si pas défini
        if (!field.default) {
          if (dataField.default) {
            field.default = dataField.default
          }
        }

        // On récupère le readOnly si pas défini
        if (!field.readOnly) {
          if (dataField.readOnly) {
            field.readOnly = dataField.readOnly
          }
        }

        // On règle le multiple
        if (typeof field.multiple !== 'boolean') {
          field.multiple = !!dataField.multiple
        }

        // Ajoute l'information du pivot pour les select_request
        if (dataField.pivot) {
          field.pivot = dataField.pivot
        }

        // Si le type n'est pas défini on le récupère
        if (!field.type) {
          if (
            dataField.dataType === 'select' ||
            dataField.dataType === 'select_request'
          ) {
            field.type = 'select'

            if (dataField.dataType === 'select_request' && field.request) {
              dataField.request = field.request
            }
          } else {
            field.type = dataField.dataType
          }
        }

        if (field.type === 'select') {
          if (dataField.values) {
            field.values = field.values || dataField.values
            field.subtype = 'select'
          }

          // Cas spécifique pour gérer la preview des listes de questions et des surveys
          if (field.subtype === 'questions-list') {
            field.displayType = 'questions-list'
          } else if (field.subtype === 'actions-list') {
            field.displayType = 'actions-list'
          } else if (field.subtype === 'surveys-list') {
            field.displayType = 'surveys-list'
          } else if (field.subtype === 'topics-list') {
            field.requestType = 'topics-list'
          } else if (field.subtype === 'podcasts') {
            field.displayType = 'podcasts'
          }
          if (dataField.request) {
            field.request = dataField.request
            if (!field.labelField && dataField.labelField) {
              field.labelField = dataField.labelField
            }
            if (!field.iconField && dataField.iconField) {
              field.iconField = dataField.iconField
            }
            if (!field.colorField && dataField.colorField) {
              field.colorField = dataField.colorField
            }
            if (!field.listFilter && dataField.listFilter) {
              field.listFilter = dataField.listFilter
            }
            if (!field.selectFilter && dataField.selectFilter) {
              field.selectFilter = dataField.selectFilter
            }

            field.subtype = 'select_request'
          }
        } else if (field.type === 'json' && dataField.subtype) {
          field.subtype = dataField.subtype
        } else if (field.type === 'items') {
          field.subtype = 'items-list'
        }

        // On détermine le displayType qui permet ensuite d'avoir un displayType par type d'input
        if (
          field.subtype === 'rrule' ||
          field.subtype === 'heroes-rrule' ||
          field.subtype === 'survey-rrule'
        ) {
          field.displayType = 'rrule'
        } else if (field.subtype === 'phone') {
          field.displayType = 'phone'
        } else if (field.subtype === 'email') {
          field.displayType = 'email'
        } else if (
          field.type === 'boolean' ||
          field.type === 'html' ||
          field.type === 'longtext' ||
          field.type === 'color' ||
          field.type === 'photo' ||
          field.type === 'attached' ||
          field.type === 'icon' ||
          field.type === 'charts' ||
          field.type === 'button_special' ||
          field.type === 'check_list'
        ) {
          field.displayType = field.type
        } else if (
          field.type === 'date' ||
          field.type === 'datetime' ||
          field.type === 'time'
        ) {
          field.displayType = 'date_and_time'
        } else if (field.type === 'select' && field.subtype === 'select') {
          field.displayType = 'select'
        } else if (
          field.type === 'select' &&
          field.subtype === 'select_request'
        ) {
          if (
            field.displayType !== 'questions-list' &&
            field.displayType !== 'surveys-list' &&
            field.displayType !== 'podcasts' &&
            field.displayType !== 'questions-creation' &&
            field.displayType !== 'actions-creation' &&
            field.displayType !== 'transcription'
          ) {
            field.displayType = 'select_request'
          }
        } else if (
          field.type === 'table' &&
          this._g.displaysDef[field.display].displayClass !== 'timeline'
        ) {
          field.displayType = 'table'
        } else if (
          field.type === 'table' &&
          this._g.displaysDef[field.display].displayClass === 'timeline'
        ) {
          field.displayType = 'timeline'
        } else if (field.subtype === 'questions-creation') {
          field.displayType = 'questions-creation'
        } else if (field.subtype === 'actions-creation') {
          field.displayType = 'actions-creation'
        } else if (field.subtype === 'transcription') {
          field.displayType = 'transcription'
        } else {
          field.displayType = 'input'
        }

        if (!field.withControlSameData) {
          field.withControlSameData = dataField.withControlSameData
        }

        if (!field.constraints) {
          field.constraints = dataField.constraints
        }

        if (!field.searchable) {
          field.searchable = dataField.searchable
        }

        if (!field.translatable) {
          field.translatable = dataField.translatable
        }

        if (!field.limit) {
          field.limit = dataField.limit
        }

        if (field.type === 'items') {
          if (field.fields) {
            field.itemsFields = field.fields
          } else {
            field.itemsFields = dataField.fields
          }

          if (field.itemsFields) {
            for (const itemField of field.itemsFields) {
              this.enrichField(itemField, requestTable, currentSection)
            }
          }
        }
      } else if (field && field.type === 'button_special') {
        field.displayType = 'button_special'
      }
    }
  }

  /*************
   * Création des sections
   * Crée une array de section à partir des champs
   */
  getSections(fields, commandFunction = null) {
    let num_section = 1

    // On passe par une variable tempo pour faire tout d'un coup
    let newSections = []
    let hasTop = false

    // Recherche et création des sections
    fields.forEach(function (field) {
      if (field.type === 'divider') {
        // || field.type == 'table') {
        newSections.push({
          num: num_section,
          index: field.index,
          _plan: field._plan,
          readOnly_plan: field.readOnly_plan,
          _module: field._module,
          after: field.after,
          label: field.caption,
          visible: field.visible,
          type: field.type,
          fields: [],
          name: field._key,
          help: field.help || null,
          col: field.col || null,
          class: field.class || null,
          _key: field._key,
          baseUrl: field.baseUrl,
        })
        num_section += 1
      } else if (field.type === 'step') {
        newSections.push({
          num: num_section,
          index: field.index,
          _plan: field._plan,
          readOnly_plan: field.readOnly_plan,
          _module: field._module,
          after: field.after,
          label: field.caption,
          icon: field.icon,
          visible: field.visible,
          type: field.type,
          fields: [],
          name: field._key,
          help: field.help || null,
          col: field.col || null,
          class: field.class || null,
          pictureOnLeft: field.pictureOnLeft || null,
          pictureOnRight: field.pictureOnRight || null,
          displayStepper: field.displayStepper,
          previousAction: field.previousAction,
          previousActionClass: field.previousActionClass,
          nextAction: field.nextAction,
          nextActionClass: field.nextActionClass,
          cancelAction: field.cancelAction,
          cancelActionClass: field.cancelActionClass,
          saveAction: field.saveAction,
          saveActionClass: field.saveActionClass,
          _key: field._key,
          disabled: false,
          command: (event) => {
            if (typeof commandFunction === 'function') {
              commandFunction(event)
            }
          },
        })
        num_section += 1
      } else if (field.parent === '_top' && !hasTop) {
        // Traitement spécifique de la section top

        newSections.push({
          num: num_section,
          index: null,
          _plan: field._plan,
          readOnly_plan: field.readOnly_plan,
          _module: field._module,
          after: '___',
          label: '_top',
          // visible: field.visible,
          // type: field.type,
          fields: [],
          name: '_top',
          _key: '_top',
        })
        hasTop = true
        num_section += 1
      }
    })

    if (newSections?.length === 0) {
      // Si on a aucune section (page sans onglet) on crée une section Fake
      newSections.push({
        index: null,
        after: '',
        num: -1,
        label: 'dummy',
        fields: fields.filter(
          (field) =>
            !field.position ||
            (field.position !== 'beforeSection' &&
              field.position !== 'afterSection'),
        ),
      })
    } else {
      // Sinon pour chaque section
      newSections.forEach((section) => {
        // On rajoute les champs
        fields.forEach((field) => {
          if (
            field.type !== 'important_note' &&
            field.type !== 'important_color'
          ) {
            if (field.parent === '_top' && section._key === '_top') {
              section.fields.push(field)
            } else if (
              field.type !== 'divider' &&
              field.type !== 'step' &&
              field.parent == section._key
            ) {
              section.fields.push(field)
            } else if (
              field.type !== 'divider' &&
              field.type !== 'step' &&
              !field.parent &&
              (!field.position ||
                (field.position !== 'beforeSection' &&
                  field.position !== 'afterSection')) &&
              section.num === 1
            ) {
              section.fields.push(field)
            }
          }
        })
      })
    }
    newSections = this.orderSections(newSections)
    newSections.forEach((section) => {
      if (!section.baseUrl) {
        section.fields = this.fieldService.orderFields(section.fields)
      }
    })

    return newSections
  }

  getFieldsAtPosition(fields: any[], position: string): any[] {
    let newFields: Field[] = []
    fields.forEach((field) => {
      if (field.position && field.position === position) {
        newFields.push(field)
      }
    })

    if (newFields?.length > 0) {
      newFields = this.fieldService.orderFields(newFields)
    }
    return newFields
  }

  orderSections(sections: any[]): any[] {
    if (sections?.length && sections[0].index >= 0) {
      const indexOrderedSections = this.orderSectionsByIndex(sections)
      return this.calcSectionOrder(indexOrderedSections)
    }

    const sectionTop = sections.find((section) => section.name === '_top')

    const startingSectionList = sections.filter((section) => {
      if (section.after === '' || section.after === '___') {
        if (sectionTop?.name === section?.name) return true

        section.after = sectionTop ? '_top' : '___'
        return true
      }

      return false
    })

    if (startingSectionList?.length > 1) {
      console.warn(
        this.translate.instant('FORM.ORDER_ERROR'),
        startingSectionList,
      )
    }

    const startingSection = sectionTop
      ? startingSectionList[1]
      : startingSectionList.shift()

    if (!startingSection) {
      console.error(this.translate.instant('FORM.ORDER_ERROR'), sections)
      return sections
    }

    const nextSectionList = difference(sections, [startingSection])
    const orderedSections = nextSectionList.reduce(
      (result: any[]) => {
        const lastSectionKey = last(result)._key

        const nextSection = nextSectionList.filter(
          (section) => section.after === lastSectionKey,
        )

        if (nextSection?.length === 0) {
          const nextStartingSection = startingSectionList.shift()
          return nextStartingSection ? [...result, nextStartingSection] : result
        }

        if (nextSection?.length > 1) {
          console.warn(this.translate.instant('FORM.ORDER_ERROR'), nextSection)
        }

        return [...result, nextSection[0]]
      },
      [startingSection],
    )
    return orderedSections
  }

  orderSectionsByIndex(sections: any[]): any[] {
    if (!sections?.length) {
      return sections
    }

    sections.sort((a, b) => (a.index || 1000) - (b.index || 1000))

    return sections
  }

  calcSectionOrder(sections: Field[]) {
    let lastField = '___'

    sections.forEach((field) => {
      field.after = lastField
      lastField = field._key
    })

    return sections
  }

  mergeDeep(orig, objects) {
    if (!this.isObject(orig) && !Array.isArray(orig)) {
      orig = {}
    }

    const target = cloneDeep(orig)
    const len = arguments?.length
    let idx = 0

    while (++idx < len) {
      const val = arguments[idx]

      if (this.isObject(val) || Array.isArray(val)) {
        this.merge(target, val)
      }
    }
    return target
  }

  /*************
   *
   * Uitilitaire pour débugger
   *
   */
  stringify(obj) {
    return JSON.stringify(obj)
  }

  /*** Fonction de merge **/
  private isObject = function (val) {
    return typeof val === 'object' || typeof val === 'function'
  }

  private merge(target, obj) {
    for (const key in obj) {
      if (!this.hasOwn(obj, key)) {
        continue
      }

      const oldVal = obj[key]
      const newVal = target[key]

      if (this.isObject(newVal) && this.isObject(oldVal)) {
        target[key] = this.merge(newVal, oldVal)
      } else if (Array.isArray(newVal)) {
        target[key] = newVal.concat(oldVal)
      } else {
        target[key] = cloneDeep(oldVal)
      }
    }
    return target
  }

  private hasOwn(obj, key) {
    return Object.prototype.hasOwnProperty.call(obj, key)
  }

  /****************************
   *
   * SAUVEGARDE DES DONNEES d'UN ENREGISTREMENT
   * UTILISE DANS SPECIFIC.JS
   */
  save(params) {
    // Appel d'une fonction au départ (ex : affichage de la fenetre d'attente)
    if (params.onStart) {
      params.onStart()
    }
    // On ajoute la date d'enregistrement locale
    params.dataToSend.answer_time = moment().format('YYYY-MM-DD HH:mm:ss')

    // Et on force le non envoi de l'id (qui sera déterminé sur le serveur)
    if (params.action === 'add') {
      if (params.dataToSend.id) {
        params.dataToSend.id = undefined
      }
    }

    // Utilisation d'une queue pour pouvoir coder les images en base64
    const queue = []

    // On clone pour éviter que les manipulations ne casse les données d'origine
    const httpDataToSend: any = {} //cloneDeep(params.dataToSend);

    // On garde les tags et les files
    if (params.dataToSend._tags) {
      httpDataToSend._tags = cloneDeep(params.dataToSend._tags)
    }
    if (params.dataToSend._files) {
      httpDataToSend._files = cloneDeep(params.dataToSend._files)
    }

    // Ontraiteb les autres champs
    for (const fieldName in params.dataToSend) {
      const fieldValue = params.dataToSend[fieldName]

      // Traitement des dates et autres champs particuliers
      const currentDataField =
        this._g.displaysDef[params.request].dataDisplay.fields[fieldName]
      if (currentDataField) {
        if (
          currentDataField &&
          (currentDataField.dataType === 'date' ||
            currentDataField.dataType === 'time' ||
            currentDataField.dataType === 'datetime')
        ) {
          if (fieldValue && fieldValue !== 'null') {
            //var dateValue = Misc.getJSDateFromMySQL(fieldValue);
            const dateValue = fieldValue

            if (currentDataField.dataType === 'date') {
              httpDataToSend[fieldName] = moment(dateValue).format('YYYY-MM-DD')
            } else if (currentDataField.dataType === 'time') {
              httpDataToSend[fieldName] = moment(dateValue).format('HH:mm:ss')
            } else if (currentDataField.dataType === 'datetime') {
              httpDataToSend[fieldName] = moment(dateValue).format(
                'YYYY-MM-DD HH:mm:ss',
              )
            }
          } else {
            // ON est obligé de la passer en chaine pour que ça passe
            // et on passe pas 'null' car mieux pour repérer
            httpDataToSend[fieldName] = 'nullDate'
          }
        } else if (
          currentDataField.subtype === 'rrule' ||
          currentDataField.subtype === 'heroes-rrule' ||
          currentDataField.subtype === 'survey-rrule'
        ) {
          httpDataToSend[fieldName] = fieldValue
            ? JSON.stringify(fieldValue)
            : null
        } else if (currentDataField.dataType === 'boolean') {
          httpDataToSend[fieldName] = fieldValue === true ? 1 : 0
        } else if (currentDataField.dataType === 'charts') {
          // On ne sauve pas les charts
          // On ne fait rien
        } else if (
          currentDataField.dataType === 'photo' &&
          this._g.appType === 'app'
        ) {
          // Traitement des photos
          // Dans le cas de l'app on envoie juste le nom donc rien à faire
          // Le fichier a déjà été téléchargé

          if (fieldValue && fieldValue !== 'null') {
            httpDataToSend[fieldName] = []
            fieldValue.split(';').forEach((filepath) => {
              if (filepath.substring(0, 7) === 'file://') {
                const filename = filepath.split('/').pop()
                // Il ne faut pas que les fichiers qui serait ùauvais ou aurait disparu
                // bloque complètement l'enregistrement
                if (
                  this.platspec.fileExists(
                    this.platspec.getDeviceDataDirectory(),
                    filename,
                  )
                ) {
                  // On ajoute la récupération du base 64 du fichier dans la queue
                  queue.push(
                    new Promise((resolve, reject) => {
                      this.platspec.readAsDataURL({
                        directory: this.platspec.getDeviceDataDirectory(),
                        filename,
                        onSuccess: (data) => {
                          // Transformation des données en base 64
                          resolve({ result: 'OK', data })

                          httpDataToSend[fieldName].push(data)
                        },
                        onError: (error) => {
                          console.warn(error?.originalError || error)
                          resolve({ result: 'NOK', error })
                        },
                      })
                    }),
                  )
                } else {
                  console.warn('photofile not exists : ' + fieldValue)
                }
              } else {
                httpDataToSend[fieldName].push(filepath)
              }
            })
          }
        } else {
          // Dans tous les autres cas on renvoie betement la donnée
          httpDataToSend[fieldName] = fieldValue
        }
      } else if (fieldName === 'id') {
        httpDataToSend[fieldName] = fieldValue
      } else if (fieldName === '_log_message') {
        httpDataToSend[fieldName] = fieldValue
      }
    }

    let saveURl = ''
    if (params.action === 'add') {
      saveURl = this._g.restRoot + 'app/data/' + params.request + '/create'
    } else {
      saveURl = this._g.restRoot + 'app/data/' + params.request + '/update'
    }

    return Promise.all(queue)
      .then((result) => {
        this.http
          .post<any>(
            saveURl,
            {
              data: httpDataToSend,
              displayName: params.displayName,
            },
            { headers: this.getHTTPHeaders() },
          )
          .subscribe(
            (data) => {
              if (!data || data.status !== 'ok') {
                this.platspec.platformAlert({
                  message: this.translate.instant('ERROR.SAVE_DATA'),
                  logData: JSON.stringify(data),
                  user: 'toast',
                  log: 'error',
                  function: 'save-utilities.ts',
                })
                console.warn(
                  this.translate.instant('ERROR.DATA_LOAD') +
                    ':' +
                    JSON.stringify(data),
                )
              }

              // On définit l'id de la donnée
              if (data.data) {
                params.dataToSend.id = data.data
              }

              if (params.onStop) {
                params.onStop()
              }
              if (params.callbackSuccess) {
                params.callbackSuccess({
                  id: data.data,
                })
              }
            },
            (error) => {
              if (params.onStop) {
                params.onStop()
              }
              this.platspec.platformAlert({
                message: this.translate.instant('ERROR.SAVE_DATA'),
                logData:
                  'save-http:' +
                  params.request +
                  '/' +
                  JSON.stringify(httpDataToSend) +
                  '/' +
                  JSON.stringify(error),
                user: 'toast',
                log: 'error',
                function: 'save-utilities.ts',
              })
              console.warn(error?.originalError || error)
              if (params.callbackError) {
                params.callbackError()
              }
            },
          )
      })
      .catch((error) => {
        console.warn(error?.originalError || error)
      })
  }

  /*****************************
   * Ouverture de la page pour référencer le token Google
   * Appelé dans un eval.
   * Sert (entre autre) pour ajouter des éléments dans google calendar voir specific.js => SpecAddEventContact
   */
  openGoogleAuth() {
    if (this._g.providerSocialAuth === SocialUserModelProvider.GOOGLE) {
      return this.platspec.platformAlert({
        user: 'toast',
        message: this.translate.instant(
          'ERROR.ALREADY_CONNECTED_TO_GOOGLE_SERVICE',
        ),
        title: 'Info',
        function: 'openGoogleAuth',
      })
    }

    window.open(
      this._g.restRoot +
        'google/oauth2?user=' +
        this._g.userId +
        '&account=' +
        this._g.userAccountId,
      '_blank',
    )
  }

  /***********************
   *
   * ecriture d'un fichier à partir du base 64
   *
   */
  b64toBlob(b64Data) {
    const contentType = ''
    const sliceSize = 512

    try {
      const byteCharacters = atob(b64Data)
      const byteArrays = []

      for (
        let offset = 0;
        offset < byteCharacters?.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize)

        const byteNumbers = new Array(slice?.length)
        for (let i = 0; i < slice?.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i)
        }

        const byteArray = new Uint8Array(byteNumbers)

        byteArrays.push(byteArray)
      }

      return new Blob(byteArrays, {
        type: contentType,
      })
    } catch (error) {
      console.warn(error?.originalError || error)
    }

    return null
  }

  prepareFileData(fieldValue, fieldName, queue, paramsToSend) {
    if (fieldValue && fieldValue !== 'null' && fieldValue !== '') {
      paramsToSend[fieldName] = []
      fieldValue.split(';').forEach((filepath) => {
        if (filepath.substring(0, 7) === 'file://') {
          const filename = filepath.split('/').pop()
          // Il ne faut pas que les fichiers qui serait mauvais ou aurait disparu
          // bloque complètement l'enregistrement
          if (
            this.platspec.fileExists(
              this.platspec.getDeviceDataDirectory(),
              filename,
            )
          ) {
            // On ajoute la récupération du base 64 du fichier dans la queue
            queue.push(
              new Promise((resolve, reject) => {
                this.platspec.readAsDataURL({
                  directory: this.platspec.getDeviceDataDirectory(),
                  filename,
                  onSuccess: (data) => {
                    // Transformation des données en base 64
                    resolve({ result: 'OK', data })

                    paramsToSend[fieldName].push(data)
                  },
                  onError: (error) => {
                    console.warn(error?.originalError || error)
                    resolve({ result: 'NOK', error })
                  },
                })
              }),
            )
          } else {
            console.warn('photofile not exists : ' + fieldValue)
          }
        } else {
          paramsToSend[fieldName].push(filepath)
        }
      })
    }
  }

  getDataPromise<T extends DataModel>(
    requestname: string,
    params?: Partial<{
      dataId: string
      dataFilter?: string
      fieldOrder: string
      fieldOrderDesc: boolean
      minLines: number
      maxLines: number
      searchString: string
    }>,
  ): Promise<{ data: T[]; hasMoreLines: boolean }> {
    return new Promise((resolve, reject) =>
      this.getData({
        dataName: requestname,
        dataId: params.dataId,
        searchString: params.searchString,
        minLines: params?.minLines || 0,
        maxLines: params?.maxLines || 1000,
        dataFilter: params?.dataFilter,
        fieldOrder: params?.fieldOrder,
        fieldOrderDesc: params?.fieldOrderDesc,
        callbackSuccess: (data) => resolve(data),
        callbackError: (data) => reject(data),
      }),
    )
  }

  public alert(message: string, type: string, fileCalling: string): void {
    this.platspec.platformAlert({
      message,
      user: 'toast',
      log: type,
      function: typeof fileCalling !== undefined ? fileCalling : '',
    })
  }

  public truncateString(str, num) {
    return str?.length <= num ? str : `${str.slice(0, num)}...`
  }

  public handleHttpErrorResponse(errorResponse: HttpErrorResponse): void {
    if (errorResponse.status === NOT_FOUND_ERROR_CODE) {
      this.messageService.add({
        severity: 'error',
        life: 10000,
        summary: this.translate.instant('DETAIL.NOT_FOUND'),
        detail:
          errorResponse?.error?.error ||
          errorResponse['data']?.error ||
          this.translate.instant('ERROR.ERROR'),
      })
    } else if (errorResponse.status === FORBIDDEN_ERROR_CODE) {
      this.messageService.add({
        severity: 'error',
        life: 10000,
        summary: this.translate.instant('DETAIL.CAN_NOT'),
        detail:
          errorResponse?.error?.error ||
          errorResponse['data']?.error ||
          this.translate.instant('ERROR.ERROR'),
      })
    }
  }

  public getHttpErrorResponseMessage(errorResponse: HttpErrorResponse): string {
    return (
      errorResponse?.error?.error?.error ||
      errorResponse?.error?.error ||
      errorResponse?.error?.data?.error ||
      this.translate.instant('ERROR.ERROR')
    )
  }
}
