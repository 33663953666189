import { NgModule } from '@angular/core'
import { GeneralDialogComponent } from './general-dialog.component'
import { TranslateModule } from '@ngx-translate/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { CommonModule } from '@angular/common'

@NgModule({
  declarations: [GeneralDialogComponent],
  imports: [CommonModule, ReactiveFormsModule, FormsModule, TranslateModule],
  exports: [CommonModule, FormsModule, TranslateModule, GeneralDialogComponent],
})
export class GeneralDialogModule {}
