import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { TranslateModule } from '@ngx-translate/core'
import { UnauthorizedComponent } from './unauthorized.component'

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
  ],
  exports: [UnauthorizedComponent],
  declarations: [UnauthorizedComponent],
})
export class UnauthorizedModule {}
