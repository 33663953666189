import { Injectable } from '@angular/core'

import {
  cloneDeep,
  compact,
  Dictionary,
  keyBy,
  mapValues,
  values,
} from 'lodash'

import { Utilities } from 'src/app/shared/utilities/utilities'
import { GlobalVars } from 'src/app/shared/vars/globalvars'

import { TranslateService } from '@ngx-translate/core'
import { ListResource } from '../resources/list.resource'
import {
  FileDownloadService,
  FileToDownload,
} from '../modules/components/file-download/file-download.service'
import { TableSettings } from '../modules/components/table/table.settings'
import { DisplayModel } from '../models/json/display.model'
import { TableFilter } from '../models/json/table-filter.model'
import { Order } from '../modules/components/table/order.model'
import { Field } from '../models/json/field.model'

@Injectable({ providedIn: 'root' })
export class ListService {
  constructor(
    private listResource: ListResource,
    private _g: GlobalVars,
    private utilities: Utilities,
    private fileDownloadService: FileDownloadService,
    private translate: TranslateService,
  ) {}

  saveDisplayVariation(
    displayName: string,
    variationName: string,
    tableSettings: TableSettings,
  ) {
    const variationSettings = {
      label: variationName,
      table: this.calcVariationSettings(tableSettings),
      fieldOrder: tableSettings.orders[0]?.source,
      fieldOrderDesc: tableSettings.orders[0]?.dir === 'desc',
      buttonsFilter: tableSettings.buttonsFilter,
      icon: 'fas fa-list',
      perso: true,
    }

    const newDisplayDef = {
      displays: {},
    }
    newDisplayDef.displays[displayName] = {
      displayVariations: {},
    }
    if (variationName) {
      newDisplayDef.displays[displayName].displayVariations[variationName] =
        variationSettings
    } else {
      newDisplayDef.displays[displayName] = variationSettings
    }
    return this.listResource.saveDisplayVariation(newDisplayDef)
  }

  paginate$(
    displayName: string,
    display: DisplayModel,
    buttonsFilter,
    searchFromTable: Dictionary<TableFilter<any>>,
    search: string,
    offset: number,
    limit: number,
    orders: Order[],
    variationName: string,
  ) {
    const request = display.displayRequest
    return this.listResource.paginate$(
      request,
      buttonsFilter,
      searchFromTable,
      search,
      offset,
      limit,
      orders,
      displayName,
      variationName,
    )
  }

  async excel(
    display: DisplayModel,
    buttonsFilter,
    searchFromTable: Dictionary<TableFilter<any>>,
    search: string,
    offset: number,
    limit: number,
    orders: Order[],
    variationName: string,
  ) {
    const URL = `${this._g.restRoot}app/data/excel/${display.displayRequest}`
    const displayAG = display._key || display.displayRequest
    const data = {
      buttonsFilter,
      filters: searchFromTable,
      offset,
      limit,
      orders,
      search: search || null,
      variationAG: variationName,
      displayAG,
    }

    this.fileDownloadService.fileToDownload$.next(
      new FileToDownload({
        name: 'export.xlsx',
        method: 'POST',
        url: URL,
        body: data,
      }),
    )
  }

  private calcDisplayFilter(display: DisplayModel) {
    const context = this._g.context
    let displayFilter
    if (
      display.displayFilter &&
      display.displayFilter.substring(0, 1) === '='
    ) {
      try {
        displayFilter = this.utilities.launch(display.displayFilter, this)
      } catch (error) {
        console.warn(error)
      }
    } else {
      displayFilter = display.displayFilter
    }
    return displayFilter
  }

  calcFilters(display: DisplayModel, currentFilterFromButtons: string): string {
    return compact([
      currentFilterFromButtons,
      this.calcDisplayFilter(display),
    ]).join(' AND ')
  }

  calcVariationSettings(tableSettings: TableSettings) {
    return {
      fields: mapValues(keyBy(tableSettings.fields, '_key'), (field) => ({
        after: field.after,
        visible: field.visible !== false,
      })),
      tableColSearch: tableSettings.filters,
      selectValues: tableSettings.selectValues,
      lines: tableSettings.pageLength,
    }
  }

  public buttonsFilterToSQL(buttonsFilter): string {
    let buttonsFilterSql = ''

    if (!buttonsFilter) return buttonsFilterSql

    let buttonsFilterSqlArray = []

    for (const [buttonFilterKey, buttonFilterValue] of Object.entries(
      buttonsFilter,
    )) {
      if (!buttonFilterValue) continue

      let filterSqlArray = []

      for (const [filterKey, filterValue] of Object.entries(
        buttonFilterValue,
      )) {
        if (filterValue.on && filterValue.filter) {
          filterSqlArray.push(`(${filterValue.filter})`)
        }
      }

      if (filterSqlArray?.length) {
        const filterSqlString = filterSqlArray.join(' OR ')
        buttonsFilterSqlArray.push(`(${filterSqlString})`)
      }
    }

    if (buttonsFilterSqlArray?.length) {
      const buttonsFilterSqlString = buttonsFilterSqlArray.join(' AND ')
      buttonsFilterSql += `(${buttonsFilterSqlString})`
    }

    return buttonsFilterSql
  }

  calcTableSettings(
    display: DisplayModel,
    variationName: string,
    buttonsFilter,
  ): TableSettings {
    const order = new Order(
      display.fieldOrder,
      display.fieldOrderDesc ? 'desc' : 'asc',
    )

    const buttonsFilterSql = buttonsFilter
      ? this.buttonsFilterToSQL(buttonsFilter)
      : ''

    const tableSettings = new TableSettings({
      fields: this.orderTableFields(display.table?.fields),
      buttonsFilter,
      buttonsFilterSql,
      filters: display.table?.tableColSearch,
      selectValues: display.table?.selectValues,
      orders: display.fieldOrder ? [order] : [],
      pageLength: display.pageLength || display.table?.lines || 10,
    })

    return tableSettings
  }

  orderTableFields(fields: Dictionary<Field>) {
    const fieldList = values(fields)

    if (fieldList?.length && fieldList[0].index >= 0) {
      const indexOrderedFields = this.orderFieldsByIndex(fieldList)
      return this.calcFieldOrder(indexOrderedFields)
    }

    const result = []
    let lastSource
    try {
      for (const i in fieldList) {
        let lastResult = fieldList.find((field) => field.after === lastSource)

        if (!lastResult) {
          lastResult = fieldList
            .filter((field) => !result.find((res) => res?._key === field?._key))
            .find((field) => !field.after || field.after === '___')
        }

        lastSource = lastResult?._key || lastResult?.source

        if (lastResult) {
          result.push(lastResult)
        }
      }

      this.calcFieldOrder(result)
      return result
    } catch (e) {
      console.error(this.translate.instant('LIST.ORDER_ERROR'), e)
      this.calcFieldOrder(fieldList)
      return fieldList
    }
  }

  orderFieldsByIndex(fields: Field[]) {
    if (!fields?.length) {
      return fields
    }

    fields.sort((a, b) => (a.index || 1000) - (b.index || 1000))

    return fields
  }

  calcFieldOrder(fields: Field[]) {
    // Comme le back ne gère pas les strings vide sur les variations, le premier élément dans le cas d'une variation, le premier élément a: after: "___"
    let lastField = '___'

    fields.forEach((field) => {
      field.after = lastField
      lastField = field._key || field?.source
    })

    return fields
  }

  toggleOrder(orders: Order[], source: string) {
    let newOrders = cloneDeep(orders)
    const indexOfOrder = orders.findIndex((order) => order.source === source)
    if (indexOfOrder >= 0) {
      const modifiedOrder = orders[indexOfOrder]
      modifiedOrder.dir = modifiedOrder.dir === 'asc' ? 'desc' : 'asc'
      orders.splice(indexOfOrder, 1)
      newOrders = [modifiedOrder, ...orders]
    } else if (source) {
      const newOrder = new Order(source, 'asc')
      newOrders = [newOrder, ...orders]
    }
    return newOrders
  }
}
