export class AppSettings {
  hasHomeImage: boolean

  hasBadge: boolean

  hasLanguages: boolean

  hasMessages: boolean

  hasTracker: boolean

  hassGeoloc: boolean

  chatUrl: string

  theme: any
}
