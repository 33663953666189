import { Component } from '@angular/core'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'

@Component({
  selector: 'mv-general-dialog',
  templateUrl: 'general-dialog.component.html',
})
export class GeneralDialogComponent {
  constructor(
    public activeModal: DynamicDialogRef,
    public config: DynamicDialogConfig,
  ) {}

  close() {
    this.activeModal.close()
  }
}
