import { Injectable } from '@angular/core'
import { AuthService } from 'ngx-auth'
import { BehaviorSubject, Observable, of, Subscription, throwError } from 'rxjs'
import {
  HttpClient,
  HttpErrorResponse,
  HttpRequest,
} from '@angular/common/http'

import { AuthResource } from './authentication.resource'
import { catchError, first, map, tap } from 'rxjs/operators'

import { TranslateService } from '@ngx-translate/core'

import { AccountInfo } from './account.info.model'
import { Utilities } from 'src/app/shared/utilities/utilities'
import { GlobalVars } from 'src/app/shared/vars/globalvars'
import {
  GoogleLoginProvider,
  SocialAuthService,
  SocialUser,
} from '@abacritt/angularx-social-login'
import { RedirectionService } from 'src/app/shared/services/redirection.service'
import {
  SocialUserModel,
  SocialUserModelProvider,
} from 'src/app/shared/models/social-user.model'
import { MessageService } from 'primeng/api'

export const BREATHING_EXPIRE_AT = 'breathing_expire_at'

@Injectable({ providedIn: 'root' })
export class AuthenticationService implements AuthService {
  ACCES_TOKEN = 'token'

  REFRESH_TOKEN = 'refresh_token'

  EXPIRE_AT = 'token_expire_at'

  USER_ACCOUNT = 'userAccount'

  USER_LOGIN = 'userLogin'

  USER_IS_SOCIAL_AUTH = 'userIsSocialAuthConnected'

  PROVIDER_SOCIAL_AUTH = 'providerSocialAuth'

  KIZEO_OAUTH_CODE = 'kizeoCode'

  KIZEO_OAUTH_REDIRECT_URI = 'kizeoRedirectUri'

  private ACTION = 'action'

  private LOGIN = 'login'

  isAuthorized$ = new BehaviorSubject(false)

  refreshListener: Subscription

  private interruptedUrl: string

  constructor(
    private utilities: Utilities,

    private authResource: AuthResource,
    private _g: GlobalVars,
    private http: HttpClient,
    private socialAuthService: SocialAuthService,
    private translate: TranslateService,
    private redirectionService: RedirectionService,
    private messageService: MessageService,
  ) {}

  public login(
    login: string,
    password: string,
    account?: string,
    captcha?: string,
    userOnly?: boolean,
  ): Observable<any> {
    this.cleanCache()

    return this.authResource.login(login, password, account, captcha).pipe(
      tap((dataJson: any) => {
        dataJson.userIsSocialAuthConnected = false
        dataJson.providerSocialAuth = ''

        this.setLoginProfile(dataJson, userOnly)
      }),
      catchError((errorResponse: HttpErrorResponse) => {
        this.messageService.add({
          severity: 'warn',
          life: 10000,
          summary: this.translate.instant('LOGIN.LOGIN'),
          detail:
            errorResponse?.error?.error ||
            this.translate.instant('ERROR.AUTHENTICATION'),
        })

        this.logout(false)

        return throwError(() => errorResponse)
      }),
    )
  }

  public loginBySocialUser(
    socialUser: SocialUserModel | SocialUser,
    account?: string,
    captcha: string = null,
    invitation: string = null,
    userOnly: boolean = null,
  ): Observable<any> {
    this.cleanCache()

    return this.authResource
      .loginBySocialUser(socialUser, account, captcha, invitation)
      .pipe(
        tap((dataJson: any) => {
          dataJson.login = socialUser['name']
          dataJson.userIsSocialAuthConnected = true
          dataJson.providerSocialAuth = socialUser['provider']

          this.setLoginProfile(dataJson, userOnly)
        }),
        catchError((errorResponse: HttpErrorResponse) => {
          this.messageService.add({
            severity: 'warn',
            life: 10000,
            summary: this.translate.instant('LOGIN.LOGIN'),
            detail:
              errorResponse?.error?.error ||
              this.translate.instant('ERROR.AUTHENTICATION'),
          })

          return throwError(() => errorResponse)
        }),
      )
  }

  public async loginByKizeo(
    code: string,
    redirect_uri: string,
    account?: string,
    userOnly?: boolean,
  ) {
    this.cleanCache()

    return this.authResource.loginKizeoUser(code, redirect_uri, account).pipe(
      tap((dataJson: any) => {
        this.setLoginProfile(dataJson, userOnly)
      }),
      catchError((errorResponse: HttpErrorResponse) => {
        this.messageService.add({
          severity: 'warn',
          life: 10000,
          summary: this.translate.instant('LOGIN.LOGIN'),
          detail:
            errorResponse?.error?.error ||
            this.translate.instant('ERROR.AUTHENTICATION'),
        })

        localStorage.removeItem(this.KIZEO_OAUTH_CODE)
        localStorage.removeItem(this.KIZEO_OAUTH_REDIRECT_URI)

        return throwError(() => errorResponse)
      }),
    )
  }

  public async setAsLoginProfile(dataJson: any, userOnly?: boolean) {
    if (!dataJson) {
      return
    }

    this.utilities.setLocally(this.ACCES_TOKEN, dataJson.access_token)
    this.utilities.setLocally(BREATHING_EXPIRE_AT, null)
    this.utilities.setLocally(this.USER_ACCOUNT, dataJson.account)

    this._g.userLogin = dataJson.login
    this._g.userAccount = dataJson.account
    this._g.userId = dataJson.id
    this._g.token = dataJson.token
    this._g.userAccountId = dataJson.accountId

    if (userOnly && !this._g.me.is_user) {
      throw new Error(this.translate.instant('AUTH_ERROR.USER'))
    }

    this.isAuthorized$.next(true)
  }

  public handleReturnalUrl(redirectedUrl: string): string {
    const returnalUrl = new URL(redirectedUrl)
    const searchParams = new URLSearchParams(returnalUrl.search)

    const fromQueryToLocalStorage = [
      this.ACCES_TOKEN,
      this.EXPIRE_AT,
      this.REFRESH_TOKEN,
      this.USER_ACCOUNT,
      this.USER_IS_SOCIAL_AUTH,
      this.PROVIDER_SOCIAL_AUTH,
    ]

    if (searchParams.get(this.ACCES_TOKEN)) {
      fromQueryToLocalStorage.forEach((param: string) => {
        if (searchParams.get(param)) {
          this.utilities.setLocally(param, searchParams.get(param))
        }
      })
    }

    return searchParams.get(this.ACCES_TOKEN)
  }

  public getReturnalUrl(redirectToUrl: string): string {
    const returnalUrl = new URL(redirectToUrl)

    const fromLocalStorageToQuery = [
      this.ACCES_TOKEN,
      this.EXPIRE_AT,
      this.REFRESH_TOKEN,
      this.USER_ACCOUNT,
      this.USER_IS_SOCIAL_AUTH,
      this.PROVIDER_SOCIAL_AUTH,
    ]

    const myDate = new Date()
    myDate.setMonth(myDate.getMinutes() + 1)

    fromLocalStorageToQuery.forEach((param: string) => {
      returnalUrl.searchParams.append(param, this.utilities.getLocally(param))
    })

    return returnalUrl.href
  }

  public getAccountInfo(
    accountCode: string,
    token: string = null,
  ): Observable<AccountInfo> {
    return this.authResource.getAccountInfo(accountCode, token)
  }

  private setLoginProfile(dataJson: any, userOnly?: boolean) {
    if (!dataJson) {
      this.logout(false)
      return
    }

    this.utilities.setLocally(this.ACCES_TOKEN, dataJson.access_token)
    this.utilities.setLocally(BREATHING_EXPIRE_AT, null)

    // Expiration date
    if (dataJson.expires_at) {
      this.utilities.setLocally(this.EXPIRE_AT, dataJson.expires_at)
    } else {
      const expireInSeconds: number = JSON.parse(dataJson?.expires_in)
      const expireAt = new Date()
      expireAt.setSeconds(expireAt.getSeconds() + expireInSeconds)

      this.utilities.setLocally(this.EXPIRE_AT, expireAt)
    }

    this.utilities.setLocally(this.REFRESH_TOKEN, dataJson.refresh_token)
    this.utilities.setLocally(this.USER_ACCOUNT, dataJson.account)

    this.utilities.setLocally(this.ACTION, this.LOGIN)
    this.utilities.setLocally(
      this.USER_IS_SOCIAL_AUTH,
      dataJson.userIsSocialAuthConnected as string,
    )

    this.utilities.setLocally(
      this.PROVIDER_SOCIAL_AUTH,
      dataJson.providerSocialAuth,
    )

    this._g.userLogin = dataJson.login
    this._g.userAccount = dataJson.account
    this._g.userId = dataJson.id
    this._g.token = dataJson.token
    this._g.userAccountId = dataJson.accountId
    this._g.userIsSocialAuthConnected = dataJson.userIsSocialAuthConnected
    this._g.providerSocialAuth = dataJson.providerSocialAuth

    if (userOnly && !this._g.me.is_user) {
      throw new Error(this.translate.instant('AUTH_ERROR.USER'))
    }

    this.isAuthorized$.next(true)
  }

  async loadProfile() {
    // Bad case: token expired, we logout
    if (!this.checkIfTokenIsValid()) {
      this.logout(false)
      return
    }

    this._g.me = await this.loadUser()

    if (!this._g.me) {
      this.logout(false)
      return
    }

    this._g.accountSettings = await this.loadAccountSettings()

    if (!this._g.accountSettings) {
      this.logout(false)
      return
    }

    this._g.userId = this._g.me?.id
    this._g.userAccount = this.utilities.getLocally(this.USER_ACCOUNT)
    this._g.userAccountId = this._g.me?.account_id
    this._g.userLogin = this.utilities.getLocally(this.USER_LOGIN)

    this._g.userIsSocialAuthConnected = this.utilities.getLocally(
      this.USER_IS_SOCIAL_AUTH,
    ) as unknown as boolean
    this._g.providerSocialAuth = this.utilities.getLocally(
      this.PROVIDER_SOCIAL_AUTH,
    )
  }

  async loadUser() {
    return await this.authResource.loadProfile()
  }

  async loadAccountSettings() {
    return await this.authResource.loadAccountSettings()
  }

  async logout(shouldDisconnect = true) {
    const clearLocalStorage = () => {
      localStorage.removeItem(this.ACCES_TOKEN)
      localStorage.removeItem(this.REFRESH_TOKEN)
      localStorage.removeItem(this.EXPIRE_AT)
      localStorage.removeItem(this.USER_IS_SOCIAL_AUTH)
      localStorage.removeItem(this.PROVIDER_SOCIAL_AUTH)
      localStorage.removeItem(BREATHING_EXPIRE_AT)
      localStorage.removeItem('superAdmin')
      localStorage.removeItem('superAdminToken')
      this._g.userLogin = null
      this._g.userAccount = null
      this._g.userId = null
      this._g.token = null
      this._g.userAccountId = null
      this.isAuthorized$.next(false)
    }

    if (shouldDisconnect) {
      try {
        clearLocalStorage()
        await this.authResource.disconnect().then(() => {
          this.logoutAction(clearLocalStorage)
        })
      } catch (e) {
        this.logoutAction(clearLocalStorage)
      }
    }

    try {
      await this.socialLogOut().then(() => {
        this.logoutAction(clearLocalStorage)
      })
    } catch (e) {
      this.logoutAction(clearLocalStorage)
    }

    this.logoutAction(clearLocalStorage)
  }

  async socialLogOut() {
    if (this._g.haveSocialConnectionActive) {
      await this.socialAuthService.signOut()
      this._g.userIsSocialAuthConnected = false
      this._g.haveSocialConnectionActive = false
    }
  }

  public isAuthorized(): Observable<boolean> {
    return this.isAuthorized$.pipe(first())
  }

  public getAccessToken(): Observable<string> {
    const accessToken: string = localStorage.getItem(this.ACCES_TOKEN)
    this._g.token = accessToken

    return of(accessToken)
  }

  public refreshToken(): Observable<any> {
    const refreshToken: string = localStorage.getItem('refresh_token')
    const accessToken: string = localStorage.getItem(this.ACCES_TOKEN)
    if (
      !refreshToken ||
      refreshToken === 'undefined' ||
      !accessToken ||
      accessToken === 'undefined'
    ) {
      return of(null)
    }

    return this.http
      .post(this._g.restRoot + 'oauth/token', { refresh_token: refreshToken })
      .pipe(
        tap((dataJson: any) => {
          if (dataJson) {
            this.setLoginProfile(dataJson)
          } else {
            this.logout(true)
          }
        }),
        catchError((err) => {
          this.logout(true)
          throw err
        }),
      )
  }

  public refreshShouldHappen(response: HttpErrorResponse): boolean {
    return response.status === 401
  }

  public refreshSocialToken() {
    // refresh google token
    if (this._g.providerSocialAuth === SocialUserModelProvider.GOOGLE) {
      this.socialAuthService.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID)
    }
  }

  public refreshSocialTokenInApi(socialUser: SocialUserModel) {
    this.authResource.refreshSocialGoogleToken(socialUser)
  }

  public verifyRefreshToken(req: HttpRequest<any>): boolean {
    if (
      !!localStorage.getItem(this.REFRESH_TOKEN) ||
      !!localStorage.getItem(this.ACCES_TOKEN)
    ) {
      return false
    }

    if (req.url) {
      return (
        req.url.endsWith('oauth/token') || req.url.includes('/api/token/renew/')
      )
    }
  }

  public skipRequest(req: HttpRequest<any>): boolean {
    return req.url.includes('googleapis.com')
  }

  public getInterruptedUrl(): string {
    return this.interruptedUrl
  }

  public setInterruptedUrl(url: string): void {
    this.interruptedUrl = url
  }

  public sendForgotPasswordRequest(
    email: string,
    captcha: string = null,
  ): Observable<any> {
    return this.authResource.sendForgotPasswordRequest(email, captcha)
  }

  public resetPassword(
    email: string,
    password: string,
    passwordConfirmation: string,
    token: string,
  ): Promise<any> {
    return this.authResource.resetPassword(
      email,
      password,
      passwordConfirmation,
      token,
    )
  }

  public setPasswordForFirstConnection(
    password: string,
    passwordConfirmation: string,
    token: string,
    captcha: string = null,
  ): Observable<any> {
    return this.authResource.setPasswordForFirstConnection(
      password,
      passwordConfirmation,
      token,
      captcha,
    )
  }

  public setEmailForInvitation(
    email: string,
    lastname: string,
    firstname: string,
    token: string,
    captcha: string = null,
  ): Observable<any> {
    return this.authResource.setEmailForInvitation(
      email,
      lastname,
      firstname,
      token,
      captcha,
    )
  }

  public checkTokenValidity(
    token: string,
    captcha: string = null,
  ): Observable<any> {
    return this.authResource.checkTokenValidity(token, captcha)
  }

  public checkTokenRefresh(
    token: string,
    email: string,
    captcha: string = null,
  ): Observable<any> {
    return this.authResource.checkTokenRefresh(token, captcha)
  }

  public checkAccountToken(
    token: string,
    captcha: string = null,
  ): Observable<any> {
    return this.authResource.checkAccountToken(token, captcha)
  }

  public checkIfTokenIsValid(): boolean {
    // Bad case: expired token
    const now = new Date()
    const expireAt: Date = new Date(this.utilities.getLocally(this.EXPIRE_AT))

    if (
      this.utilities.getLocally(this.ACCES_TOKEN) &&
      this.utilities.getLocally(this.EXPIRE_AT) &&
      now >= expireAt
    ) {
      return false
    }

    return true
  }

  public checkIfToken() {
    // Bad case: no token
    if (
      !this.utilities.getLocally(this.ACCES_TOKEN) ||
      !this.utilities.getLocally(this.EXPIRE_AT) ||
      this.utilities.getLocally(this.ACCES_TOKEN) === 'null' ||
      this.utilities.getLocally(this.EXPIRE_AT) === 'null'
    )
      return false

    return true
  }

  private logoutAction(clearLocalStorage) {
    clearLocalStorage()

    const pathname = window.location.pathname
    if (pathname && pathname !== '/') {
      localStorage.setItem('lastPathname', pathname)
    }

    this.redirectionService.handleRedirection('/login')
  }

  private cleanCache() {
    localStorage.removeItem(this.ACCES_TOKEN)
    localStorage.removeItem(this.REFRESH_TOKEN)
    localStorage.removeItem(this.EXPIRE_AT)
    localStorage.removeItem(this.USER_IS_SOCIAL_AUTH)
    localStorage.removeItem(this.PROVIDER_SOCIAL_AUTH)
  }
}
