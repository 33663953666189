import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { NgxsEmitPluginModule } from '@ngxs-labs/emitter';
import { CompleteJsonStore } from 'src/app/shared/store/json.store';
import { ListStore } from 'src/app/shared/store/list.store';

@NgModule({
  imports: [
    NgxsModule.forRoot([CompleteJsonStore, ListStore]),
    NgxsEmitPluginModule.forRoot(),
  ],
})
export class StoreModule {}
