import { Injectable } from '@angular/core';

import { Dictionary } from 'lodash';
import { MvHttpClient } from '../lib/MvHttpClient';
import { GlobalVars } from '../vars/globalvars';
import { DataModel } from '../models/data.model';
import { TableFilter } from '../models/json/table-filter.model';
import { Order } from '../modules/components/table/order.model';

@Injectable({ providedIn: 'root' })
export class ListResource {
  constructor(private _g: GlobalVars, private mvHttpClient: MvHttpClient) {}

  saveDisplayVariation(newDisplayDef) {
    if (newDisplayDef) {
      const url = `${this._g.restRoot}app/settings/display_data/save`;
      return this.mvHttpClient.post<any>(url, newDisplayDef).toPromise();
    }
  }

  /**
   * Pas de promese parce qu'on veux pouvoir annuler l'appel quand un nouvel appel arrive
   */
  paginate$<T extends DataModel>(
    requestName: string,
    buttonsFilter,
    searchFromTable: Dictionary<TableFilter<any>>,
    search: string,
    offset: number,
    limit: number,
    orders: Order[],
    displayName: string,
    variationName: string
  ) {
    const URL = `${this._g.restRoot}app/data/list/${requestName}`;
    const data = {
      buttonsFilter: this.getFilters(buttonsFilter),
      filters: searchFromTable,
      offset,
      limit,
      orders,
      search: search || null,
      displayAG: displayName,
      variationAG: variationName,
    };

    return this.mvHttpClient.post<{
      data: T[];
      hasMoreData: boolean;
      recordsFiltered: number;
      recordsTotal: number;
    }>(URL, data);
  }

  getFilters(buttonsFilter) {
    const filters = {};
    for (const pKey in buttonsFilter) {
      if (buttonsFilter[pKey]) {
        filters[pKey] = {};

        for (const sKey in buttonsFilter[pKey]) {
          if (buttonsFilter[pKey][sKey]) {
            const button = buttonsFilter[pKey][sKey];

            if (button && button.type === 'select') {
              const key = button.key || button.field;
              const prevValue = filters[pKey][key]?.value || [];

              // Valeur cochée
              if (button.on) {
                filters[pKey][key] = {
                  on: true,
                  value: [...prevValue, button.id || button.value.id],
                };

                // Valeur par défaut tant que rien n'a été coché manuellement
              } else if (
                button.defaultOnValuesArray &&
                button.defaultOnValuesArray?.length
              ) {
                filters[pKey][key] = {
                  on: true,
                  value: button.defaultOnValuesArray,
                };
              }
            } else {
              filters[pKey][sKey] = { on: button.on };
            }
          }
        }
      }
    }
    return filters;
  }
}
