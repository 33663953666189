import { Injectable } from '@angular/core'
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http'

import { Observable } from 'rxjs'
import { finalize } from 'rxjs/operators'
import { NgxSpinnerService } from 'ngx-spinner'
import { environment } from 'src/environements/environment'

const INTERCEPTOR_EXCEPTION = 'disableLoader'

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private totalRequests = 0

  private loaderHideTimeout = environment.loader_hide_timeout

  private loaderMaxDuration = environment.loader_max_duration

  // Les langues sont filtrées par un paramètre d'env
  private disableLoaderPatterns = environment.disable_loader_patterns
    .split(',')
    .map((value) => value.trim())

  constructor(private spinner: NgxSpinnerService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    // Disable spinner for this call because of Header
    if (request.headers.has(INTERCEPTOR_EXCEPTION)) {
      const headers = request.headers.delete(INTERCEPTOR_EXCEPTION)
      return next.handle(request.clone({ headers })).pipe(
        finalize(() => {
          this.handleLoader()
        }),
      )
    }

    // Disable spinner for this call if it matches pattern
    const includedPatternsRegex = new RegExp(
      this.disableLoaderPatterns.join('|'),
      'i',
    )
    if (includedPatternsRegex.test(request.url)) {
      return next.handle(request).pipe(
        finalize(() => {
          this.handleLoader()
        }),
      )
    }

    // Starts spinner
    if (this.totalRequests === 0) {
      this.spinner.show('main')

      // Limits spinner duration if API takes to much time
      setTimeout(() => {
        this.spinner.hide('main')
      }, this.loaderMaxDuration)
    }
    this.totalRequests++

    // Stops spinner if requests are all done
    return next.handle(request).pipe(
      finalize(() => {
        this.handleLoader()
      }),
    )
  }

  private handleLoader(): void {
    // Usage of timeout because of large number of await API calls
    if (this.totalRequests - 1 === 0) {
      this.spinner.hide('main')
    }

    setTimeout(() => {
      this.totalRequests--
    }, this.loaderHideTimeout)
  }
}
