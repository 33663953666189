import { Inject, Injectable } from '@angular/core'
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http'

import { BehaviorSubject, Observable, of } from 'rxjs'

import { MessageService } from 'primeng/api'

import { AUTH_SERVICE } from 'ngx-auth'

import { catchError, delay, switchMap } from 'rxjs/operators'
import { environment } from 'src/environements/environment'
import { AuthenticationService } from '../modules/core/auth/authentication.service'
import { Utilities } from '../utilities/utilities'
import { TranslateService } from '@ngx-translate/core'

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private ACCES_TOKEN = 'token'

  private isRefreshingToken = false

  // Les langues sont filtrées par un paramètre d'env
  private disableTokenCheckPatterns = environment.disable_token_check_patterns
    .split(',')
    .map((value) => value.trim())

  private autoLogoutPatterns = ['/app/complete']

  isAuthorized$ = new BehaviorSubject(false)

  constructor(
    @Inject(AUTH_SERVICE) private authenticationService: AuthenticationService,
    private utilities: Utilities,

    private messageService: MessageService,
    private translate: TranslateService,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    // Disable check for this call if it matches pattern
    const includedPatternsRegex = new RegExp(
      this.disableTokenCheckPatterns.join('|'),
      'i',
    )

    if (includedPatternsRegex.test(req.url)) {
      return this.nextHandle(next, req)
    }

    const request = this.addAuthHeaderToRequest(req)

    if (!this.authenticationService.checkIfToken()) {
      return this.nextHandle(next, request)
    }

    if (
      !this.authenticationService.checkIfTokenIsValid() &&
      !this.isRefreshingToken
    ) {
      this.isRefreshingToken = true

      // Refresh du token
      return this.authenticationService.refreshToken().pipe(
        switchMap(() => {
          this.isRefreshingToken = false
          const newRequest = this.addAuthHeaderToRequest(request)
          return next.handle(newRequest)
        }),
        catchError((e) => {
          this.isRefreshingToken = false

          this.messageService.add({
            severity: 'info',
            summary: this.translate.instant('AUTH_ERROR.UNAUTHORIZED'),
            detail: this.translate.instant('AUTH_ERROR.EXPIRE'),
          })

          this.authenticationService.logout(true)

          throw e
        }),
      )
    } else if (this.isRefreshingToken) {
      // Gestion des appels concurrents alors que le Refresh est en cours
      return of(true).pipe(
        delay(3000),
        switchMap(() => {
          const newRequest = this.addAuthHeaderToRequest(request)
          return next.handle(newRequest)
        }),
      )
    } else {
      return this.nextHandle(next, request)
    }
  }

  private nextHandle(next, request) {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            const logoutPatternsRegex = new RegExp(
              this.autoLogoutPatterns.join('|'),
              'i',
            )

            if (logoutPatternsRegex.test(request.url)) {
              this.messageService.add({
                severity: 'info',
                summary: this.translate.instant('AUTH_ERROR.UNAUTHORIZED'),
                detail: this.translate.instant('AUTH_ERROR.EXPIRE'),
              })

              this.authenticationService.logout(true)
            }
          }
        }

        throw err
      }),
    )
  }

  private addAuthHeaderToRequest(request) {
    const authHeader = this.utilities.getLocally(this.ACCES_TOKEN)
    if (authHeader) {
      return request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + authHeader,
        },
      })
    }

    return request
  }
}
