import { PhotoModel } from './photo.model'
import { Type } from 'class-transformer'

export class PhotoWrapperModel {
  @Type(() => PhotoModel)
  list: Omit<PhotoModel, 'content'>[] = []

  @Type(() => PhotoModel)
  added: PhotoModel[] = []

  @Type(() => PhotoModel)
  updated: PhotoModel[] = []

  @Type(() => PhotoModel)
  removed: PhotoModel[] = []

  constructor(obj: Partial<PhotoWrapperModel>) {
    Object.assign(this, obj)
  }
}
