import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { environment } from 'src/environements/environment'
import { Utilities } from '../utilities/utilities'

@Injectable({ providedIn: 'root' })
export class RedirectionService {
  private ACTION = 'action'

  private LOGOUT = 'logout'

  constructor(private router: Router, private utilities: Utilities) {}

  public handleRedirection(path: string): void {
    // Params
    let externalUrl = new URL(environment.authentication_uri)

    if (environment.authentication_dynamic_uri_code) {
      const [account] = window.location.hostname.split('.')
      const externalUri = window.location.origin.replace(
        `${account}`,
        `${environment.authentication_dynamic_uri_code}`,
      )

      externalUrl = new URL(externalUri + '/login')
    }

    this.utilities.setLocally(this.ACTION, this.LOGOUT)

    if (
      window.origin !== externalUrl.origin &&
      !window.origin.includes('mate')
    ) {
      // Si on est pas sur l'url d'authentification, on redirige
      this.redirectToLoginUrl(externalUrl)
    } else {
      // Si on est sur l'url d'authentification, on navigue normalement
      this.router.navigate([path], { replaceUrl: true })
    }
  }

  public redirectToLoginUrl(externalUrl: URL): void {
    // Params
    externalUrl.searchParams.append('redirectOrigin', window.origin)

    let [account] = window.location.hostname.split('.')
    if (localStorage.getItem('appCodeName')) {
      account = localStorage.getItem('appCodeName')
    }

    externalUrl.searchParams.append('redirectAccount', account)
    localStorage.setItem('redirectedToOrigin', externalUrl.origin)

    const subscribe = new URLSearchParams(window.location.search).get(
      'subscribe',
    )
    if (subscribe) {
      externalUrl.searchParams.append('subscribe', subscribe)
    }

    window.location.href = externalUrl.href
  }
}
