import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router'

import { of } from 'rxjs'
import { environment } from 'src/environements/environment'

@Injectable({
  providedIn: 'root',
})
export class LoginRedirectionGuard implements CanActivate {
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // Params
    let externalUrl = new URL(environment.authentication_uri)

    if (environment.authentication_dynamic_uri_code) {
      const [account] = window.location.hostname.split('.')
      const externalUri = window.location.origin.replace(
        `${account}`,
        `${environment.authentication_dynamic_uri_code}`,
      )

      externalUrl = new URL(externalUri + '/login')
    }

    if (
      window.origin !== externalUrl.origin &&
      !window.origin.includes('mate')
    ) {
      // Si on est pas sur l'url d'authentification, on redirige
      externalUrl.searchParams.append('redirectOrigin', window.origin)

      let [account] = window.location.hostname.split('.')
      if (localStorage.getItem('appCodeName')) {
        account = localStorage.getItem('appCodeName')
      }

      externalUrl.searchParams.append('redirectAccount', account)

      localStorage.setItem('redirectedToOrigin', externalUrl.origin)

      const subscribe = new URLSearchParams(window.location.search).get(
        'subscribe',
      )
      if (subscribe) {
        externalUrl.searchParams.append('subscribe', subscribe)
      }

      window.location.href = externalUrl.href
    } else {
      // Si on est sur l'url d'authentification, on garde les params en session pour ne pas les perdre
      if (
        route?.queryParams?.redirectOrigin ||
        !localStorage.getItem('redirectedFromOrigin')
      ) {
        localStorage.setItem(
          'redirectedFromOrigin',
          route?.queryParams?.redirectOrigin || null,
        )
      }

      if (route?.queryParams?.redirectAccount) {
        localStorage.setItem(
          'redirectedFromAccount',
          route?.queryParams?.redirectAccount,
        )
      }

      if (new URLSearchParams(window.location.search).get('code')) {
        localStorage.setItem(
          'kizeoCode',
          new URLSearchParams(window.location.search).get('code'),
        )
      }
    }

    return of(true)
  }
}
