<ngx-spinner
  [zIndex]="2000000"
  name="main"
  type="ball-scale-multiple"
></ngx-spinner>

<router-outlet></router-outlet>
<p-toast
  [baseZIndex]="2000000"
  appendTo="body"
  position="bottom-center"
></p-toast>
<mv-api-offline></mv-api-offline>
