import { Injectable } from '@angular/core'

import { difference, last } from 'lodash'
import { Field } from '../models/json/field.model'

@Injectable({ providedIn: 'root' })
export class FieldService {
  /**
   * Calcule l'ordre des champs en fonction des afters définit sur chaque champs
   *
   * Glossaire :
   * - Champ racine => Champ ayant un after vide
   * - Champs enfants => Les champ suivant un champ racine via la définition de leur "after"
   *
   * Cas normal :
   * Il y a un champ racine => c'est le premier élément
   * L'élément suivant est celui ayant comme after, l'élément inséré (et on boucle jusqu'à ne plus trouver de champs enfants)
   *
   * Cas particulier 1 (gérer) :
   * Il peut y avoir plusieurs éléments racine, dans ce cas, on trouve le premier (par ordre alphabetique) et on l'insère, on cherche tous ses enfants puis on insère le prochain élément racine
   *
   * Cas particulier 2 (non gérer) :
   * Pour un élément (non racine) donné il est possible qu'il a plusieurs enfants, dans ce cas on ne gère que le premier champ trouvé.
   */

  orderFields(fields: Field[]) {
    if (!fields?.length) {
      return fields
    }

    if (fields[0].index >= 0) {
      const indexOrderedFields = this.orderFieldsByIndex(fields)
      return this.calcFieldOrder(indexOrderedFields)
    }

    const startingFieldList = fields.filter((field) => {
      if (!field.after || field.after === '' || field.after === '___') {
        field.after = '___'
        return true
      }

      return false
    })

    if (startingFieldList?.length > 1) {
      console.warn(
        'Warning FieldService.orderFields - Json files should have only 1 starting field, fields order will be impacted',
        startingFieldList,
      )
    }

    const startingField = startingFieldList.shift()

    if (!startingField) {
      console.error(
        'Error FieldService.orderFields - No starting field, cannot order fields',
        fields,
      )
      return fields
    }

    const nextFieldList = difference(fields, [startingField])

    const orderedFields = nextFieldList.reduce(
      (result: Field[]) => {
        const lastFieldKey = last(result)._key

        const nextField = nextFieldList.filter(
          (field) => field.after === lastFieldKey,
        )

        if (nextField?.length === 0) {
          const nextStartingField = startingFieldList.shift()
          return nextStartingField ? [...result, nextStartingField] : result
        }

        if (nextField?.length > 1) {
          console.warn(
            'Warning FieldService.orderFields - Multiple fields after the same one, some fields will be ignored',
            nextField,
          )
        }

        return [...result, nextField[0]]
      },
      [startingField],
    )

    return orderedFields
  }

  orderFieldsByIndex(fields: Field[]) {
    if (!fields?.length) {
      return fields
    }

    fields.sort((a, b) => (a.index || 1000) - (b.index || 1000))

    return fields
  }

  /**
   * Retourne l'élément qui a comme after => "lastSource"
   */
  findNext(fields: Field[], lastSource?: string) {
    if (fields?.length && fields[0].index >= 0 && !fields[0].after) {
      const indexOrderedFields = this.orderFieldsByIndex(fields)

      return indexOrderedFields
    }

    return fields.find((field) => field.after === lastSource)
  }

  /**
   * Retourne le prochain élément racine qui n'existe pas encore dans "result"
   */
  findNextRoot(fields: Field[], result: Field[]) {
    return fields
      .filter((field) => !result.find((res) => res?._key === field._key))
      .find((field) => !field.after || field.after === '___')
  }

  calcFieldOrder(fields: Field[]) {
    // Comme le back ne gère pas les strings vide sur les variations, le premier élément dans le cas d'une variation, le premier élément a: after: "___"
    let lastField = '___'

    fields.forEach((field) => {
      field.after = lastField
      lastField = field._key
    })

    return fields
  }
}
