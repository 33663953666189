import { Injectable } from '@angular/core'

import { Observable } from 'rxjs'
import { HttpErrorResponse, HttpParams } from '@angular/common/http'
import { AccountInfo } from './account.info.model'
import { catchError, map } from 'rxjs/operators'
import { TranslateService } from '@ngx-translate/core'
import { MessageService } from 'primeng/api'
import { MvHttpClient } from 'src/app/shared/lib/MvHttpClient'
import { Utilities } from 'src/app/shared/utilities/utilities'
import { GlobalVars } from 'src/app/shared/vars/globalvars'
import { SocialUserModel } from 'src/app/shared/models/social-user.model'

@Injectable({ providedIn: 'root' })
export class AuthResource {
  constructor(
    private mvHttpClient: MvHttpClient,
    private utilities: Utilities,
    private _g: GlobalVars,
    private translate: TranslateService,
    private messageService: MessageService,
  ) {}

  public login(
    login: string,
    password: string,
    account: string,
    captcha: string = null,
  ): Observable<any> {
    const URL = `${this._g.restRoot}auth/token`

    const headers = {}

    if (account) {
      headers['Account'] = account
    }

    const options = account ? { headers } : undefined

    return this.mvHttpClient.post(
      URL,
      {
        username: login,
        password,
        captcha,
      },
      options,
    )
  }

  public loginBySocialUser(
    socialUserModel: SocialUserModel,
    account?: string,
    captcha: string = null,
    invitation: string = null,
  ): Observable<any> {
    const URL = `${this._g.restRoot}social/social-oauth2`

    const headers = {}

    if (account) {
      headers['Account'] = account
    }

    const options = account ? { headers } : undefined

    return this.mvHttpClient.post(
      URL,
      {
        provider: socialUserModel['provider'],
        data: socialUserModel,
        captcha,
        invitation,
      },
      options,
    )
  }

  public loginKizeoUser(
    code: string,
    redirect_uri: string,
    account?: string,
    captcha: string = null,
  ): Observable<any> {
    const URL = `${this._g.restRoot}oauth2/token`

    const headers = {}
    if (account) {
      headers['Account'] = account
    }
    const options = account ? { headers } : undefined
    return this.mvHttpClient.post(
      URL,
      {
        code: code,
        redirect_uri: redirect_uri,
        captcha,
      },
      options,
    )
  }

  async loadProfile() {
    const URL = this._g.restRoot + 'app/data/my_user/get/0'
    const response = await this.mvHttpClient
      .get<any>(URL, { headers: this.utilities.getHTTPHeaders() })
      .toPromise()
    const result = response?.data?.[0]
    return result
  }

  async refreshSocialToken(socialUserModel: SocialUserModel) {
    if (this._g.token !== null && this._g.token !== '') {
      const URL = `${this._g.restRoot}social/oauth2-refresh`
      await this.mvHttpClient
        .post(
          URL,
          {
            provider: socialUserModel['provider'],
            accessToken: socialUserModel['authToken'],
            response: socialUserModel['response'],
          },
          { headers: this.utilities.getHTTPHeaders() },
        )
        .toPromise()
    }
  }

  async refreshSocialGoogleToken(socialUserModel: SocialUserModel) {
    if (this._g.token !== null && this._g.token !== '') {
      const URL = `${this._g.restRoot}social/social-oauth2-refresh`
      await this.mvHttpClient
        .post(
          URL,
          {
            provider: socialUserModel['provider'],
            data: socialUserModel,
          },
          { headers: this.utilities.getHTTPHeaders() },
        )
        .toPromise()
    }
  }

  async disconnect() {
    const URL = this._g.restRoot + 'app/disconnect'
    return await this.mvHttpClient
      .get<any>(URL, {
        headers: this.utilities.getHTTPHeaders(),
      })
      .pipe(
        catchError((errorResponse: any) => {
          this.utilities.handleHttpErrorResponse(errorResponse)
          throw new Error(
            this.utilities.getHttpErrorResponseMessage(errorResponse),
          )
        }),
      )
      .toPromise()
  }

  async loadAccountSettings() {
    const URL = this._g.restRoot + 'app/data/account_settings/get/0'
    const response = await this.mvHttpClient
      .get<any>(URL, { headers: this.utilities.getHTTPHeaders() })
      .toPromise()
    return response?.data?.[0]
  }

  public sendForgotPasswordRequest(
    email: string,
    captcha: string = null,
  ): Observable<any> {
    const URL = `${this._g.restRoot}forgot-password`

    return this.mvHttpClient.post(URL, {
      email,
      captcha,
    })
  }

  public async resetPassword(
    email: string,
    password: string,
    passwordConfirmation: string,
    token: string,
  ): Promise<any> {
    const URL = `${this._g.restRoot}reset-password`

    return await this.mvHttpClient
      .post(URL, {
        email,
        password,
        password_confirmation: passwordConfirmation,
        token,
      })
      .toPromise()
  }

  public async changePassword(
    oldPassword: string,
    password: string,
    passwordConfirmation: string,
  ): Promise<any> {
    const URL = `${this._g.restRoot}app/data/my_user/change_password`

    return await this.mvHttpClient
      .post(URL, {
        oldPassword,
        password,
        password_confirmation: passwordConfirmation,
      })
      .toPromise()
  }

  public setPasswordForFirstConnection(
    password: string,
    passwordConfirmation: string,
    token: string,
    captcha: string = null,
  ): Observable<any> {
    const URL = `${this._g.restRoot}first_connection`

    return this.mvHttpClient.post(URL, {
      password,
      password_confirmation: passwordConfirmation,
      token,
      captcha,
    })
  }

  public setEmailForInvitation(
    email: string,
    lastname: string,
    firstname: string,
    token: string,
    captcha: string = null,
  ): Observable<any> {
    const URL = `${this._g.restRoot}invitation`

    return this.mvHttpClient.post(URL, {
      email,
      lastname,
      firstname,
      token,
      captcha,
    })
  }

  public checkTokenValidity(
    token: string,
    captcha: string = null,
  ): Observable<any> {
    const URL = `${this._g.restRoot}check_token_validity`

    return this.mvHttpClient.get(URL, {
      params: {
        token,
        captcha,
      },
    })
  }

  public checkTokenRefresh(
    token: string,
    email: string,
    captcha: string = null,
  ): Observable<any> {
    const URL = `${this._g.restRoot}check_token_refresh`

    return this.mvHttpClient.get(URL, {
      params: {
        token,
        email,
        captcha,
      },
    })
  }

  public checkAccountToken(
    token: string,
    captcha: string = null,
  ): Observable<any> {
    const URL = `${this._g.restRoot}check_account_token`

    return this.mvHttpClient.get(URL, {
      params: {
        token,
        captcha,
      },
    })
  }

  public getAccountInfo(
    accountCode: string,
    token: string = null,
  ): Observable<AccountInfo> {
    // Params
    const params = new HttpParams()
      .set('accountName', `${accountCode}`)
      .set('token', `${token}`)

    return this.mvHttpClient
      .get<{
        data: AccountInfo
      }>(`${this._g.restRoot}account/authentication-info`, { params })
      .pipe(
        map((response: any) => response?.data),
        catchError((errorResponse: any) => {
          this.messageService.add({
            severity: 'error',
            life: 10000,
            summary: this.translate.instant('LOGIN.ACCOUNT'),
            detail:
              errorResponse?.error?.message ||
              errorResponse?.error?.error ||
              this.translate.instant('ERROR.ERROR'),
          })

          return errorResponse
        }),
      )
  }
}
