import { Injectable } from '@angular/core'
import { filter, map, startWith, tap } from 'rxjs/operators'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { Dictionary } from 'lodash'
import { Title } from '@angular/platform-browser'
import { TranslateService } from '@ngx-translate/core'

import { Store } from '@ngxs/store'
import { GlobalVars } from '../vars/globalvars'
import { CompleteJsonStore } from '../store/json.store'

@Injectable({ providedIn: 'root' })
export class TitleService {
  constructor(
    private router: Router,
    private _g: GlobalVars,
    private title: Title,
    private store: Store,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
  ) {}

  /**
   * Ecoute les évènements de navigation pour calculer le titre
   */
  updateTitleOnRouteChange() {
    return this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      startWith({} as NavigationEnd),
      map(() => this.activatedRoute),
      map((route: ActivatedRoute) => {
        while (route.firstChild) {
          route = route.firstChild
        }
        return route.snapshot
      }),
      filter((route) => route.outlet === 'primary'),
      tap((route) => this.updateTitle(route.params, route?.data?.mapTitle)),
    )
  }

  /**
   * Calcule le titre de la page courrante.
   * S'il y a une fonction de mapping, on l'appelle avec les globalvars et les paramêtres de la route.
   * S'il n'y a pas de fonction de mapping, on laisse la route gérer (cas de détail)
   */
  updateTitle(
    params: Dictionary<any>,
    mapTitle: (_g: GlobalVars, a: Dictionary<any>, label: string) => string,
  ) {
    let title: string
    if (mapTitle) {
      let label
      if (params.display) {
        label = this.store.selectSnapshot(
          CompleteJsonStore.currentDisplayLabel(
            params.display,
            params.variation,
          ),
        )
      }
      title = mapTitle(this._g, params, label)
    }
    if (title) {
      this.setTitle(title)
    }
  }

  /**
   * sette le titre passé en paramètre préfixé par le nom de l'application, sinon sette juste le nom de l'application.
   */
  setTitle(title: string) {
    if (title) {
      const translate = this.translateService.instant(title)
      this.title.setTitle(`${this._g.appName} - ${translate}`)
    } else {
      this.title.setTitle(this._g.appName)
    }
  }
}
