import * as moment from 'moment'
import { environment } from 'src/environements/environment'
import { GlobalVars } from './shared/vars/globalvars'
import { DEBUG_CODE } from './pages/gateway/header-navigation/dropdown-language/dropdown-language.component'
import { TranslateService } from '@ngx-translate/core'

export const LANGUAGE = 'language'
export const AVAILABLE_LANGUAGES = 'available_languages'

export const APP_NAMES: any = {
  heroes: 'HeRoes',
  geremy: 'Geremy',
  mate: 'Mate',
  kizeobiz: 'Kizeo Biz',
  kizeoloc: 'Kizeo Loc',
}

export const APP_LOGOS: any = {
  heroes: 'heroes',
  geremy: 'Geremy',
  mate: 'mate',
  kizeobiz: 'kizeobiz',
  kizeoloc: 'kizeoloc',
}

export function appInitializerFactory(
  translate: TranslateService,
  _g: GlobalVars,
) {
  return () => {
    // Définition de la langue
    const cachedLanguage = localStorage.getItem(LANGUAGE)
    const navigatorLanguage = navigator.language.trim().substring(0, 2)
    const availableLanguagesString = localStorage.getItem(AVAILABLE_LANGUAGES)
    const cachedAvailableLanguages = availableLanguagesString
      ? availableLanguagesString.split(',')
      : null
    const availableLanguages =
      cachedAvailableLanguages || _g.available_languages

    // Priorité à la langue en cache
    if (
      cachedLanguage &&
      (availableLanguages.includes(cachedLanguage) ||
        cachedLanguage === DEBUG_CODE)
    ) {
      _g.language = cachedLanguage
      // Sinon langue du navigateur
    } else if (
      navigatorLanguage &&
      availableLanguages.includes(navigatorLanguage)
    ) {
      _g.language = navigatorLanguage
      // Langue par défaut
    } else {
      _g.language = _g.default_language
    }

    _g.available_languages = availableLanguages

    // On définit la locale pour Angular et Moment
    moment.locale(_g.language)

    // On passe la valeur en cache pour pouvoir la récupérer en cas de refresh
    if (_g.language !== cachedLanguage) {
      localStorage.setItem(LANGUAGE, _g.language)
    }

    /******************************
     * Récupération et définition des variables globales, à partir
     * des infos de script de config spécifique à cette application
     */
    let defaultAppCode = 'geremy'
    if (
      window.location.hostname.includes('.transcribe.') ||
      window.location.hostname.includes('.cr.') ||
      window.location.hostname.includes('.geremy.')
    ) {
      defaultAppCode = 'geremy'
    } else if (
      window.location.hostname.includes('.acmate.') ||
      window.location.hostname.includes('.wizaio.')
    ) {
      defaultAppCode = 'mate'
    }

    const [account] = window.location.hostname.split('.')

    const appName =
      account && APP_NAMES[account] ? APP_NAMES[account] : defaultAppCode
    const appLogo =
      account && APP_LOGOS[account] ? APP_LOGOS[account] : defaultAppCode
    const appUri = window.location.hostname.replace(`${account}.`, '')

    // Name & Code
    _g.appName = appName
    _g.appCodeName = account

    // Logo
    _g.appLogoCodeName = appLogo

    // Uri & API
    const protocol = window.location.protocol
    _g.appUri = appUri
    _g.restRoot = `${protocol}//api.${appUri}/api/v1/`

    // Params
    _g.appType = 'web'
    _g.mobileMode = 'complete'
    _g.restPrefix = ''
    _g.appClass = _g.appType + ' ' + _g.appName

    if (environment.use_default_params === true) {
      // Local env. (paramétrer environnement.local.ts et lancer le serve avec --configuration=local)
      _g.appName = environment.default_app_name || appName
      _g.appCodeName = environment.default_app_codename || account
      _g.restRoot = environment.default_app_api
    }

    // Pour authentification
    localStorage.setItem('appName', _g.appName)
    localStorage.setItem('appCodeName', _g.appCodeName)

    // On force le chargement local, puis le chargement du fichier servi par le backend
    if (_g.language !== DEBUG_CODE) {
      translate.setDefaultLang(`${_g.default_language}_local`)
      return translate.use(`${_g.default_language}_local`).subscribe(() => {
        return translate.use(_g.language)
      })
    }

    return translate.use(_g.language)
  }
}
