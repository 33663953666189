import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { Subject } from 'rxjs'
import { AUTH_SERVICE } from 'ngx-auth'
import { TranslateService } from '@ngx-translate/core'
import { PrimeNGConfig } from 'primeng/api'
import { HttpClient } from '@angular/common/http'
import { AVAILABLE_LANGUAGES } from './app-initializer.factory'
import { Event, NavigationStart, Router } from '@angular/router'
import * as _url from 'url'
import { RxjsComponent } from './shared/lib/rxjs.component'
import { GlobalVars } from './shared/vars/globalvars'
import { Utilities } from './shared/utilities/utilities'
import { AuthenticationService } from './shared/modules/core/auth/authentication.service'
import { TitleService } from './shared/services/title.service'
import { SlackService } from './shared/services/slack.service'
import { DOCUMENT } from '@angular/common'
import { environment } from 'src/environements/environment'
import { TranslationTagsHostDirective } from './shared/modules/directives/translation-click/translation-tags-host.directive'

@Component({
  selector: 'mv-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  hostDirectives: [TranslationTagsHostDirective],
})
export class AppComponent extends RxjsComponent implements OnInit, OnDestroy {
  title: string

  public isForgotPasswordRequested = false

  public isResetPasswordPage = false

  public isFirstConnection = false

  public override destroy$ = new Subject()

  public showTempoUnauthorized = false

  constructor(
    public _g: GlobalVars,
    public utilities: Utilities,
    @Inject(AUTH_SERVICE) private authenticationService: AuthenticationService,
    private titleSvc: TitleService,
    private translate: TranslateService,
    private config: PrimeNGConfig,
    private http: HttpClient,
    private router: Router,
    private slackService: SlackService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    super()

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        const url = `${window.location.origin}${event.url}`
        const href = new URL(url)

        const hasCode = href.searchParams.has('code')
        const hasState = href.searchParams.has('state')

        if (hasCode && hasState) {
          const code = href.searchParams.get('code')
          this.slackService.addToSlack(code)
        }
      }
    })

    // Ping API & locales
    const url = `${this._g.restRoot}get_locales`
    this.http.get(url).subscribe({
      next: (res: {
        default_language: string
        available_languages: string[]
      }) => {
        // API is online
        this._g.isApiOnline$.next(true)

        // Locales
        this._g.default_language = res.default_language.trim()
        this._g.available_languages = res.available_languages.map((value) =>
          value.trim(),
        )

        // Set to storage
        localStorage.setItem(
          AVAILABLE_LANGUAGES,
          this._g.available_languages.join(','),
        )

        return
      },
      error: () => {
        // API error
        this._g.isApiOnline$.next(false)

        return
      },
    })

    // Définition du titre (pour le browser)
    this.title = this._g.appName

    // Traductions PrimeNg
    this.translate.get('primeng').subscribe((res) => {
      this.config.setTranslation(res)
    })

    /****************************
     *
     * Ajout dynamique des favicons
     */
    let link: any

    link = document.createElement('link')
    link.type = 'image/x-icon'
    link.rel = 'shortcut icon'
    link.href =
      'assets/images/' + this._g.appLogoCodeName + '_favicon-16x16.png'
    link.sizes = '16x16'
    document.getElementsByTagName('head')[0].appendChild(link)

    link = document.createElement('link')
    link.type = 'image/x-icon'
    link.rel = 'shortcut icon'
    link.href =
      'assets/images/' + this._g.appLogoCodeName + '_favicon-32x32.png'
    link.sizes = '32x32'
    document.getElementsByTagName('head')[0].appendChild(link)
  }

  async ngOnInit() {
    let accessToken = localStorage.getItem(
      this.authenticationService.ACCES_TOKEN,
    )

    if (
      accessToken === 'null' ||
      !this.authenticationService.checkIfTokenIsValid()
    ) {
      accessToken = null
    }

    const urlToken = this.authenticationService.handleReturnalUrl(
      window.location.href,
    )

    if (!accessToken && urlToken) {
      accessToken = urlToken
    }

    if (accessToken === 'null') {
      accessToken = null
    }

    this.authenticationService.isAuthorized$.next(!!accessToken)

    this.register(this.titleSvc.updateTitleOnRouteChange().subscribe())

    if (environment.disable_hotjar !== true) {
      const accountLoginUrl = new URL(environment.authentication_uri)
      const hotjarVersion = environment.hotjar_version

      const hotjarId =
        window.origin === accountLoginUrl.origin
          ? environment.hotjar_login_id
          : environment.hotjar_app_id

      ;((h, o, t, j, a, r) => {
        h.hj =
          h.hj ||
          /* tslint:disable:only-arrow-functions */
          function () {
            ;(h.hj.q = h.hj.q || []).push(arguments)
          }
        h._hjSettings = { hjid: hotjarId, hjsv: hotjarVersion }
        a = o.getElementsByTagName('head')[0]
        r = o.createElement('script')
        r.async = 1
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
        a.appendChild(r)
      })(
        window as any,
        this.document,
        'https://static.hotjar.com/c/hotjar-',
        '.js?sv=',
      )
    }
  }

  override ngOnDestroy() {
    this.destroy$.next(null)
    this.destroy$.complete()
  }

  public routeToForgotPassword(isForgotPasswordRequest: boolean): void {
    this.isForgotPasswordRequested = isForgotPasswordRequest
  }

  public returnToLoginPage(isReturnToLoginPage: boolean): void {
    this.isForgotPasswordRequested = !isReturnToLoginPage
    this.isResetPasswordPage = !isReturnToLoginPage
    this.isFirstConnection = !isReturnToLoginPage
  }
}
