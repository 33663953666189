import { Injectable } from '@angular/core'
import { HttpClient, HttpHandler, HttpParams } from '@angular/common/http'
import { Dictionary, reduce } from 'lodash'
import { CustomHttpUrlEncodingCodec } from './http-url-encoding-codec'
import { Observable } from 'rxjs'
import { GlobalVars } from '../vars/globalvars'

@Injectable({ providedIn: 'root' })
export class MvHttpClient extends HttpClient {
  constructor(private _g: GlobalVars, handler: HttpHandler) {
    super(handler)
  }

  /**
   * Fonction de gestion des parametres pour la query
   */
  createRequestOption(req?: Dictionary<any>): { params: HttpParams } {
    return {
      params: this.createHttpParams(req),
    }
  }

  createHttpParams(req: _.Dictionary<any>): HttpParams {
    let params: HttpParams = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    })
    if (req) {
      Object.keys(req)
        .filter((key) => req[key] !== undefined)
        .forEach((key) => (params = params.append(key, req[key])))
    }
    return params
  }

  getDataFiltered<T>(requestName: string, filter, limit = 500): Observable<T> {
    const filterStr = reduce(
      filter,
      (acc, value, key) => {
        if (acc?.length) {
          acc += ' and '
        }
        return `${acc}${key}='${value}'`
      },
      '',
    )

    return this.get<T>(
      this.generateFilteredRequestUrl(requestName, filterStr, limit),
    )
  }

  getNotesFiltered<T>(
    requestName: string,
    filterStr,
    limit = 500,
  ): Observable<T> {
    return this.get<T>(
      this.generateFilteredRequestUrl(requestName, filterStr, limit),
    )
  }

  generateFilteredRequestUrl(
    requestName: string,
    filterStr: string,
    limit: number,
  ): string {
    return `${
      this._g.restRoot
    }app/data/${requestName}/get/filtered/${encodeURIComponent(
      filterStr || '1=1',
    )}/0/${limit}`
  }

  getDataAgregatedFiltered<T>(
    requestName: string,
    filter,
    limit = 500,
    groupString,
  ): Observable<T> {
    const filterStr = reduce(
      filter,
      (acc, value, key) => {
        if (acc?.length) {
          acc += ' and '
        }
        return `${acc}${key}='${value}'`
      },
      '',
    )
    return this.get<T>(
      this.generateAggregatedFilteredRequestUrl(
        requestName,
        filterStr,
        limit,
        groupString,
      ),
    )
  }

  generateAggregatedFilteredRequestUrl(
    requestName: string,
    filterStr: string,
    limit: number,
    groupString: string,
  ): string {
    return (
      `${
        this._g.restRoot
      }app/data/${requestName}/get/aggregated/${encodeURIComponent(
        filterStr || '1=1',
      )}/0/${limit}?` + groupString
    )
  }
}
