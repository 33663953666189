import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms'
import {
  NgbDatepickerModule,
  NgbTimepickerModule,
} from '@ng-bootstrap/ng-bootstrap'
import { MVDatePickerComponent } from './datepicker.component'
import { TooltipModule } from 'primeng/tooltip'
import { CalendarModule } from 'primeng/calendar'

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    TooltipModule,
    CalendarModule,
  ],
  declarations: [MVDatePickerComponent],
  exports: [MVDatePickerComponent],
})
export class DatepickerModule {}
